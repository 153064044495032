import { Component } from 'react'
import { CodedConceptDto, PersonnelDTO } from 'services/openapi'
import ImmunizationTemplateComponent from './ImmunizationTemplateComponent'
import { ETemplateMetadataType, ITemplate, ITemplateMetadata } from '../../blots/TemplateBlot'

export type TImmunizationTemplateValue =
  | number
  | string
  | CodedConceptDto
  | PersonnelDTO
  | undefined

export type ImmunizationTemplateState = {
  administeringProviderId?: string
  administeringProviderName?: string
  agent?: CodedConceptDto
  consent?: CodedConceptDto
  createdBy?: string
  createdOn?: string
  date?: string
  dosage?: number
  doseId?: string
  doseNumber?: number
  immunizationId?: string
  immunizationName?: string
  lotExpiryDate?: string
  lotNumber?: string
  note?: string
  route?: CodedConceptDto
  scheduleId?: string
  scheduleName?: string
  site?: CodedConceptDto
  sourceOfInformation?: CodedConceptDto
  units?: CodedConceptDto
}

export type ImmunizationTemplateProps = { data: ImmunizationTemplateState }

class ImmunizationTemplate
  extends Component<ImmunizationTemplateProps, ImmunizationTemplateState>
  implements ITemplate
{
  type = 'ImmunizationTemplate'
  state: ImmunizationTemplateState

  constructor(props: ImmunizationTemplateProps) {
    super(props)
    this.state = { ...props.data }
  }

  static getMetadata = (): ITemplateMetadata => ({
    administeringProviderName: {
      label: 'Administering Provider',
      type: ETemplateMetadataType.string,
    },
    consent: { label: 'Consent', type: ETemplateMetadataType.CodedConceptDto },
    createdBy: { label: 'Created by', type: ETemplateMetadataType.ProviderId },
    createdOn: { label: 'Created on', type: ETemplateMetadataType.DateTime },
    date: { label: 'Date', type: ETemplateMetadataType.Date },
    dosage: { label: 'Dosage', type: ETemplateMetadataType.number },
    doseNumber: { label: 'DoseNumber', type: ETemplateMetadataType.number },
    immunizationName: {
      label: 'Immunization Name',
      required: true,
      type: ETemplateMetadataType.string,
    },
    lotExpiryDate: { label: 'Lot Expiry Date', type: ETemplateMetadataType.Date },
    lotNumber: { label: 'Lot Number', type: ETemplateMetadataType.string },
    note: { label: 'Note', type: ETemplateMetadataType.string },
    route: { label: 'Route', type: ETemplateMetadataType.CodedConceptDto },
    scheduleName: { label: 'Schedule Name', type: ETemplateMetadataType.string },
    site: { label: 'Site', type: ETemplateMetadataType.CodedConceptDto },
    sourceOfInformation: {
      label: 'Source of Information',
      required: true,
      type: ETemplateMetadataType.CodedConceptDto,
    },
    units: { label: 'Units', type: ETemplateMetadataType.CodedConceptDto },
  })

  static getTemplateDisplayName = (): string => 'Immunization Template'

  static getTemplateVersion = (): number => 1

  getData = () => ({
    administeringProviderId: this.state.administeringProviderId,
    administeringProviderName: this.state.administeringProviderName,
    agent: this.state.agent,
    consent: this.state.consent,
    createdBy: this.state.createdBy,
    createdOn: this.state.createdOn,
    date: this.state.date,
    dosage: this.state.dosage,
    doseId: this.state.doseId,
    doseNumber: this.state.doseNumber,
    immunizationId: this.state.immunizationId,
    immunizationName: this.state.immunizationName,
    lotExpiryDate: this.state.lotExpiryDate,
    lotNumber: this.state.lotNumber,
    note: this.state.note,
    route: this.state.route,
    scheduleId: this.state.scheduleId,
    scheduleName: this.state.scheduleName,
    site: this.state.site,
    sourceOfInformation: this.state.sourceOfInformation,
    units: this.state.units,
  })

  getEmptyData = () => ({
    administeringProviderId: undefined,
    administeringProviderName: undefined,
    agent: undefined,
    consent: undefined,
    createdBy: undefined,
    createdOn: undefined,
    date: undefined,
    dosage: undefined,
    doseId: undefined,
    doseNumber: undefined,
    immunizationId: undefined,
    immunizationName: undefined,
    lotExpiryDate: undefined,
    lotNumber: undefined,
    note: '',
    route: undefined,
    scheduleId: undefined,
    scheduleName: undefined,
    site: undefined,
    sourceOfInformation: undefined,
    units: undefined,
  })

  handleChange = (key: string, value: TImmunizationTemplateValue) => {
    this.setState((state, props) => ({ [key]: value }))
  }

  save = async () => {
    console.log('SAVEDDDD', this.type)
  }

  cancel = async () => {
    console.log('Cancel called', this.type)
  }

  render() {
    return <ImmunizationTemplateComponent {...this.state} onChange={this.handleChange} />
  }
}

export default ImmunizationTemplate

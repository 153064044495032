import { useParams } from 'react-router-dom'
import useClientDetails from 'modules/shared/hooks/useClientDetails'
import useGroups from 'modules/shared/hooks/useGroups'
import ClientRecordHeaderDetails from './ClientRecordHeaderDetails'

type ClientRecordHeaderProps = {
  allowChangeContext?: boolean
}
const ClientRecordHeader = ({ allowChangeContext = false }: ClientRecordHeaderProps) => {
  const { clientId } = useParams()
  const { groupId } = useParams()
  const { clientDetails } = useClientDetails(clientId)
  const { chartingGroupInContext } = useGroups(groupId)
  return (
    <ClientRecordHeaderDetails
      allowChangeContext={allowChangeContext}
      client={clientDetails}
      group={chartingGroupInContext}
    />
  )
}

export default ClientRecordHeader

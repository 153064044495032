/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExpertiseResponseDTO } from '../models/ExpertiseResponseDTO'
import type { PagePersonnelDTO } from '../models/PagePersonnelDTO'
import type { PersonnelDTO } from '../models/PersonnelDTO'
import type { PersonnelLightDTO } from '../models/PersonnelLightDTO'
import type { PersonnelSuperLightDTO } from '../models/PersonnelSuperLightDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PersonnelControllerService {
  /**
   * @param personnelId
   * @returns PersonnelDTO Successfully retrieved the specified personnel
   * @throws ApiError
   */
  public static getPersonnel(personnelId: string): CancelablePromise<PersonnelDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}',
      path: {
        personnelId: personnelId,
      },
      errors: {
        403: `User does not have permission to view the personnel`,
      },
    })
  }

  /**
   * @param personnelId
   * @param requestBody
   * @returns PersonnelDTO Successfully updated the specified personnel
   * @throws ApiError
   */
  public static updatePersonnel(
    personnelId: string,
    requestBody: PersonnelLightDTO
  ): CancelablePromise<PersonnelDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}',
      path: {
        personnelId: personnelId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `User does not have permission to update the personnel`,
      },
    })
  }

  /**
   * @param personnelId
   * @returns void
   * @throws ApiError
   */
  public static deletePersonnel(personnelId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}',
      path: {
        personnelId: personnelId,
      },
      errors: {
        403: `User does not have permission to delete the personnel`,
      },
    })
  }

  /**
   * @param name
   * @param expertise
   * @param genderCodeSystemOid
   * @param genderCode
   * @param isUser
   * @param identifierCodeSystemOid
   * @param identifierCode
   * @param identifierValue
   * @param jobFunctionIsActive
   * @param isStaff
   * @param jobTitle
   * @param jobFunctionIsInternal
   * @param jobFunctions
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PagePersonnelDTO Successfully retrieved the specified personnel
   * @throws ApiError
   */
  public static searchPersonnel(
    name?: string,
    expertise?: string,
    genderCodeSystemOid?: string,
    genderCode?: string,
    isUser?: boolean,
    identifierCodeSystemOid?: string,
    identifierCode?: string,
    identifierValue?: string,
    jobFunctionIsActive?: boolean,
    isStaff?: boolean,
    jobTitle?: string,
    jobFunctionIsInternal?: boolean,
    jobFunctions?: Array<string>,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PagePersonnelDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel',
      query: {
        name: name,
        expertise: expertise,
        genderCodeSystemOid: genderCodeSystemOid,
        genderCode: genderCode,
        isUser: isUser,
        identifierCodeSystemOid: identifierCodeSystemOid,
        identifierCode: identifierCode,
        identifierValue: identifierValue,
        jobFunctionIsActive: jobFunctionIsActive,
        isStaff: isStaff,
        jobTitle: jobTitle,
        jobFunctionIsInternal: jobFunctionIsInternal,
        jobFunctions: jobFunctions,
        page: page,
        size: size,
        sort: sort,
      },
      errors: {
        403: `User does not have permission to search the personnel`,
      },
    })
  }

  /**
   * @param requestBody
   * @param isFromUserService
   * @returns PersonnelLightDTO Successfully created the specified personnel
   * @throws ApiError
   */
  public static createPersonnel(
    requestBody: PersonnelLightDTO,
    isFromUserService?: string
  ): CancelablePromise<PersonnelLightDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/personnel',
      query: {
        isFromUserService: isFromUserService,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `User does not have permission to create the personnel`,
      },
    })
  }

  /**
   * @param personnelIds
   * @returns PersonnelSuperLightDTO Successfully retrieved the specified personnel
   * @throws ApiError
   */
  public static getPersonnelLightList(
    personnelIds?: Array<string>
  ): CancelablePromise<Array<PersonnelSuperLightDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/ids',
      query: {
        personnelIds: personnelIds,
      },
      errors: {
        403: `User does not have permission to view the personnel`,
      },
    })
  }

  /**
   * @param userId
   * @returns PersonnelSuperLightDTO Successfully retrieved the specified personnel
   * @throws ApiError
   */
  public static findPersonnelByUserId(userId: string): CancelablePromise<PersonnelSuperLightDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/find-by-userId/{userId}',
      path: {
        userId: userId,
      },
      errors: {
        403: `User does not have permission to view the personnel`,
      },
    })
  }

  /**
   * @returns ExpertiseResponseDTO Successfully retrieved the expertises
   * @throws ApiError
   */
  public static getAllExpertises(): CancelablePromise<ExpertiseResponseDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/expertises',
      errors: {
        403: `User does not have permission to view the expertises`,
      },
    })
  }
}

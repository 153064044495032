import { Component, MouseEvent as ReactMouseEvent } from 'react'
import { SketchPicker } from 'react-color'
import ImageMarker, { Marker, MarkerComponentProps } from 'react-image-marker'
import { AddCircleOutline } from '@mui/icons-material'
import CircleIcon from '@mui/icons-material/Circle'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Fab, Grid, IconButton, Popover, Stack, Tooltip } from '@mui/material'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import AuthService from 'core/helpers/security/AuthService'
import { DocumentControllerService } from 'services/openapi'
import { ITemplate } from '../../blots/TemplateBlot'

type TAnnotation = {
  annotation: string
  id: string
  markerColor: string
}

export type ImageMarkerTemplateState = {
  annotations?: TAnnotation[]
  documentId?: string
  file?: File
  imageBase64Data?: string // only for display, not stored in the charting entry in db
  imageType?: string
  imageSketchPickerAnchorEl?: Element
  markerColor: string
  markers?: Marker[]
  markerSketchPickerAnchorEls: (Element | undefined)[]
}

type ImageMarkerTemplateProps = { data?: ImageMarkerTemplateState }

export const convertToBase64 = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(blob)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export default class ImageMarkerTemplate
  extends Component<ImageMarkerTemplateProps, ImageMarkerTemplateState>
  implements ITemplate
{
  type = 'ImageMarkerTemplate'
  state: ImageMarkerTemplateState = { markerColor: '#000', markerSketchPickerAnchorEls: [] }
  documentId: string | null = null

  constructor(props: ImageMarkerTemplateProps) {
    super(props)
    this.state = {
      ...props.data,
      markerColor: '#000',
      markerSketchPickerAnchorEls: props.data?.annotations
        ? new Array(props.data.annotations.length).fill(undefined)
        : [],
    }
  }

  async componentDidMount() {
    if (this.state.documentId && !this.state.imageBase64Data) {
      try {
        const response = await DocumentControllerService.getDocumentById(
          this.state.documentId,
          undefined,
          true
        )
        const base64Data = 'data:image/*;base64,' + response
        this.setState({ ...this.state, imageBase64Data: base64Data })
      } catch (e) {
        // TODO
      }
    } else if (this.state.file && !this.state.imageBase64Data) {
      const base64 = await convertToBase64(this.state.file)
      this.setState({
        ...this.state,
        imageBase64Data: base64 as string,
        imageType: this.state.file.type,
      })
    }
  }

  getData = () => {
    return {
      annotations: this.state.annotations,
      documentId: this.state.documentId,
      image: undefined,
      imageBase64Data: this.state.imageBase64Data,
      imageType: this.state.imageType,
      markers: this.state.markers,
    }
  }

  getEmptyData = () => {
    return {
      annotations: undefined,
      documentId: undefined,
      image: undefined,
      imageBase64Data: undefined,
      imageType: undefined,
      markers: undefined,
    }
  }

  handleAddMarker = (marker: Marker) => {
    this.setState({
      annotations: [
        ...(this.state.annotations || []),
        { annotation: '', id: uuidv4(), markerColor: this.state.markerColor },
      ],
      markers: [...(this.state.markers || []), marker],
    })
  }

  handleMarkerColorClose = (index: number) => {
    const newMarkerSketchPickerAnchorEls = this.state.markerSketchPickerAnchorEls
    newMarkerSketchPickerAnchorEls.splice(index, 1, undefined)
    this.setState({
      markerSketchPickerAnchorEls: newMarkerSketchPickerAnchorEls,
    })
  }

  handleMarkerColorOpen = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    const newMarkerSketchPickerAnchorEls = this.state.markerSketchPickerAnchorEls
    newMarkerSketchPickerAnchorEls[index] = e.currentTarget
    this.setState({
      markerSketchPickerAnchorEls: newMarkerSketchPickerAnchorEls,
    })
  }

  handleFileUpload = async (file: File) => {
    const clientId = window.location.href.split('/')[5]
    const documentDTO = [
      {
        associatedEntities: [{ entityId: clientId, entityType: 'CLIENT_FILE' }],
        ontologyTitle: { code: '74155-3', codeSystemOid: '2.16.840.1.113883.6.1' },
      },
    ]
    const formData = new FormData()

    formData.append('files', file)

    formData.append('requestBodyJson', JSON.stringify(documentDTO))

    const token = AuthService.getAccessToken()
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/idhe-document-management-service/v1/documents`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    this.documentId = result?.[0]?.id || this.documentId

    await new Promise<void>((resolve) => {
      this.setState({ ...this.state, documentId: this.documentId || undefined }, resolve)
    })
  }

  handleRemoveImage = async () => {
    this.setState({
      annotations: undefined,
      documentId: undefined,
      imageBase64Data: undefined,
      imageType: undefined,
      markers: undefined,
    })
  }

  handleResetImage = async () => {
    try {
      const documentId = this.state.documentId || this.documentId
      if (documentId) {
        await DocumentControllerService.deleteDocument(documentId)
        this.documentId = null
      }
      this.setState({
        ...this.state,
        documentId: undefined,
      })
    } catch (e) {
      // TODO
    }
  }

  handleRemoveMarker = (index: number) => {
    if (
      this.state.annotations &&
      this.state.annotations.length > 0 &&
      this.state.markers &&
      this.state.markers.length > 0
    ) {
      const annotations = [...this.state.annotations]
      const markers = [...this.state.markers]
      annotations.splice(index, 1)
      markers.splice(index, 1)
      this.setState({ annotations, markers })
    }
  }

  handleUpdateAnnotation = (value: string, index: number) => {
    if (this.state.annotations && this.state.annotations.length > 0) {
      const annotations = [...this.state.annotations]
      annotations[index] = { ...annotations[index], annotation: value }
      this.setState({ annotations })
    }
  }

  handleUpdateMarkerColor = (value: string, index: number) => {
    if (this.state.annotations && this.state.annotations.length > 0) {
      const annotations = [...this.state.annotations]
      annotations[index] = { ...annotations[index], markerColor: value }
      const newMarkerSketchPickerAnchorEls = this.state.markerSketchPickerAnchorEls
      newMarkerSketchPickerAnchorEls.splice(index, 1, undefined)
      this.setState({ annotations, markerSketchPickerAnchorEls: newMarkerSketchPickerAnchorEls })
    }
  }

  handleAttachFiles = async (files: FileList) => {
    const file = files[0]
    const mimeType = file.type
    const base64 = await convertToBase64(file)
    this.setState({
      ...this.state,
      file: file,
      imageBase64Data: base64 as string,
      imageType: mimeType,
    })
  }

  save = async () => {
    if (this.state.file) {
      try {
        await this.handleFileUpload(this.state.file)
      } catch (error) {
        return Promise.reject(error)
      }
    }
    return Promise.resolve()
  }

  cancel = async () => {
    this.handleResetImage()
  }

  CustomMarker = (props: MarkerComponentProps) => {
    const markerColor =
      this.state.annotations?.[props.itemNumber.valueOf()].markerColor || this.state.markerColor
    return (
      <Box
        className="custom-marker"
        sx={{
          alignItems: 'center',
          border: 2,
          borderColor: markerColor,
          borderRadius: '50%',
          color: markerColor,
          fontSize: '16px',
          fontWeight: 'bold',
          height: '28px',
          textAlign: 'center',
          width: '28px',
        }}
      >
        {`${props.itemNumber.valueOf() + 1}`}
      </Box>
    )
  }

  render() {
    return (
      <Box sx={{ mb: 1 }}>
        {this.state.imageBase64Data ? (
          <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  cursor: 'default',
                  display: 'inline-block',
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                <ImageMarker
                  markerComponent={this.CustomMarker}
                  markers={this.state.markers || []}
                  onAddMarker={this.handleAddMarker}
                  src={this.state.imageBase64Data}
                />
              </Box>
              <Stack spacing={1}>
                <Fab
                  aria-describedby="image-sketch-picker-popover"
                  onClick={(e) => this.setState({ imageSketchPickerAnchorEl: e.currentTarget })}
                  size="small"
                  sx={{ backgroundColor: this.state.markerColor }}
                />
                <Tooltip title="Remove image">
                  <Fab onClick={this.handleRemoveImage} size="small">
                    <DeleteIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title="Clear annotations">
                  <Fab
                    onClick={() => this.setState({ annotations: undefined, markers: undefined })}
                    size="small"
                  >
                    <CloseIcon />
                  </Fab>
                </Tooltip>
                <Popover
                  anchorEl={this.state.imageSketchPickerAnchorEl}
                  anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                  id="image-sketch-picker-popover"
                  onClose={() => this.setState({ imageSketchPickerAnchorEl: undefined })}
                  open={Boolean(this.state.imageSketchPickerAnchorEl)}
                >
                  <SketchPicker
                    color={this.state.markerColor}
                    onChange={(color) =>
                      this.setState({
                        imageSketchPickerAnchorEl: undefined,
                        markerColor: color.hex,
                      })
                    }
                  />
                </Popover>
              </Stack>
            </Stack>
            {this.state.annotations && !!(this.state.annotations.length > 0) && (
              <>
                {this.state.annotations.map((annotation, index) => (
                  <Grid alignItems="center" container justifyContent="left" key={annotation.id}>
                    <Grid item sx={{ pr: 2 }} xs={8}>
                      <MISTextField
                        defaultValue={this.state.annotations?.[index]?.annotation}
                        id={`annotation-${index + 1}`}
                        label={`Annotation ${index + 1}`}
                        multiline
                        onChange={(e) => this.handleUpdateAnnotation(e.target.value, index)}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        aria-label="change marker color"
                        onClick={(e) => this.handleMarkerColorOpen(e, index)}
                      >
                        <CircleIcon
                          aria-describedby="marker-sketch-picker-popover"
                          htmlColor={annotation.markerColor}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => this.handleRemoveMarker(index)}
                      >
                        <DeleteIcon htmlColor="#000" />
                      </IconButton>
                    </Grid>
                    <Popover
                      anchorEl={this.state.markerSketchPickerAnchorEls[index]}
                      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                      id="marker-sketch-picker-popover"
                      onClose={() => this.handleMarkerColorClose(index)}
                      open={Boolean(this.state.markerSketchPickerAnchorEls[index])}
                    >
                      <SketchPicker
                        color={annotation.markerColor}
                        onChange={(color) => this.handleUpdateMarkerColor(color.hex, index)}
                      />
                    </Popover>
                  </Grid>
                ))}
              </>
            )}
          </Stack>
        ) : (
          <Box
            className="upload-container"
            contentEditable
            onDrop={(e) => {
              e.preventDefault()
              e.dataTransfer.files && this.handleAttachFiles(e.dataTransfer.files)
            }}
            sx={{ border: '1px solid black', height: 550, width: 612 }}
          >
            <MISButton
              color="primary"
              component="label"
              role={undefined}
              size="large"
              startIcon={<AddCircleOutline />}
              tabIndex={-1}
              variant="contained"
            >
              Upload image
              <input
                accept=".jpeg, .png, .jpg"
                className="upload-input"
                onChange={(e) => e.target.files && this.handleAttachFiles(e.target.files)}
                type="file"
              />
            </MISButton>
          </Box>
        )}
      </Box>
    )
  }
}

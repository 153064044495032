import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { GroupControllerService } from 'services/openapi'
import { setChartingGroupInContext, setClientIdInContext } from 'store/reducers/charting'
import { resetClient } from 'store/reducers/client'
import { selectChartingGroupInContext } from 'store/selectors/charting'

const useGroups = (groupId: string | undefined) => {
  const dispatch = useDispatch()
  const { handleApiError } = useErrorHandler()
  const chartingGroupInContext = useSelector(selectChartingGroupInContext)

  useEffect(() => {
    const getGroups = async (groupId: string) => {
      try {
        const resp = await GroupControllerService.getGroup(groupId)
        dispatch(setChartingGroupInContext(resp))
        dispatch(resetClient())
        dispatch(setClientIdInContext(undefined))
      } catch (error) {
        handleApiError(error)
      }
    }
    if (groupId) {
      getGroups(groupId)
    }
  }, [dispatch, handleApiError, groupId])
  return { chartingGroupInContext }
}

export default useGroups

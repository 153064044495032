import 'quill-mention/dist/quill.mention.css'
import 'react-quill/dist/quill.snow.css'
import './Charting.scss'
import 'rc-dock/dist/rc-dock.css'

import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContextProps, useAuth } from 'react-oidc-context'
import { UnprivilegedEditor } from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import axios from 'axios'
import { DeltaStatic, Sources } from 'quill'
import DockLayout, { LayoutData, PanelData } from 'rc-dock'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import { MultiValueOption } from 'common/components/form/MISMultiValueAutocomplete'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import global from 'common/styles/global.scss'
import { isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { MISNavigationState } from 'core/components/navigation/MISNavigationState'
import CustomMultiValueAutocomplete from 'modules/client/ClientDetails/ClientPrivacyDirectives/CustomMultiValueAutocomplete'
import ClientRecordHeader from 'modules/client/ClientDetails/ClientRecordHeader'
import { getClientPreferredName } from 'modules/shared/clientUtils'
import useChartingEntries from 'modules/shared/hooks/useChartingEntries'
import useClientDetails from 'modules/shared/hooks/useClientDetails'
import { FilterProps } from 'modules/shared/hooks/useRunningNotes'
import { ErrorType, getError, hasError } from 'modules/shared/utils'
import {
  chartingAttendeesProgressState,
  chartingEditorProgressState,
  chartingFieldsProgressState,
  isDisplayInProgressState,
} from 'recoil/charting'
import { chartingEntryIdState } from 'recoil/lastupdated'
import { terminologySelector } from 'recoil/terminology'
import {
  CancelablePromise,
  ChartingEntryControllerService,
  ChartingEntryDTO,
  ClientControllerService,
  ClientDTO,
  FormSchemaControllerService,
} from 'services/openapi'
import { MIS_GENDER_VOCAB_NAME } from 'services/terminologyConstants'
import {
  setActiveHistoricTabs,
  setChartingClients,
  setCurrentTemplate,
  setValidationActive,
} from 'store/reducers/charting'
import { setHistoricalTrigger } from 'store/reducers/historicalTrigger'
import {
  selectChartingActiveHistoricTabs,
  selectChartingClients,
  selectChartingCurrentTemplate,
  selectChartingGroupInContext,
} from 'store/selectors/charting'
import { selectClientDetails } from 'store/selectors/client'
import TemplateBlot from './components/blots/TemplateBlot'
import ChartingEntries from './components/ChartingEntries'
import ChartingFields from './components/ChartingFields'
import ChartingServiceFields from './components/ChartingServiceFields'
import Editor, { NavigationDetail } from './components/editor/Editor'
import HistoricalPanel from './components/historical/HistoricalPanel'
import { getMetadataForTemplate } from './components/historical/utils'
import SaveTemplateDialog from './components/template-management/SaveTemplateDialog'

export interface TValue {
  id: number | string
  disabled?: boolean
  value: string
  type?: string
}

export type DocumentDetails = {
  fileId?: string
  name: string
  fileSize: number
}

const validateChartingDataTemplates = (chartingDataDelta: DeltaStatic): boolean => {
  let isValid = true
  chartingDataDelta.forEach((eachDelta) => {
    const templateBlot = eachDelta.insert?.['template-blot']
    if (templateBlot?.templateName) {
      if (templateBlot.templateName === 'AllergyTemplate') {
        if (
          (!templateBlot.templateData?.allergenOther && !templateBlot.templateData?.allergen) ||
          (templateBlot.templateData?.allergenOther &&
            !templateBlot.templateData?.allergenSpecified)
        )
          isValid = false
        if (!templateBlot.templateData?.type) isValid = false
      } else {
        const metadata = getMetadataForTemplate(templateBlot.templateName)
        if (metadata) {
          const keys = Object.keys(metadata)
          const requiredFields = keys.filter((key) => metadata[key].required)
          requiredFields.forEach((requiredField) => {
            if (!templateBlot.templateData?.[requiredField]) {
              isValid = false
            }
          })
        }
      }
    }
  })

  return isValid
}

const Charting = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { clientId } = useParams()
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()
  const { showSnackError, showSnackSuccess } = useSnack()
  const auth: AuthContextProps = useAuth()
  const clientDetails = useSelector(selectClientDetails)
  const activeTabInfo = useSelector(selectChartingActiveHistoricTabs)
  const currentTemplate = useSelector(selectChartingCurrentTemplate)
  const [lastUpdatedChartingId, setLastUpdatedChartingId] = useRecoilState(chartingEntryIdState)
  useClientDetails(clientId)
  const [chartingEditorProgress, setChartingEditorProgress] = useRecoilState(
    chartingEditorProgressState
  )
  const [chartingFieldsProgress, setChartingFieldsProgress] = useRecoilState(
    chartingFieldsProgressState
  )
  const [chartingAttendeesProgress, setChartingAttendeesProgress] = useRecoilState(
    chartingAttendeesProgressState
  )
  const [seletedMenuItem, setSeletedMenuItem] = useRecoilState(MISNavigationState)
  const setIsDisplayInProgress = useSetRecoilState(isDisplayInProgressState)

  const [menuItem, setMenuItem] = useState<string | undefined>(undefined)
  const [chartingData, setChartingData] = useState<ChartingEntryDTO>({
    duration: undefined,
    id: undefined,
    primaryProvider: undefined,
    serviceDateTime: undefined,
    status: undefined,
    statusComment: undefined,
    statusReason: undefined,
  })
  const [programs, setPrograms] = useState<TValue[]>([])
  const [services, setServices] = useState<TValue[]>([])

  const [openTemplateDialog, setOpenTemplateDialog] = useState(false)

  const [applyFilters, setApplyFilters] = useState(false)
  const [filters, setFilters] = useState<FilterProps[]>([])
  const [isDisplayRevHistory, setIsDisplayRevHistory] = useState(false)
  const [selectedAttendees, setSelectedAttendees] = useState<MultiValueOption[]>([])
  const [searchText, setSearchText] = useState('')
  const [attendeeOptions, setAttendeeOptions] = useState<MultiValueOption[]>([])
  const genderOptions = useRecoilValue(terminologySelector(MIS_GENDER_VOCAB_NAME))
  const chartingGroupInContext = useSelector(selectChartingGroupInContext)
  const chartingClients = useSelector(selectChartingClients)
  const { chartingEntries, providers, setChartingEntries, sortData } = useChartingEntries(
    clientId || '',
    chartingGroupInContext?.id || '',
    filters,
    applyFilters
  )
  const [errors, setErrors] = useState<ErrorType[]>([])

  const chartDataRef = useRef<{ delta: DeltaStatic | undefined }>()
  const chartSelectedIdRef = useRef<string | undefined>()
  const editorRef = useRef<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getAttachments: () => any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setAttachments: (attachments: any[]) => void
    getClientMentions: () => string[] | undefined
    setClientMentions: (clientMentions: string[] | undefined) => void
    getClientIdContext: () => string | undefined
    setClientIdContext: (clientId: string | undefined) => void
    getContents: () => DeltaStatic | undefined
    setContents: (contents: string | DeltaStatic) => void
    setServiceDateTime: (setServiceDateTime: string | undefined) => void
    setNavigationDetails: (navigationDetail: NavigationDetail | undefined) => void
    getNavigationDetails: () => NavigationDetail | undefined
    setPrograms: (programs: TValue[] | undefined) => void
    getPrograms: () => TValue[] | undefined
    setServices: (services: TValue[] | undefined) => void
    getServices: () => TValue[] | undefined
  }>()
  const dockLayoutRef = useRef<DockLayout | null>(null)

  const getClientDetail = useCallback(
    (client: ClientDTO) => {
      const preferredName = getClientPreferredName(client)
      const gender =
        client.gender && genderOptions?.find((option) => option.code === client.gender?.code)?.name
      const birthDate = client.birthdate ? isoDateToDisplayFormat(client.birthdate) : ''
      return `${preferredName}|${t('client-header.gender-label')}: ${gender}| ${t(
        'client-header.birthdate-label'
      )}:${birthDate}`
    },
    [genderOptions, t]
  )

  useEffect(() => {
    setSelectedAttendees([])
    if (chartingClients && chartingClients.length > 0) {
      const chartingClientList = chartingClients.map((cl) => ({
        label: getClientDetail(cl),
        value: cl.id as string,
      }))
      if (clientId) {
        const attendesList = chartingClientList.filter((c) => c.value !== clientId)
        setSelectedAttendees(attendesList)
      } else {
        setSelectedAttendees(chartingClientList)
      }
    }
  }, [chartingClients, clientId, getClientDetail])

  const handleChangeAttendee = useCallback(
    (newValue) => {
      if (
        seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress' ||
        seletedMenuItem === 'navigation.left-nav-menu.group.charting.in-progress'
      ) {
        setChartingAttendeesProgress(newValue)
      }

      setSelectedAttendees(newValue)
    },
    [seletedMenuItem, setChartingAttendeesProgress]
  )

  useEffect(() => {
    const getClients = async (searchTerm: string) => {
      try {
        const resp = await ClientControllerService.searchClient(searchTerm, '', '', '', -1)
        if (resp.content && resp.content.length > 0) {
          const options = resp.content.map((each) => {
            return { label: getClientDetail(each), value: each.id as string }
          })
          setAttendeeOptions(options)
        }
      } catch (err) {
        handleApiError(err)
      }
    }
    if (searchText && searchText.length >= 3) getClients(searchText)
  }, [searchText, handleApiError, getClientDetail])

  useEffect(() => {
    editorRef.current?.setClientIdContext(clientId)
  }, [clientId])

  const resetCharting = useCallback(() => {
    chartSelectedIdRef.current = undefined
    editorRef.current?.setContents('')
    chartDataRef.current = { delta: undefined }
    setPrograms([])
    setServices([])
    setChartingData({
      duration: undefined,
      id: undefined,
      primaryProvider: undefined,
      serviceDateTime: undefined,
      status: undefined,
      statusComment: undefined,
      statusReason: undefined,
    })
    dispatch(setValidationActive(false))
  }, [dispatch])

  useEffect(() => {
    const navigationDetails = editorRef.current?.getNavigationDetails()
    if (navigationDetails) {
      const updatedNavigationdetail = { ...navigationDetails, activeMenuItem: seletedMenuItem }
      editorRef.current?.setNavigationDetails(updatedNavigationdetail)
    } else {
      editorRef.current?.setNavigationDetails({ activeMenuItem: seletedMenuItem, fromMenuItem: '' })
    }
    if (
      menuItem !== seletedMenuItem &&
      (seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress' ||
        seletedMenuItem === 'navigation.left-nav-menu.group.charting.in-progress')
    ) {
      setTimeout(() => {
        setMenuItem(seletedMenuItem)
        if (chartingAttendeesProgress) {
          setSelectedAttendees(chartingAttendeesProgress)
        } else {
          setSelectedAttendees([])
        }
        if (chartingEditorProgress) {
          const { chartingEntryData } = chartingEditorProgress
          const json = chartingEntryData
          editorRef.current?.setContents(json?.delta || '')
          setPrograms(json?.programs || [])
          setServices(json?.services || [])
          setChartingData({
            duration: chartingFieldsProgress?.duration,
            id: chartingFieldsProgress?.id,
            primaryProvider: chartingFieldsProgress?.primaryProvider,
            serviceDateTime: chartingFieldsProgress?.serviceDateTime,
            status: chartingFieldsProgress?.status,
            statusComment: chartingFieldsProgress?.statusComment,
            statusReason: chartingFieldsProgress?.statusReason,
          })
        } else {
          resetCharting()
        }
      }, 0)
    } else if (menuItem !== seletedMenuItem) {
      setMenuItem(seletedMenuItem)
      resetCharting()
      setSelectedAttendees([])
    }
  }, [
    chartingAttendeesProgress,
    chartingEditorProgress,
    chartingFieldsProgress?.duration,
    chartingFieldsProgress?.id,
    chartingFieldsProgress?.primaryProvider,
    chartingFieldsProgress?.serviceDateTime,
    chartingFieldsProgress?.status,
    chartingFieldsProgress?.statusComment,
    chartingFieldsProgress?.statusReason,
    menuItem,
    resetCharting,
    seletedMenuItem,
  ])

  const setChartingDataHandler = useCallback(
    (data: ChartingEntryDTO) => {
      setChartingData(data)
      const chartingData = {
        duration: data?.duration || chartingFieldsProgress?.duration,
        id: data?.id || chartingFieldsProgress?.id,
        primaryProvider: data?.primaryProvider || chartingFieldsProgress?.primaryProvider,
        serviceDateTime: data?.serviceDateTime || chartingFieldsProgress?.serviceDateTime,
        status: data?.status || chartingFieldsProgress?.status,
        statusComment: data?.statusComment || chartingFieldsProgress?.statusComment,
        statusReason: data?.statusReason || chartingFieldsProgress?.statusReason,
      }
      setChartingFieldsProgress(chartingData)
    },
    [chartingFieldsProgress, setChartingFieldsProgress]
  )

  const storeEditorData = useCallback(() => {
    const navigationDetails = editorRef.current?.getNavigationDetails()
    const activeItemMenu = navigationDetails?.activeMenuItem
    if (
      activeItemMenu === 'navigation.left-nav-menu.charting.blank-canvas' ||
      activeItemMenu === 'navigation.left-nav-menu.client.charting.in-progress' ||
      activeItemMenu === 'navigation.left-nav-menu.group.charting.in-progress'
    ) {
      const programs = editorRef.current?.getPrograms()
      const services = editorRef.current?.getServices()
      const updatedChartingData = { ...chartDataRef.current, programs, services }
      const chartingEntryData = {
        ...JSON.parse(JSON.stringify(updatedChartingData)),
      }
      const chartEntryDTO = {
        chartingEntryData,
        status: {
          code: 'COMPLETE',
          codeSystemOid: '2.16.840.1.113883.3.1019.1.8',
        },
      }
      setChartingEditorProgress(chartEntryDTO)
    }
  }, [setChartingEditorProgress])

  const handleChangeProgram = useCallback(
    (programs: TValue[]) => {
      editorRef.current?.setPrograms(programs)
      setPrograms(programs)
      storeEditorData()
    },
    [storeEditorData]
  )

  const handleChangeServices = useCallback(
    (services: TValue[]) => {
      editorRef.current?.setServices(services)
      setServices(services)
      storeEditorData()
    },
    [storeEditorData]
  )

  const handleChange = useCallback(
    async (_value: string, _delta: DeltaStatic, _source: Sources, editor: UnprivilegedEditor) => {
      const content = editor.getContents()
      chartDataRef.current = { delta: content }
      storeEditorData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSeletedMenuItem, storeEditorData]
  )

  const setClientsForCharting = useCallback(
    (clientIds: string[] | undefined) => {
      const getChartingClientDTOs = async (ids: string[]) => {
        const promises: CancelablePromise<ClientDTO>[] = []
        ids.forEach((id) => promises.push(ClientControllerService.getClient(id, undefined, false)))
        const clients = await Promise.all(promises)
        dispatch(setChartingClients(clients))
      }
      if (clientIds && clientIds.length > 0) {
        getChartingClientDTOs(clientIds)
      }
    },
    [dispatch]
  )

  const handleSelectChart = useCallback(
    (chartingEntryDTO: ChartingEntryDTO, duplicate?: boolean, isChartRevHistory?: boolean) => {
      setErrors([])
      dispatch(setValidationActive(false))
      const { chartingEntryData, clientIds, id } = chartingEntryDTO
      setClientsForCharting(clientIds)
      const json = chartingEntryData
      if (duplicate) chartSelectedIdRef.current = undefined
      else chartSelectedIdRef.current = id
      editorRef.current?.setContents(json?.delta || '')
      setPrograms(json?.programs || [])
      setServices(json?.services || [])
      setChartingData(chartingEntryDTO)
      if (isChartRevHistory) {
        setIsDisplayRevHistory(true)
      } else {
        setIsDisplayRevHistory(false)
      }
      if (json?.documents) {
        editorRef.current?.setAttachments(json?.documents)
      }
    },
    [dispatch, setClientsForCharting]
  )

  const handleSelectChartRevHistory = useCallback(
    (data: ChartingEntryDTO, isLatestVersion: boolean) => {
      let chartingDTO = data
      if (isLatestVersion && chartingEntries) {
        chartingDTO = chartingEntries.find((dto) => dto.id === data.id) || data
      }
      handleSelectChart(chartingDTO, false, !isLatestVersion)
      setChartingDataHandler(chartingDTO)
      setIsDisplayRevHistory(!isLatestVersion)
    },
    [chartingEntries, handleSelectChart, setChartingDataHandler, setIsDisplayRevHistory]
  )

  // need to make is async by settimeout otherwise it executes before recoil is updated,
  // works with async wait also. Also tried giving window.location.pathname
  //as dependency, but it doesn't work with it.
  useEffect(() => {
    setTimeout(() => {
      if (
        seletedMenuItem === 'navigation.left-nav-menu.client.charting' ||
        seletedMenuItem === 'navigation.left-nav-menu.group.charting'
      ) {
        if (chartingEntries && chartingEntries?.length > 0) {
          const selectedId = lastUpdatedChartingId || chartingEntries[0]?.id
          const selectedChartingEntry = chartingEntries?.find(
            (chartingEntry) => chartingEntry.id === selectedId
          )
          handleSelectChart(selectedChartingEntry || chartingEntries[0])
        } else {
          resetCharting()
        }
      }
    }, 500)
  }, [chartingEntries, handleSelectChart, lastUpdatedChartingId, resetCharting, seletedMenuItem])

  const isDisplaySaveButton = useCallback(() => {
    if (
      (seletedMenuItem === 'navigation.left-nav-menu.client.charting' ||
        seletedMenuItem === 'navigation.left-nav-menu.group.charting') &&
      isDisplayRevHistory
    ) {
      return false
    }

    if (
      seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress' ||
      seletedMenuItem === 'navigation.left-nav-menu.group.charting.in-progress'
    ) {
      return true
    }

    if (
      chartSelectedIdRef.current &&
      (seletedMenuItem === 'navigation.left-nav-menu.client.charting' ||
        seletedMenuItem === 'navigation.left-nav-menu.group.charting')
    ) {
      return true
    }
  }, [isDisplayRevHistory, seletedMenuItem])

  const isDisplayCancelButton = useCallback(() => {
    return (
      seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress' ||
      seletedMenuItem === 'navigation.left-nav-menu.group.charting.in-progress'
    )
  }, [seletedMenuItem])

  const handleCancel = useCallback(() => {
    setChartingAttendeesProgress(undefined)
    setIsDisplayInProgress(false)
    setChartingEditorProgress(undefined)
    setChartingFieldsProgress(undefined)
    editorRef.current?.setClientMentions(undefined)
    if (clientId) {
      setSeletedMenuItem('navigation.left-nav-menu.client.charting')
      navigate(`/clients/client-record/${clientId}/charting`)
    } else if (chartingGroupInContext && chartingGroupInContext.id) {
      setSeletedMenuItem('navigation.left-nav-menu.group.charting')
      navigate(`/groups/group-record/${chartingGroupInContext.id}/charting`)
    }
  }, [
    chartingGroupInContext,
    clientId,
    navigate,
    setChartingAttendeesProgress,
    setChartingEditorProgress,
    setChartingFieldsProgress,
    setIsDisplayInProgress,
    setSeletedMenuItem,
  ])

  const handleSubmit = useCallback(async () => {
    const validate = () => {
      const groupId = chartingGroupInContext && chartingGroupInContext.id
      let isValid = true
      const errors: ErrorType[] = []
      if (!chartingData?.serviceDateTime) {
        isValid = false
        errors.push({
          field: `charting-service-date-time`,
          message: t('charting.validation.charting-service-date-time-required'),
        })
      }
      if (!chartingData?.primaryProvider) {
        isValid = false
        errors.push({
          field: `charting-primary-provider`,
          message: t('charting.validation.charting-primary-provider-required'),
        })
      }
      if (groupId && selectedAttendees.length === 0) {
        isValid = false
        errors.push({
          field: `charting-attendees`,
          message: t('charting.validation.charting-attendee-required'),
        })
      }

      if (
        chartDataRef.current?.delta &&
        (chartingData.status?.code === 'COMPLETE' || chartingData.status?.code === 'REVISED')
      )
        isValid = validateChartingDataTemplates(chartDataRef.current.delta)
      return { errors, isValid }
    }

    const saveChartingEntries = async () => {
      const groupId = chartingGroupInContext && chartingGroupInContext.id

      const clientIds = selectedAttendees.map((att) => att.value)
      if (clientId) {
        clientIds.push(clientId)
      }
      if (clientId || groupId) {
        const documents: DocumentDetails[] = []
        if (chartDataRef.current && chartDataRef.current.delta) {
          const blotIds: string[] = []
          const deltaEntries = Object.values(chartDataRef.current.delta)
          for (const eachDelta of deltaEntries[0]) {
            const templateBlot = eachDelta.insert?.['template-blot']

            if (templateBlot?.blotId) {
              blotIds.push(templateBlot.blotId)
            } else if (!templateBlot && typeof eachDelta.insert === 'string') {
              //incase there is a text without template-blot and has string, that means its a note, and notes need blotId in their attributes
              if (!eachDelta.attributes?.blotId) {
                eachDelta.attributes = { ...eachDelta.attributes, blotId: uuidv4() }
              }
            }
          }
          try {
            await TemplateBlot.saveAllTemplates(blotIds)
          } catch (err) {
            showSnackError('Erro saving one or more templates')
            return
          }
          const attachments = editorRef.current?.getAttachments()

          if (attachments && attachments.length > 0) {
            try {
              const newAttachments = attachments.filter((attachment) => !attachment.fileId)
              const oldAttachments = attachments.filter((attachment) => attachment.fileId)
              if (newAttachments.length !== 0) {
                const documentDTOs = newAttachments.map((attachment) => ({
                  associatedEntities: [{ entityId: clientId, entityType: 'CLIENT_CHARTING_ENTRY' }],
                  ontologyTitle: { code: '74155-3', codeSystemOid: '2.16.840.1.113883.6.1' },
                }))
                const formData = new FormData()

                newAttachments.forEach((attachment) => {
                  formData.append('files', attachment.file)
                })

                formData.append('requestBodyJson', JSON.stringify(documentDTOs))
                const token = auth.user?.access_token || ''
                const result = await axios.post(
                  `${process.env.REACT_APP_API_URL}/api/idhe-document-management-service/v1/documents`,
                  formData,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'multipart/form-data',
                    },
                  }
                )

                result.data.forEach((document) => {
                  documents.push({
                    fileId: document.id,
                    fileSize: document.fileSize,
                    name: document.fileName,
                  })
                })
              }
              documents.push(...oldAttachments)
            } catch (err) {
              handleApiError(err)
              return
            }
          }

          for (const eachDelta of deltaEntries[0]) {
            const templateBlot = eachDelta.insert?.['template-blot']

            if (templateBlot?.blotId && templateBlot.templateData) {
              templateBlot.templateData = TemplateBlot.refs[templateBlot.blotId].current?.getData()
            }
          }

          const filteredDelta = chartDataRef.current.delta.ops?.map((each) => {
            if (
              each.insert &&
              each.insert['template-blot'] &&
              each.insert['template-blot'].isFormBuilder
            ) {
              const templateData = {
                components: each.insert['template-blot'].templateData?.components,
              }
              const templateBlot = { ...each.insert['template-blot'], templateData }
              return { insert: { 'template-blot': templateBlot } }
            }
            return each
          })
          chartDataRef.current.delta.ops = filteredDelta
        }
        if (
          seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress' ||
          seletedMenuItem === 'navigation.left-nav-menu.group.charting.in-progress'
        ) {
          let response
          try {
            response = await ChartingEntryControllerService.createChartingEntry1({
              chartingEntryData: { ...chartDataRef.current, documents, programs, services },
              clientIds,
              duration: chartingData?.duration,
              groupId,
              primaryProvider: chartingData?.primaryProvider,
              serviceDateTime: chartingData?.serviceDateTime,
              status: chartingData?.status,
            })
          } catch (err) {
            handleApiError(err)
          }
          setLastUpdatedChartingId(response.id as string)
          await setChartingEntries(sortData([response, ...(chartingEntries || [])]))
          setChartingEditorProgress(undefined)
          setChartingFieldsProgress(undefined)
          editorRef.current?.setClientMentions(undefined)
          setIsDisplayInProgress(false)
          setChartingAttendeesProgress(undefined)
          dispatch(setHistoricalTrigger())
          if (clientId) {
            setSeletedMenuItem('navigation.left-nav-menu.client.charting')
            navigate(`/clients/client-record/${clientId}/charting`)
          } else if (groupId) {
            setSeletedMenuItem('navigation.left-nav-menu.group.charting')
            navigate(`/groups/group-record/${groupId}/charting`)
          }

          const navigationDetails = editorRef.current?.getNavigationDetails()
          if (navigationDetails) {
            const updatedNavigationdetail = { ...navigationDetails, fromMenuItem: '' }
            editorRef.current?.setNavigationDetails(updatedNavigationdetail)
          }
        } else if (chartSelectedIdRef.current) {
          try {
            const response = await ChartingEntryControllerService.updateChartingEntry1(
              chartSelectedIdRef.current,
              {
                chartingEntryData: {
                  ...chartDataRef.current,
                  documents,
                  programs,
                  services,
                },
                clientIds,
                duration: chartingData?.duration,
                groupId,
                primaryProvider: chartingData?.primaryProvider,
                serviceDateTime: chartingData?.serviceDateTime,
                status: chartingData?.status,
                statusComment: chartingData.statusComment,
                statusReason: chartingData.statusReason,
              }
            )
            setLastUpdatedChartingId(response.id as string)
            await setChartingEntries(
              sortData(
                chartingEntries?.map((entry) => (entry?.id === response?.id ? response : entry)) ||
                  []
              )
            )
            dispatch(setHistoricalTrigger())
          } catch (err) {
            handleApiError(err)
          }
        }
      }
    }

    const { errors, isValid } = validate()
    if (isValid) {
      saveChartingEntries()
      setErrors([])
      dispatch(setValidationActive(false))
    } else {
      setErrors(errors)
      dispatch(setValidationActive(true))
      showSnackError(t('charting.templates.mandatory-fields-missing'))
    }
  }, [
    chartingData?.serviceDateTime,
    chartingData?.primaryProvider,
    chartingData.status,
    chartingData?.duration,
    chartingData.statusComment,
    chartingData.statusReason,
    chartingGroupInContext,
    t,
    clientId,
    selectedAttendees,
    seletedMenuItem,
    showSnackError,
    auth.user?.access_token,
    handleApiError,
    setLastUpdatedChartingId,
    setChartingEntries,
    sortData,
    chartingEntries,
    setChartingAttendeesProgress,
    setChartingEditorProgress,
    setChartingFieldsProgress,
    setIsDisplayInProgress,
    setSeletedMenuItem,
    navigate,
    programs,
    services,
    dispatch,
  ])

  const handleSaveAsTemplate = useCallback(async () => {
    if (chartDataRef.current && chartDataRef.current.delta) {
      const content = editorRef.current?.getContents()
      if (content) {
        const filteredDelta = content.ops?.map((each) => {
          if (
            each.insert &&
            each.insert['template-blot'] &&
            each.insert['template-blot'].isFormBuilder
          ) {
            const templateBlot = {
              ...each.insert['template-blot'],
            }

            templateBlot.templateData = TemplateBlot.getEmptyDataForBlot(templateBlot.blotId)
            return { insert: { 'template-blot': templateBlot } }
          }
          if (each.insert && each.insert['template-blot']) {
            each.insert['template-blot']['templateData'] = TemplateBlot.getEmptyDataForBlot(
              each.insert['template-blot']['blotId']
            )
          }
          return each
        })
        content.ops = filteredDelta

        const template = {
          dataDomainId: undefined,
          description: currentTemplate?.description,
          designation: currentTemplate?.designation || 'PRIVATE',
          name: currentTemplate?.name,
          schema: JSON.stringify(content),
          state: 'PUBLISHED',
          tags: currentTemplate?.tags,
          templateType: 'AGGREGATE_TEMPLATE',
          version: currentTemplate?.version,
        }
        try {
          await FormSchemaControllerService.putFormSchema({
            ...template,
          })
          setOpenTemplateDialog(false)
          showSnackSuccess(t('api.save-success'))
          dispatch(setCurrentTemplate(undefined))
        } catch (err) {
          handleApiError(err)
        }
      }
    }
  }, [
    currentTemplate?.description,
    currentTemplate?.designation,
    currentTemplate?.name,
    currentTemplate?.tags,
    currentTemplate?.version,
    dispatch,
    handleApiError,
    showSnackSuccess,
    t,
  ])

  const handleStateChange = useCallback(
    async (data: ChartingEntryDTO) => {
      const validate = () => {
        let isValid = true
        const errors: ErrorType[] = []
        if (!chartingData?.serviceDateTime) {
          isValid = false
          errors.push({
            field: `charting-service-date-time`,
            message: t('charting.validation.charting-service-date-time-required'),
          })
        }
        if (!chartingData?.primaryProvider) {
          isValid = false
          errors.push({
            field: `charting-primary-provider`,
            message: t('charting.validation.charting-primary-provider-required'),
          })
        }
        if (
          chartDataRef.current?.delta &&
          (data.status?.code === 'COMPLETE' || data.status?.code === 'REVISED')
        )
          isValid = validateChartingDataTemplates(chartDataRef.current.delta)
        return { errors, isValid }
      }
      const { errors, isValid } = validate()
      if (isValid) {
        setChartingData(data)
        setErrors([])
        dispatch(setValidationActive(false))
      } else {
        setErrors(errors)
        dispatch(setValidationActive(true))
        showSnackError(t('charting.templates.mandatory-fields-missing'))
        return
      }
      if (clientId && chartSelectedIdRef.current) {
        // filter out form builder template layout from charting data
        if (chartDataRef.current && chartDataRef.current.delta) {
          const filteredDelta = chartDataRef.current.delta.ops?.map((each) => {
            if (
              each.insert &&
              each.insert['template-blot'] &&
              each.insert['template-blot'].isFormBuilder
            ) {
              const templateData = {
                components: each.insert['template-blot'].templateData?.components,
              }
              const templateBlot = { ...each.insert['template-blot'], templateData }
              return { insert: { 'template-blot': templateBlot } }
            }
            return each
          })
          chartDataRef.current.delta.ops = filteredDelta
        }
        let response
        try {
          response = await ChartingEntryControllerService.updateChartingEntry(
            clientId,
            chartSelectedIdRef.current,
            {
              chartingEntryData: {
                ...chartDataRef.current,
                programs,
                services,
              },
              duration: chartingData?.duration,
              primaryProvider: chartingData?.primaryProvider,
              serviceDateTime: chartingData?.serviceDateTime,
              status: data?.status,
              statusComment: data.statusComment,
              statusReason: data.statusReason,
            }
          )
        } catch (err) {
          handleApiError(err)
        }
        setLastUpdatedChartingId(response.id as string)
        await setChartingEntries(
          sortData(
            chartingEntries?.map((entry) => (entry?.id === response?.id ? response : entry)) || []
          )
        )
      }
    },
    [
      clientId,
      chartingData?.serviceDateTime,
      chartingData?.primaryProvider,
      chartingData?.duration,
      t,
      dispatch,
      showSnackError,
      setLastUpdatedChartingId,
      setChartingEntries,
      sortData,
      chartingEntries,
      programs,
      services,
      handleApiError,
    ]
  )

  const isDisplayChartingList = useCallback(() => {
    return (
      (seletedMenuItem === 'navigation.left-nav-menu.client.charting' && clientDetails) ||
      (seletedMenuItem === 'navigation.left-nav-menu.group.charting' && chartingGroupInContext?.id)
    )
  }, [chartingGroupInContext, clientDetails, seletedMenuItem])
  const editor = useMemo(
    () => <Editor disableMentions onChange={handleChange} ref={editorRef} />,
    [handleChange]
  )

  const DockPanelTabs = {
    ChartingDocument: {
      cached: true,
      content: (
        <div className="charting-view" style={{ overflow: 'auto' }}>
          <div className="charting-content-view">
            <Box
              sx={{
                backgroundColor: global.WHITE,
                borderRadius: '8px',
                pl: 3,
                pr: 3,
                pt: 3,
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {isDisplaySaveButton() && (
                  <Box display="flex">
                    <MISButton
                      color="primary"
                      onClick={() => setOpenTemplateDialog(true)}
                      variant="contained"
                    >
                      Save as Template
                    </MISButton>
                    <MISButton
                      color="primary"
                      onClick={handleSubmit}
                      sx={{ marginLeft: 1 }}
                      variant="contained"
                    >
                      {t('common.button.save')}
                    </MISButton>
                    {isDisplayCancelButton() && (
                      <MISButton
                        color="secondary"
                        onClick={handleCancel}
                        sx={{ marginLeft: 1 }}
                        variant="contained"
                      >
                        {t('common.button.cancel')}
                      </MISButton>
                    )}
                  </Box>
                )}
              </div>

              <ChartingFields
                chartingData={chartingData}
                errors={errors}
                onChangeChartingData={setChartingDataHandler}
                onChangeState={handleStateChange}
                onSelectChartRevHistory={handleSelectChartRevHistory}
                providers={providers || []}
              />
              <ChartingServiceFields
                onChangePrograms={handleChangeProgram}
                onChangeServices={handleChangeServices}
                programs={programs}
                services={services}
              />
            </Box>
            <Box sx={{ pb: 3, pl: 3, pr: 3 }}>{editor}</Box>
          </div>
        </div>
      ),
      id: 'ChartingDocument',
      title: 'Charting Document',
    },
    ChartingEntries: {
      cached: true,
      content: (
        <div className="charting-view">
          {isDisplayChartingList() && (
            <div className="charting-list-view">
              <ChartingEntries
                applyFilters={applyFilters}
                chartingEntries={chartingEntries || []}
                filters={filters}
                onSelect={handleSelectChart}
                providers={providers || []}
                setApplyFilters={setApplyFilters}
                setFilters={setFilters}
              />
            </div>
          )}
        </div>
      ),
      id: 'ChartingEntries',
      title: 'Charting Entries',
    },
  }

  useEffect(
    () => editorRef.current?.setServiceDateTime(chartingData.serviceDateTime),
    [chartingData.serviceDateTime]
  )

  const historicalPanel = HistoricalPanel({ dockLayoutRef })

  const dockLayout: LayoutData = {
    dockbox: {
      children: [
        historicalPanel.panel,
        {
          children: [
            {
              group: 'card custom',
              panelLock: { minWidth: 50, widthFlex: 1 },
              tabs: [DockPanelTabs.ChartingEntries],
            },
            {
              group: 'card custom',
              panelLock: { minWidth: 400, widthFlex: 7 },
              tabs: [DockPanelTabs.ChartingDocument],
            },
          ],
          mode: 'horizontal',
          size: 400,
        },
      ],
      id: 'main',
      mode: 'vertical',
    },
  }

  const dockLayoutClientNoContext: LayoutData = {
    dockbox: {
      children: [
        historicalPanel.panel,
        {
          children: [
            {
              group: 'card custom',
              panelLock: { minWidth: 400, widthFlex: 7 },
              tabs: [DockPanelTabs.ChartingDocument],
            },
          ],
          mode: 'horizontal',
          size: 400,
        },
      ],
      id: 'main',
      mode: 'vertical',
    },
  }

  const isTabUpdateInProgress = useRef(false)

  const onLayoutChange = useCallback(
    (layoutData) => {
      if (isTabUpdateInProgress.current) {
        return
      }

      if (layoutData.dockbox.children[0].tabs.length < activeTabInfo.length) {
        const tabs = layoutData.dockbox.children[0].tabs.map((tab) => tab.id)
        const newTabs = activeTabInfo.filter((tab) => tabs.includes(tab.templateId))
        dispatch(setActiveHistoricTabs(newTabs))
        return
      }
    },
    [activeTabInfo, dispatch]
  )

  useEffect(() => {
    // When the user changes, all components need to be updated to use the newest information.
    if (dockLayoutRef !== undefined && dockLayoutRef.current !== undefined) {
      dockLayoutRef.current?.updateTab('ChartingEntries', DockPanelTabs.ChartingEntries)
      dockLayoutRef.current?.updateTab('ChartingDocument', DockPanelTabs.ChartingDocument)
    }
  }, [DockPanelTabs.ChartingDocument, DockPanelTabs.ChartingEntries])

  const location = useLocation()

  const displayDockLayoutWithContext = useCallback(() => {
    return (
      (clientId || chartingGroupInContext?.id) &&
      (seletedMenuItem === 'navigation.left-nav-menu.client.charting' ||
        seletedMenuItem === 'navigation.left-nav-menu.group.charting')
    )
  }, [chartingGroupInContext, clientId, seletedMenuItem])

  const displayDockLayoutWithNoContext = useCallback(() => {
    return (
      seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress' ||
      seletedMenuItem === 'navigation.left-nav-menu.group.charting.in-progress' ||
      seletedMenuItem === 'navigation.left-nav-menu.charting.blank-canvas'
    )
  }, [seletedMenuItem])

  useEffect(() => {
    const historicalPanel1 = dockLayoutRef.current?.find('historicalPanel') as PanelData
    const isBlankCanvas = seletedMenuItem === 'navigation.left-nav-menu.charting.blank-canvas'
    if (isBlankCanvas) {
      return
    } else if (activeTabInfo && historicalPanel1?.tabs.length === 0) {
      isTabUpdateInProgress.current = true
      const promises: Promise<void>[] = []
      activeTabInfo.forEach((tab) => {
        const promise = historicalPanel.addHistorical(tab.templateName, tab.filters || [])
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        isTabUpdateInProgress.current = false
      })
    }
  }, [activeTabInfo, dispatch, historicalPanel, location, seletedMenuItem])
  return (
    <MISBaseContainer>
      <ClientRecordHeader allowChangeContext />
      <Box
        sx={{
          '& .MuiFormControl-root': { width: '100%' },
          marginBottom: '16px',
          marginLeft: '24px',
          marginTop: '16px',
        }}
      >
        {seletedMenuItem !== 'navigation.left-nav-menu.charting.blank-canvas' && (
          <CustomMultiValueAutocomplete
            error={hasError(errors, 'charting-attendees')}
            helperText={getError(errors, 'charting-attendees')}
            label={t('client-header.charting-attendees-label')}
            onChange={(event: SyntheticEvent, newValue: string[]) => {
              handleChangeAttendee(newValue)
            }}
            onInputChange={(newValue: string) => {
              setSearchText(newValue)
            }}
            options={attendeeOptions}
            value={selectedAttendees || []}
          />
        )}
      </Box>
      {displayDockLayoutWithContext() && (
        <DockLayout
          defaultLayout={dockLayout}
          groups={historicalPanel.groups}
          onLayoutChange={onLayoutChange}
          ref={dockLayoutRef}
          style={{ minHeight: '100vh' }}
        />
      )}
      {displayDockLayoutWithNoContext() && (
        <DockLayout
          defaultLayout={dockLayoutClientNoContext}
          groups={historicalPanel.groups}
          onLayoutChange={onLayoutChange}
          ref={dockLayoutRef}
          style={{ minHeight: '100vh' }}
        />
      )}
      <SaveTemplateDialog
        handleClose={() => {
          setOpenTemplateDialog(false)
        }}
        handleSaveClicked={handleSaveAsTemplate}
        openDialog={openTemplateDialog}
      />
    </MISBaseContainer>
  )
}

export default Charting

import { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  ThemeProvider,
} from '@mui/material'
import global from 'common/styles/global.scss'
import misTheme from 'core/styles/theme'
import MISCheckbox from './MISCheckbox'
import MISChip from './MISChip'
import MISMarkdownRenderer from '../markdown/MISMarkdownRenderer'

export interface ChipValueType {
  id: string
  name: string
}

const MISSelectMultiDropdown = (props: any) => {
  const [currentValue, setCurrentValue] = useState<any[]>([])

  useEffect(() => {
    setCurrentValue(props.value)
  }, [props.value])

  const handleOnChange = (event: any) => {
    setCurrentValue(event.target.value)
  }

  const handleOnClick = (option: any) => {
    setCurrentValue((prevValue) => {
      if (prevValue.includes(option.value)) {
        return prevValue.filter((value) => value !== option.value)
      } else {
        return [...prevValue, option.value]
      }
    })
  }

  const handleDelete = (value: any) => () => {
    props.handleChipDelete(value)
    setCurrentValue((prevVal) => prevVal.filter((val) => val !== value))
  }

  const findLabelByValue = (value: any[]) => {
    if (!Array.isArray(value) || value.length === 0) {
      return props.placeholder // Return an empty string if the input is not an array or is an empty array
    }

    const options = props?.options?.filter((obj: any) => value.some((item) => item === obj.value))
    const labels = options.map((obj: any) => obj.label) // Extract the label values from each object
    const lastLabel = labels.pop() // Remove and store the last label

    if (labels.length === 0) {
      return lastLabel // Return just the last label if there is only one element in the array
    }

    return labels.join(', ') + ', ' + lastLabel
  }

  return (
    <ThemeProvider theme={misTheme}>
      <FormControl size={props.size || 'small'} sx={{ width: '100%' }}>
        <InputLabel required={props.required}>{props.label}</InputLabel>
        <Select
          readOnly={props.readOnly}
          {...props}
          multiple
          onChange={props.onChange || handleOnChange}
          renderValue={(value: any[]) => {
            if (!props.isChip) {
              return value.length !== 0 ? (
                findLabelByValue(value)
              ) : (
                <span className="placeholder-value">{props.placeholder}</span>
              )
            } else {
              const displayValues = props.chipValues || value
              if (displayValues?.length > 0) {
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {displayValues?.map((val: ChipValueType) => (
                      <MISChip
                        deleteIcon={<CloseIcon onMouseDown={(event) => event.stopPropagation()} />}
                        key={val.name}
                        label={val.name}
                        onDelete={handleDelete(val)}
                      />
                    ))}
                  </Box>
                )
              } else if (props.placeholder) {
                return <span className="placeholder-value">{props.placeholder}</span>
              }
            }
          }}
          // label is always shrinked with startAdornment https://github.com/mui/material-ui/issues/13898
          startAdornment={props.info ? <MISMarkdownRenderer {...props.info} /> : false}
          value={currentValue}
        >
          {props.options?.length > 0
            ? props.options.map((option: any) => (
                <MenuItem
                  key={option.label}
                  onClick={() => handleOnClick(option)}
                  sx={{
                    '&.MuiMenuItem-root:hover': {
                      backgroundColor: global.MENU_OPTION_HOVER_BACKGROUND,
                    },
                  }}
                  value={option.value}
                >
                  <MISCheckbox checked={currentValue?.indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))
            : props.children}
        </Select>
        {props.errorMessage && (
          <FormHelperText sx={{ color: `${global.FIELD_ERROR_TEXT}` }}>
            {props.errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    </ThemeProvider>
  )
}

export default MISSelectMultiDropdown

import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import MISMultiValueAutocomplete, {
  MultiValueOption,
} from 'common/components/form/MISMultiValueAutocomplete'
import CustomMultiValueAutocomplete from 'modules/client/ClientDetails/ClientPrivacyDirectives/CustomMultiValueAutocomplete'
import {
  IdheServiceControllerService,
  IdheServiceDTO,
  ProgramControllerService,
  ProgramTerse,
} from 'services/openapi'
import { TValue } from '../Charting'

interface OptionType {
  label: string
  value: string | number
}

export default function ChartingServiceFields({
  onChangePrograms,
  onChangeServices,
  programs,
  services,
}: {
  onChangePrograms: (program: TValue[]) => void
  onChangeServices: (program: TValue[]) => void
  services?: TValue[]
  programs?: TValue[]
}): JSX.Element {
  const { t } = useTranslation('common')

  const [programList, setProgramList] = useState<ProgramTerse[]>([])
  const [serviceList, setServiceList] = useState<IdheServiceDTO[]>([])
  const [chartingServices, setChartingServices] = useState<OptionType[]>([])
  const [chartingPrograms, setChartingProgams] = useState<MultiValueOption[]>([])

  useEffect(() => {
    const getProgServiceList = async () => {
      const programResponse = await ProgramControllerService.listPrograms(undefined, 9999, [
        'name',
        'asc',
      ])
      if (programResponse?.content) {
        setProgramList(programResponse?.content)
      }

      const serviceResponse = await IdheServiceControllerService.getAllServices(undefined, 9999, [
        'name',
        'asc',
      ])
      if (serviceResponse?.content) {
        setServiceList(serviceResponse?.content)
      }
    }
    getProgServiceList()
  }, [])

  const programOptions = useCallback(
    () =>
      programList
        ?.filter((p) => !chartingPrograms.find((cp) => cp.value === p.id))
        .map((program) => ({ label: program.name || '', value: program.id || '' })) || [],
    [programList, chartingPrograms]
  )

  useEffect(() => {
    if (programs) {
      const selectedPrograms = programs.map((p) => ({ label: p.value, value: p.id.toString() }))
      setChartingProgams(selectedPrograms)
    }
    if (services) {
      const selectedServices = services.map((p) => ({ label: p.value, value: p.id }))
      setChartingServices(selectedServices)
    }
  }, [programs, services])

  const serviceOptions = useCallback(
    () =>
      serviceList
        ?.filter((p) => !chartingServices.find((cp) => cp.value === p.id))
        .map((service) => ({ label: service.name, value: service.id })) || [],
    [serviceList, chartingServices]
  )
  const handleChangePrograms = useCallback(
    (values: any) => {
      setChartingProgams(values)
      if (Array.isArray(values)) {
        const updatedPrograms = values.map((v) => ({
          id: v.value,
          type: 'program',
          value: v.label,
        }))
        onChangePrograms(updatedPrograms)
      }
    },
    [onChangePrograms]
  )

  const handleChangeService = useCallback(
    (values: any) => {
      setChartingServices(values)
      if (Array.isArray(values)) {
        const updatedServices = values.map((v) => ({
          id: v.value,
          type: 'service',
          value: v.label,
        }))
        onChangeServices(updatedServices)
      }
    },
    [onChangeServices]
  )
  return (
    <Box sx={{ marginBottom: 1, width: '100%' }}>
      <Grid columnSpacing={2} container rowSpacing={1}>
        <Grid item xs={6}>
          <CustomMultiValueAutocomplete
            label={t('charting.canvas.services')}
            onChange={(event: SyntheticEvent, newValue: string[]) => {
              handleChangeService(newValue)
            }}
            options={serviceOptions()}
            placeholder={chartingServices.length > 0 ? '' : t('charting.canvas.service-warning')}
            shrinkLabel
            value={chartingServices || []}
          />
        </Grid>
        <Grid item xs={6}>
          <MISMultiValueAutocomplete
            label={t('charting.canvas.programs')}
            onChange={(event: SyntheticEvent, newValue: MultiValueOption[]) => {
              handleChangePrograms(newValue)
            }}
            options={programOptions()}
            placeholder={chartingPrograms.length > 0 ? '' : t('charting.canvas.program-warning')}
            required
            shrinkLabel
            value={chartingPrograms || []}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentDTO } from '../models/DocumentDTO'
import type { PageDocumentDTO } from '../models/PageDocumentDTO'
import type { TagsDTO } from '../models/TagsDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class DocumentControllerService {
  /**
   * Update a document
   * @param id
   * @param requestBodyJson
   * @param requestBody
   * @returns DocumentDTO OK
   * @throws ApiError
   */
  public static updateDocument(
    id: string,
    requestBodyJson: string,
    requestBody?: {
      file?: Blob
    }
  ): CancelablePromise<DocumentDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-document-management-service/v1/documents/{id}',
      path: {
        id: id,
      },
      query: {
        requestBodyJson: requestBodyJson,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Delete a document
   * @param id
   * @returns any OK
   * @throws ApiError
   */
  public static deleteDocument(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-document-management-service/v1/documents/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * Get paged list of documents with optional search params
   * The name param allows searching for items with a searchText across the filename, description and tags. Or on the type given an array of typeCodesAll items are returned if effectiveAt is not specified, including terminated items.
   * @param parentEntityId
   * @param searchText
   * @param documentTypeCodes
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageDocumentDTO OK
   * @throws ApiError
   */
  public static getDocumentList(
    parentEntityId: string,
    searchText?: string,
    documentTypeCodes?: Array<string>,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageDocumentDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-document-management-service/v1/documents',
      query: {
        parentEntityId: parentEntityId,
        searchText: searchText,
        documentTypeCodes: documentTypeCodes,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Add a document. DOESNT WORK WITH OPENAPI, USE AXIOS ON FRONT END INSTEAD!
   * @param files
   * @param requestBodyJson
   * @returns DocumentDTO OK
   * @throws ApiError
   */
  public static addDocument(
    files: Array<Blob>,
    requestBodyJson: string
  ): CancelablePromise<Array<DocumentDTO>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-document-management-service/v1/documents',
      query: {
        files: files,
        requestBodyJson: requestBodyJson,
      },
    })
  }

  /**
   * Get a document by id and version
   * @param id
   * @param version
   * @param base64Data
   * @returns binary OK
   * @throws ApiError
   */
  public static getDocumentById(
    id: string,
    version?: string,
    base64Data: boolean = false
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-document-management-service/v1/documents/{id}/file',
      path: {
        id: id,
      },
      query: {
        version: version,
        base64Data: base64Data,
      },
    })
  }

  /**
   * Get all tags
   * @returns TagsDTO OK
   * @throws ApiError
   */
  public static getAllTags(): CancelablePromise<TagsDTO> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-document-management-service/v1/documents/tags',
    })
  }
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GovernanceAgencyDTO } from '../models/GovernanceAgencyDTO'
import type { GovernanceAgencyWithDomainsDTO } from '../models/GovernanceAgencyWithDomainsDTO'
import type { OrganizationAssociationDTO } from '../models/OrganizationAssociationDTO'
import type { OrganizationUnPagedAssociationDTO } from '../models/OrganizationUnPagedAssociationDTO'
import type { PageGovernanceAgencyWithDomainsDTO } from '../models/PageGovernanceAgencyWithDomainsDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class GovernanceAgencyControllerService {
  /**
   * Get a GovernanceAgency
   * @param agencyId
   * @param verbose
   * @returns any Successfully retrieved the specified agency
   * @throws ApiError
   */
  public static getAgencyById(agencyId: string, verbose: boolean = false): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}',
      path: {
        agencyId: agencyId,
      },
      query: {
        verbose: verbose,
      },
      errors: {
        403: `User does not have permission to retrieve agency`,
      },
    })
  }

  /**
   * Update a GovernanceAgency
   * The properties of the agency are set in the JSON request body.
   * @param agencyId
   * @param requestBody
   * @returns GovernanceAgencyDTO Successfully updated the specified agency
   * @throws ApiError
   */
  public static updateAgency(
    agencyId: string,
    requestBody: GovernanceAgencyDTO
  ): CancelablePromise<GovernanceAgencyDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}',
      path: {
        agencyId: agencyId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Failed to create the specified agency`,
        403: `User does not have permission to update the agency`,
        404: `Either the URL is incorrect or the entity does not exist`,
      },
    })
  }

  /**
   * Get relationships of other agencies to the specified Agency
   * @param agencyId
   * @returns OrganizationUnPagedAssociationDTO Successfully retrieved the agency associations
   * @throws ApiError
   */
  public static getAgencyRelationships(
    agencyId: string
  ): CancelablePromise<OrganizationUnPagedAssociationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/agency-relationships',
      path: {
        agencyId: agencyId,
      },
      errors: {
        403: `User does not have permission to get the agency associations`,
      },
    })
  }

  /**
   * Add  relationships to another agencies for the specified Agency
   * Validation will be performed to check for date period overlaps with existing
   * @param agencyId
   * @param requestBody
   * @returns OrganizationAssociationDTO Successfully added the agency associations
   * @throws ApiError
   */
  public static upsertAgencyRelationships(
    agencyId: string,
    requestBody: Array<OrganizationAssociationDTO>
  ): CancelablePromise<Array<OrganizationAssociationDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/agency-relationships',
      path: {
        agencyId: agencyId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `User does not have permission to upsert the agency associations`,
      },
    })
  }

  /**
   * Search GovernanceAgency by name, including/excluding types and including terminated
   * @param includeTypes
   * @param excludeTypes
   * @param name
   * @param includeActiveOnly
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageGovernanceAgencyWithDomainsDTO Successfully retrieved the agency list based on the search criteria
   * @throws ApiError
   */
  public static searchAgencyListForSpecifiedType(
    includeTypes?: string,
    excludeTypes?: string,
    name?: string,
    includeActiveOnly: boolean = true,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageGovernanceAgencyWithDomainsDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies',
      query: {
        includeTypes: includeTypes,
        excludeTypes: excludeTypes,
        name: name,
        includeActiveOnly: includeActiveOnly,
        page: page,
        size: size,
        sort: sort,
      },
      errors: {
        403: `User does not have permission to search the agency list`,
      },
    })
  }

  /**
   * Create a GovernanceAgency
   * The properties of the agency are set in the JSON request body.
   * @param requestBody
   * @param addAgencyRelation
   * @returns GovernanceAgencyWithDomainsDTO Successfully create the specified agency
   * @throws ApiError
   */
  public static createAgency(
    requestBody: GovernanceAgencyWithDomainsDTO,
    addAgencyRelation?: string
  ): CancelablePromise<GovernanceAgencyWithDomainsDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies',
      query: {
        add_agency_relation: addAgencyRelation,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Failed to create the specified agency`,
        403: `User does not have permission to create the agency`,
        404: `Either the URL is incorrect or the entity does not exist`,
      },
    })
  }

  /**
   * Get departments to the specified Agency
   * @param agencyId
   * @returns GovernanceAgencyDTO Successfully retrieved the agency departments
   * @throws ApiError
   */
  public static getAgencyDepartments(
    agencyId: string
  ): CancelablePromise<Array<GovernanceAgencyDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/departments',
      path: {
        agencyId: agencyId,
      },
      errors: {
        403: `User does not have permission to get the agency departments`,
      },
    })
  }

  /**
   * Delete relationship by relationshipid
   * Soft deletes the relationship by setting deleted flag to true
   * @param agencyId
   * @param agencyRelationshipId
   * @returns void
   * @throws ApiError
   */
  public static deleteAgencyRelationship(
    agencyId: string,
    agencyRelationshipId: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/governance-agencies/{agencyId}/agency-relationships/{agencyRelationshipId}',
      path: {
        agencyId: agencyId,
        agencyRelationshipId: agencyRelationshipId,
      },
      errors: {
        403: `User does not have permission to delete the agency relationship`,
      },
    })
  }
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ClientSearchResultDTO } from './ClientSearchResultDTO'
import type { GroupSearchResultDTO } from './GroupSearchResultDTO'

export type HeaderSearchResultDTO = {
  id?: string
  type?: HeaderSearchResultDTO.type
  groupSearchResultDTO?: GroupSearchResultDTO
  clientSearchResultDTO?: ClientSearchResultDTO
}

export namespace HeaderSearchResultDTO {
  export enum type {
    GROUP = 'GROUP',
    CLIENT = 'CLIENT',
  }
}

import { Checkbox, CheckboxProps } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import global from 'common/styles/global.scss'
import misTheme from 'core/styles/theme'

const MISCheckbox = (props: CheckboxProps) => {
  return (
    <ThemeProvider theme={misTheme}>
      <Checkbox
        {...props}
        size="small"
        sx={{
          '&.Mui-checked': {
            color: global.FIELD_RADIO_LABEL_SELECTED,
          },
          color: global.FIELD_RADIO_LABEL_UNSELECTED,
          height: '16px',
          marginRight: global.MARGIN_XXS,
          width: '16px',
        }}
      />
    </ThemeProvider>
  )
}

export default MISCheckbox

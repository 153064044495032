/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssociationDTO } from '../models/AssociationDTO'
import type { ClientDTO } from '../models/ClientDTO'
import type { ClientHeaderDTO } from '../models/ClientHeaderDTO'
import type { ClientLightDTO } from '../models/ClientLightDTO'
import type { ClientUpdateDTO } from '../models/ClientUpdateDTO'
import type { HeaderSearchResultDTO } from '../models/HeaderSearchResultDTO'
import type { PageClientDTO } from '../models/PageClientDTO'
import type { UnPagedAssociationDTO } from '../models/UnPagedAssociationDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientControllerService {
  /**
   * @param clientId
   * @param _with
   * @param privacyMasking
   * @returns ClientDTO OK
   * @throws ApiError
   */
  public static getClient(
    clientId: string,
    _with?: Array<string>,
    privacyMasking: boolean = false
  ): CancelablePromise<ClientDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}',
      path: {
        clientId: clientId,
      },
      query: {
        with: _with,
        privacyMasking: privacyMasking,
      },
    })
  }

  /**
   * @param clientId
   * @param requestBody
   * @returns ClientDTO OK
   * @throws ApiError
   */
  public static updateClient(
    clientId: string,
    requestBody: ClientUpdateDTO
  ): CancelablePromise<ClientDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param clientId
   * @param associationId
   * @param requestBody
   * @returns AssociationDTO OK
   * @throws ApiError
   */
  public static updateClientAgencyAssociation(
    clientId: string,
    associationId: string,
    requestBody: AssociationDTO
  ): CancelablePromise<AssociationDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/clients/{clientId}/agencies/{associationId}',
      path: {
        clientId: clientId,
        associationId: associationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns ClientDTO OK
   * @throws ApiError
   */
  public static createClient(requestBody: ClientLightDTO): CancelablePromise<ClientDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/clients',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param clientId
   * @returns UnPagedAssociationDTO OK
   * @throws ApiError
   */
  public static getClientAgencyAssociations(
    clientId: string
  ): CancelablePromise<UnPagedAssociationDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/agencies',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * @param clientId
   * @param requestBody
   * @returns AssociationDTO OK
   * @throws ApiError
   */
  public static addClientAgencyAssociation(
    clientId: string,
    requestBody: AssociationDTO
  ): CancelablePromise<AssociationDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/clients/{clientId}/agencies',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param name
   * @param searchType
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns HeaderSearchResultDTO OK
   * @throws ApiError
   */
  public static getHeaderSearchResult(
    name: string,
    searchType: string = 'ALL',
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<Array<HeaderSearchResultDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/header',
      query: {
        name: name,
        searchType: searchType,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param clientId
   * @param privacyMasking
   * @returns ClientHeaderDTO OK
   * @throws ApiError
   */
  public static getClientHeader(
    clientId: string,
    privacyMasking: boolean = false
  ): CancelablePromise<ClientHeaderDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/{clientId}/header',
      path: {
        clientId: clientId,
      },
      query: {
        privacyMasking: privacyMasking,
      },
    })
  }

  /**
   * @param name
   * @param fileNumber
   * @param identifierNumber
   * @param birthdate
   * @param age
   * @param identifierCodeSystemOid
   * @param identifierCode
   * @param genderCodeSystemOid
   * @param genderCode
   * @param statusCodeSystemOids
   * @param statusCodes
   * @param provinceCodeSystemOids
   * @param provinceCodes
   * @param excludeClients
   * @param privacyMasking
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageClientDTO OK
   * @throws ApiError
   */
  public static searchClient(
    name: string,
    fileNumber: string,
    identifierNumber: string,
    birthdate: string,
    age: number,
    identifierCodeSystemOid?: string,
    identifierCode?: string,
    genderCodeSystemOid?: string,
    genderCode?: string,
    statusCodeSystemOids?: Array<string>,
    statusCodes?: Array<string>,
    provinceCodeSystemOids?: Array<string>,
    provinceCodes?: Array<string>,
    excludeClients?: Array<string>,
    privacyMasking: boolean = false,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageClientDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/search',
      query: {
        name: name,
        fileNumber: fileNumber,
        identifierCodeSystemOid: identifierCodeSystemOid,
        identifierCode: identifierCode,
        identifierNumber: identifierNumber,
        genderCodeSystemOid: genderCodeSystemOid,
        genderCode: genderCode,
        birthdate: birthdate,
        age: age,
        statusCodeSystemOids: statusCodeSystemOids,
        statusCodes: statusCodes,
        provinceCodeSystemOids: provinceCodeSystemOids,
        provinceCodes: provinceCodes,
        excludeClients: excludeClients,
        privacyMasking: privacyMasking,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param clientIds
   * @returns string OK
   * @throws ApiError
   */
  public static searchClientByIds(clientIds: Array<string>): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/clients/search-by-ids',
      query: {
        clientIds: clientIds,
      },
    })
  }
}

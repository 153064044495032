/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagePersonnelAddressDTO } from '../models/PagePersonnelAddressDTO'
import type { PersonnelAddressDTO } from '../models/PersonnelAddressDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PersonnelAddressControllerService {
  /**
   * @param personnelId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PagePersonnelAddressDTO Successfully returned the array of addresses
   * @throws ApiError
   */
  public static getPersonnelAddresses(
    personnelId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PagePersonnelAddressDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/addresses',
      path: {
        personnelId: personnelId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
      errors: {
        403: `User does not have permission to view the addresses`,
      },
    })
  }

  /**
   * @param personnelId
   * @param requestBody
   * @returns PersonnelAddressDTO Successfully saved the addresses
   * @throws ApiError
   */
  public static saveAddresses(
    personnelId: string,
    requestBody: Array<PersonnelAddressDTO>
  ): CancelablePromise<Array<PersonnelAddressDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/addresses',
      path: {
        personnelId: personnelId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `User does not have permission to save the address`,
      },
    })
  }

  /**
   * @param personnelId
   * @param addressId
   * @returns PersonnelAddressDTO Successfully returned the address
   * @throws ApiError
   */
  public static getAddress(
    personnelId: string,
    addressId: string
  ): CancelablePromise<PersonnelAddressDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/addresses/{addressId}',
      path: {
        personnelId: personnelId,
        addressId: addressId,
      },
      errors: {
        403: `User does not have permission to view the address`,
      },
    })
  }

  /**
   * @param personnelId
   * @param addressId
   * @returns void
   * @throws ApiError
   */
  public static deleteAddress(personnelId: string, addressId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/addresses/{addressId}',
      path: {
        personnelId: personnelId,
        addressId: addressId,
      },
      errors: {
        403: `User does not have permission to delete the address`,
      },
    })
  }

  /**
   * @param addressText
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PagePersonnelAddressDTO Successfully returned the array of addresses
   * @throws ApiError
   */
  public static searchAllAddresses(
    addressText: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PagePersonnelAddressDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/addresses',
      query: {
        addressText: addressText,
        page: page,
        size: size,
        sort: sort,
      },
      errors: {
        403: `User does not have permission to search for addresses`,
      },
    })
  }

  /**
   * Get all communities that have been recorded in a provider address
   * @returns string Successfully returned the array of communities
   * @throws ApiError
   */
  public static getAllCommunities(): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/address/communities',
      errors: {
        403: `User does not have permission to delete the address`,
      },
    })
  }
}

import {
  Dispatch,
  ElementType,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import TuneIcon from '@mui/icons-material/Tune'
import { Box, InputBaseComponentProps, Stack } from '@mui/material'
import { useRecoilValue } from 'recoil'
import MISDateTimePicker from 'common/components/form/MISDateTimePicker'
import MISDrawer from 'common/components/form/MISDrawer'
import MISMultiValueAutocomplete, {
  MultiValueOption,
} from 'common/components/form/MISMultiValueAutocomplete'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { getPersonnelFullName } from 'modules/shared/clientUtils'
import useProviders from 'modules/shared/hooks/useProviders'
import { programsAtom } from 'recoil/atoms'
import { CodedConceptDto } from 'services/openapi'
import { FOLLOW_UP_URGENCY, MIS_LENGTH_UNITS } from 'services/terminologyConstants'
import { selectTerminology } from 'store/selectors/terminology'
import { ETemplateMetadataType, ITemplateMetadata } from '../blots/TemplateBlot'
import { HistoricalFBComponents } from '../templates/form-builder-template/FormBuilderTemplate'

type HistoricalItemFilterProps = {
  components: ITemplateMetadata | undefined
  componentsFB: HistoricalFBComponents | undefined
  handleFiltering: Dispatch<SetStateAction<string[]>>
}

const YesNoEmptyOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]

const HistoricalItemFilter = ({
  components,
  componentsFB,
  handleFiltering,
}: HistoricalItemFilterProps) => {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState(false)
  const [filters, setFilters] = useState<Record<string, string>>({})
  const providers = useProviders()
  const programs = useRecoilValue(programsAtom)
  const terminology = useSelector(selectTerminology)

  const followUpUrgency = useMemo(
    () => terminology.find((term) => term.setName === FOLLOW_UP_URGENCY)?.value || [],
    [terminology]
  )

  const lengthUnits = useMemo(
    () =>
      terminology
        .find((term) => term.setName === MIS_LENGTH_UNITS)
        ?.value.filter((each) => each.name === 'cm' || each.name === 'in') || [],
    [terminology]
  )

  const providerOptions = useMemo(
    () =>
      providers
        ?.map((provider) => getPersonnelFullName(provider))
        .filter((name): name is string => name !== undefined) || [],
    [providers]
  )

  const updateFilter = useCallback(
    (key: string, value: any) => {
      setFilters({ ...filters, [key]: value })
    },
    [filters]
  )

  const getComponent = useCallback(
    (component: ITemplateMetadata | HistoricalFBComponents) => {
      const componentArray: ReactNode[] = []
      for (const key in component) {
        const componentValue = component[key]
        const keyValue = key + '|' + componentValue.type
        if (
          componentValue.type === ETemplateMetadataType.Date ||
          componentValue.type === ETemplateMetadataType.DateTime
        ) {
          componentArray.push(
            <>
              <MISDateTimePicker
                key={keyValue + 'from'}
                label={componentValue.label + ' From'}
                onChange={(value: unknown) => updateFilter(keyValue + '|from', value)}
                value={filters[keyValue + '|from'] || null}
              />
              <MISDateTimePicker
                key={keyValue + 'to'}
                label={componentValue.label + ' To'}
                onChange={(value: unknown) => updateFilter(keyValue + '|to', value)}
                value={filters[keyValue + '|to'] || null}
              />
            </>
          )
        } else if (componentValue.type === ETemplateMetadataType.string) {
          componentArray.push(
            <MISTextField
              key={keyValue}
              label={componentValue.label}
              onChange={(event) => updateFilter(keyValue, event.target.value)}
              value={filters[keyValue] || null}
            />
          )
        } else if (componentValue.type === ETemplateMetadataType.number) {
          componentArray.push(
            <>
              <MISTextField
                InputProps={{
                  inputComponent: NumericFormat as ElementType<InputBaseComponentProps>,
                  inputProps: {
                    allowLeadingZeros: false,
                    allowNegative: false,
                    decimalScale: 2,
                    thousandSeparator: true,
                  },
                }}
                key={keyValue + 'from'}
                label={componentValue.label + ' From'}
                onChange={(event) => updateFilter(keyValue + '|from', event.target.value)}
                value={filters[keyValue + '|from'] || null}
              />
              <MISTextField
                InputProps={{
                  inputComponent: NumericFormat as ElementType<InputBaseComponentProps>,
                  inputProps: {
                    allowLeadingZeros: false,
                    allowNegative: false,
                    decimalScale: 2,
                    thousandSeparator: true,
                  },
                }}
                key={keyValue + 'to'}
                label={componentValue.label + ' To'}
                onChange={(event) => updateFilter(keyValue + '|to', event.target.value)}
                value={filters[keyValue + '|to'] || null}
              />
            </>
          )
        } else if (componentValue.type === ETemplateMetadataType.CodedConceptDto) {
          let codedConcepts: CodedConceptDto[] = []
          if (componentValue.label === 'Priority') {
            codedConcepts = followUpUrgency
          } else if (componentValue.label === 'Height' || componentValue.label === 'Weight') {
            codedConcepts = lengthUnits
          }
          componentArray.push(
            <MISMultiValueAutocomplete
              label={componentValue.label}
              onChange={(event: SyntheticEvent, newValue: MultiValueOption[]) => {
                updateFilter(keyValue, newValue.map((v) => v.value).toString())
              }}
              options={
                codedConcepts
                  ? codedConcepts
                      .map((concept) => concept.name)
                      .filter((name): name is string => name !== undefined)
                      .map((v) => ({ label: v, value: v })) || []
                  : []
              }
              value={
                filters[keyValue]
                  ? filters[keyValue].split(' ,').map((v) => ({ label: v, value: v }))
                  : []
              }
            />
          )
        } else if (
          componentValue.type === ETemplateMetadataType.ProviderId ||
          componentValue.type === ETemplateMetadataType.PersonnelDTO
        ) {
          componentArray.push(
            <MISMultiValueAutocomplete
              allowFreeText={false}
              label={componentValue.label}
              onChange={(event: SyntheticEvent, newValue: MultiValueOption[]) => {
                updateFilter(keyValue, newValue.map((v) => v.value).toString())
              }}
              options={providerOptions.map((v) => ({ label: v, value: v }))}
              value={
                filters[keyValue]
                  ? filters[keyValue].split(' ,').map((v) => ({ label: v, value: v }))
                  : []
              }
            />
          )
        } else if (componentValue.type === ETemplateMetadataType.ProgramTerse) {
          componentArray.push(
            <MISMultiValueAutocomplete
              label={componentValue.label}
              onChange={(event: SyntheticEvent, newValue: MultiValueOption[]) => {
                updateFilter(keyValue, newValue.map((v) => v.value).toString())
              }}
              options={
                programs
                  .map((program) => program.name)
                  .filter((name): name is string => name !== undefined)
                  .map((v) => ({ label: v, value: v })) || []
              }
              value={
                filters[keyValue]
                  ? filters[keyValue].split(' ,').map((v) => ({ label: v, value: v }))
                  : []
              }
            />
          )
        } else if (componentValue.type === ETemplateMetadataType.boolean) {
          componentArray.push(
            <MISSelectDropdown
              label={componentValue.label}
              onChange={(e) => updateFilter(keyValue, e.target.value)}
              options={YesNoEmptyOptions}
              value={YesNoEmptyOptions?.find((each) => each.value === filters[keyValue])?.value}
            />
          )
        }
      }
      return componentArray
    },
    [filters, followUpUrgency, lengthUnits, programs, providerOptions, updateFilter]
  )

  const renderFormComponent = () => {
    return (
      <Stack
        spacing={2}
        sx={{
          '& .MuiFormControl-root': {
            width: '100%',
          },
          m: 4,
          overflowY: 'auto',
          pb: 4,
          px: 1,
          width: 400,
        }}
      >
        {(components && getComponent(components)) || (componentsFB && getComponent(componentsFB))}
        <MISButton
          color="primary"
          onClick={handleSearch}
          size="large"
          sx={{
            alignItems: 'center',
            bottom: 16,
            position: 'absolute',
            width: 380,
          }}
        >
          {t('charting.filter.apply')}
        </MISButton>
      </Stack>
    )
  }

  const handleSearch = useCallback(() => {
    if (isOpen) {
      setIsOpen(false)
      const filterArray: string[] = []
      for (const key in filters) {
        if (filters[key]) {
          console.log(key, filters[key])
          const split = key.split('|')
          if (split.indexOf(ETemplateMetadataType.string) !== -1) {
            if (componentsFB)
              filterArray.push(
                'data.name|eq|' + split[0] + '|string,data.value|like|' + filters[key] + '|string'
              )
            else filterArray.push(split[0] + '|like|' + filters[key] + '|string')
          } else if (split.indexOf(ETemplateMetadataType.boolean) !== -1) {
            if (componentsFB) {
              if (filters[key] === 'true')
                filterArray.push(
                  'data.name|eq|' +
                    split[0] +
                    '|string,data.checked|eq|' +
                    filters[key] +
                    '|boolean'
                )
              else
                filterArray.push(
                  'data.name|like|' + split[0] + '|string,data.checked|notexist|true'
                )
            } else filterArray.push(split[0] + '|eq|' + filters[key] + '|boolean')
          } else if (split.indexOf(ETemplateMetadataType.number) !== -1) {
            if (split.indexOf('from') !== -1) {
              if (componentsFB)
                filterArray.push(
                  'data.name|eq|' + split[0] + '|string,data.value|gte|' + filters[key] + '|number'
                )
              else filterArray.push(split[0] + '|gte|' + filters[key] + '|number')
            } else {
              if (componentsFB)
                filterArray.push(
                  'data.name|eq|' + split[0] + '|string,data.value|lte|' + filters[key] + '|number'
                )
              else filterArray.push(split[0] + '|lte|' + filters[key] + '|number')
            }
          } else if (
            split.indexOf(ETemplateMetadataType.Date) !== -1 ||
            split.indexOf(ETemplateMetadataType.DateTime) !== -1
          ) {
            if (split.indexOf('from') !== -1) {
              if (componentsFB)
                filterArray.push(
                  'data.name|eq|' + split[0] + '|string,data.value|gte|' + filters[key] + '|date'
                )
              else filterArray.push(split[0] + '|gte|' + filters[key] + '|date')
            } else {
              if (componentsFB)
                filterArray.push(
                  'data.name|eq|' + split[0] + '|string,data.value|lte|' + filters[key] + '|date'
                )
              else filterArray.push(split[0] + '|lte|' + filters[key] + '|date')
            }
          } else if (split.indexOf(ETemplateMetadataType.CodedConceptDto) !== -1) {
            filterArray.push(split[0] + '.name|like|' + filters[key] + '|string')
          } else if (split.indexOf(ETemplateMetadataType.ProgramTerse) !== -1) {
            const splitter = filters[key].split(',')
            splitter.forEach((each) => {
              filterArray.push(split[0] + '.name|like|' + each + '|string')
            })
          } else if (split.indexOf(ETemplateMetadataType.PersonnelDTO) !== -1) {
            const prov = providers?.find((each) => getPersonnelFullName(each) === filters[key])
            if (prov) {
              filterArray.push(split[0] + '.id|like|' + prov.id + '|string')
            }
          } else if (split.indexOf(ETemplateMetadataType.ProviderId) !== -1) {
            const prov = providers?.find((each) => getPersonnelFullName(each) === filters[key])
            if (prov) {
              filterArray.push(split[0] + '|like|' + prov.id + '|string')
            }
          }
        }
      }
      handleFiltering(filterArray)
    }
  }, [componentsFB, filters, handleFiltering, isOpen, providers])

  return (
    <>
      <Box
        sx={{
          '& .MuiButton-root': {
            ml: 3,
          },
        }}
      >
        <MISButton
          onClick={() => setIsOpen(true)}
          size="large"
          startIcon={<TuneIcon />}
          sx={{ color: 'black', px: 0.5 }}
          variant="text"
        >
          {t('charting.filter.filter')}
        </MISButton>
      </Box>

      <MISDrawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {renderFormComponent()}
      </MISDrawer>
    </>
  )
}

export default HistoricalItemFilter

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ClientSearchResultDTO } from './ClientSearchResultDTO'

export type GroupSearchResultDTO = {
  id?: string
  type?: GroupSearchResultDTO.type
  name?: string
  description?: string
  groupSearchResultDTO?: GroupSearchResultDTO
  clientSearchResultDTO?: ClientSearchResultDTO
}

export namespace GroupSearchResultDTO {
  export enum type {
    GROUP = 'GROUP',
    CLIENT = 'CLIENT',
  }
}

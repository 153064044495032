import { Component } from 'react'
import { CodedConceptDto } from 'services/openapi'
import HeightWeightTemplateComponent from './HeightWeightTemplateComponent'
import { ETemplateMetadataType, ITemplate, ITemplateMetadata } from '../../blots/TemplateBlot'

export type THeightWeightTemplateValue = boolean | number | string | CodedConceptDto | undefined

export type HeightWeightTemplateState = {
  bmi?: number
  createdBy?: string
  createdOn?: string
  height?: number
  heightUnit?: CodedConceptDto
  underTwo?: boolean
  weight?: number
  weightUnit?: CodedConceptDto
}

export type HeightWeigthTemplateProps = { data: HeightWeightTemplateState }

class HeightWeightTemplate
  extends Component<HeightWeigthTemplateProps, HeightWeightTemplateState>
  implements ITemplate
{
  type = 'HeightWeightTemplate'
  state: HeightWeightTemplateState

  constructor(props: HeightWeigthTemplateProps) {
    super(props)
    this.state = { ...props.data }
  }

  static getMetadata = (): ITemplateMetadata => ({
    bmi: { label: 'BMI', type: ETemplateMetadataType.number },
    createdBy: { label: 'Created by', type: ETemplateMetadataType.ProviderId },
    createdOn: { label: 'Created on', type: ETemplateMetadataType.DateTime },
    height: { label: 'Height', type: ETemplateMetadataType.number },
    heightUnit: { label: 'Height unit', type: ETemplateMetadataType.CodedConceptDto },
    underTwo: { label: 'Under Two', type: ETemplateMetadataType.boolean },
    weight: { label: 'Weight', type: ETemplateMetadataType.number },
    weightUnit: { label: 'Weight unit', type: ETemplateMetadataType.CodedConceptDto },
  })

  static getTemplateDisplayName = (): string => 'Height Weight Template'

  static getTemplateVersion = (): number => 1

  getData = () => ({
    bmi: this.state.bmi,
    createdBy: this.state.createdBy,
    createdOn: this.state.createdOn,
    height: this.state.height,
    heightUnit: this.state.heightUnit,
    underTwo: this.state.underTwo,
    weight: this.state.weight,
    weightUnit: this.state.weightUnit,
  })

  getEmptyData = () => ({
    bmi: undefined,
    createdBy: undefined,
    createdOn: undefined,
    height: undefined,
    heightUnit: undefined,
    underTwo: undefined,
    weight: undefined,
    weightUnit: undefined,
  })

  save = async () => {
    console.log('SAVEDDDD', this.type)
  }

  cancel = async () => {
    console.log('Cancel called', this.type)
  }

  handleChange = (key: string, value: THeightWeightTemplateValue) =>
    this.setState({ ...this.state, [key]: value })

  render() {
    return <HeightWeightTemplateComponent {...this.state} onChange={this.handleChange} />
  }
}

export default HeightWeightTemplate

import 'core/styles/Styles.scss'
import './RouterApp.scss'

import { lazy, Suspense, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { AuthContextProps, useAuth } from 'react-oidc-context'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { User } from 'oidc-client-ts'
import GenericError from 'common/components/GenericError'
import Loader from 'common/components/Loader'
import MISSnackbar from 'common/components/snackbar/MISSnackbar'
import { useSnack } from 'common/components/snackbar/useSnack'
import TopBanner from 'common/components/TopBanner'
import TopNavigation from 'common/components/topnavigation/TopNavigation'
import useIsMounted from 'common/hooks/useIsMounted'
import AppNavigation from 'core/components/AppNavigation'
import DomainSelection from 'core/components/DomainSelection'
import { ErrorHandlerProvider } from 'core/components/errorhandler/ErrorHandlerContext'
import Drawer from 'core/components/navigation/Drawer'
import { ProgressProvider } from 'core/components/progress/ProgressContext'
import AcceptableUseAcknowledgement from 'core/components/signin/AcceptableUseAcknowledgement'
import { SignIn } from 'core/components/signin/SignIn'
import { ROUTE_KEYS } from 'core/constants'
import { updateAbility } from 'core/helpers/security/ability'
import AuthService from 'core/helpers/security/AuthService'
import { AbilityContext } from 'core/helpers/security/can'
import useStartupService from 'core/routing/useStartupService'
import Alerts from 'modules/alerts/Alerts'
import Charting from 'modules/charting/Charting'
import TemplateEditor from 'modules/charting/components/template-management/TemplateEditor'
import TemplateManagement from 'modules/charting/components/template-management/TemplateManagement'
import AddEditClient from 'modules/client/AddEditClient/AddEditClient'
import ClientDashboard from 'modules/client/ClientDashboard/ClientDashboard'
import ClientDetails from 'modules/client/ClientDetails/ClientRecordDetails'
import ClientImmunizations from 'modules/client/Immunizations/Immunizations'
import SearchClient from 'modules/client/SearchClient/SearchClient'
import ToDo from 'modules/client/ToDo/ToDo'
import AddEditEncounterServiceTemplate from 'modules/encounter-service-templates/AddEditEncounterServiceTemplate'
import EncounterServiceTemplateGroups from 'modules/encounter-service-templates/EncounterServiceTemplateGroups'
import SearchEncounterServiceTemplates from 'modules/encounter-service-templates/SearchEncounterServiceTemplates'
import Encounters from 'modules/encounters/Encounters'
import Forms from 'modules/forms/Forms'
import FormsViewer from 'modules/forms/FormsViewer'
import GovernanceAgencyList from 'modules/governance-agency/components/GovernanceAgencyList/GovernanceAgencyList'
import GovAgencyDetails from 'modules/governance-agency/GovernanceAgencyDetails/GovAgencyDetails'
import AddEditGroup from 'modules/groups/AddEditGroup/AddEditGroup'
import GroupRecordDetails from 'modules/groups/GroupDetail/GroupRecordDetail'
import SearchGroup from 'modules/groups/SearchGroups/SearchGroup'
import HealthConcerns from 'modules/health-concerns/HealthConcerns'
import BusinessLines from 'modules/program/BusinessLines'
import ChartingServices from 'modules/program/ChartingServices'
import Programs from 'modules/program/Programs'
import PurposeGroups from 'modules/program/PurposeGroups'
import Purposes from 'modules/program/Purposes'
import TypeGroups from 'modules/program/TypeGroups'
import Types from 'modules/program/Types'
import AddEditProviderStaff from 'modules/provider-staff/AddEditProviderStaff/AddEditProviderStaff'
import ProviderStaffDetails from 'modules/provider-staff/ProviderStaffDetails/ProviderStaffDetails'
import SearchProviderStaff from 'modules/provider-staff/SearchProviderStaff/SearchProviderStaff'
import RunningNotes from 'modules/running-notes/RunningNotes'
import Teams from 'modules/teams/Teams'
import AgreementsAcceptableUse from 'modules/user/components/agreements/AgreementsAcceptableUse'
import AgreementsConfidential from 'modules/user/components/agreements/AgreementsConfidential'
import UserPreferences from 'modules/user/components/preferences/UserPreferences'
import UserProvisioning from 'modules/user/components/provisioning/UserProvisioning'
import UserDashboard from 'modules/user/dashboard/UserDashboard'
import UserToDo from 'modules/user/to-do/UserToDo'
import { setApiToken } from 'services/ApiService'
import { AcceptableUseAgreementControllerService } from 'services/openapi'
import { selectAppInitializationState } from 'store/selectors/app'
import { BreadbrumbType, getAdditionalBreadcrumbs } from './breadcrumb'
import IdleTimerContainer from './components/IdleTimerContainer'
import ConfidentialityModal from './ConfidentialityModal'
import { NotificationProvider } from './NotificationProvider'
import TEMP_PERMISSIONS from '../helpers/security/permissions'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

type SecuredAppProps = {
  auth: AuthContextProps
  username?: string
}

const BoldReportDesigner = lazy(() => import('../../modules/reports/ReportBuilder'))
const BoldReportViewer = lazy(() => import('../../modules/reports/ReportViewer'))

const SecuredApp = ({ auth, username }: SecuredAppProps) => {
  const { activeNavigator, error, isAuthenticated, isLoading, signinRedirect, user } = auth
  const appInitialized = useSelector(selectAppInitializationState)

  const [breadcrumb, setBreadcrumb] = useState<BreadbrumbType[]>([])
  const [authSet, setAuthSet] = useState(false)
  const [showAcceptableUseAcknowledgemnt, setShowAcceptableUseAcknowledgement] = useState(false)
  const { showSnackError } = useSnack()

  useStartupService(auth)
  const ability = useContext(AbilityContext)
  const isMounted = useIsMounted()
  updateAbility(ability, TEMP_PERMISSIONS) // TODO: use permissions API to fetch permissions

  window.onpageshow = function (event) {
    if (event.persisted) {
      window.location.reload()
    }
  }

  const handleBreadcrumb = useCallback(
    (data) => {
      const key =
        breadcrumb.length > 0 ? breadcrumb.filter((item) => item.key === data.key) : breadcrumb

      if (key.length === 0) {
        setBreadcrumb([...breadcrumb, data])
      }
    },
    [breadcrumb]
  )

  useEffect(() => {
    if (isMounted()) {
      if (!isAuthenticated && !activeNavigator && !isLoading) {
        if (username) {
          signinRedirect({
            login_hint: username,
          })
        } else {
          localStorage.removeItem('login_initiated')
          window.location.reload()
        }
      } else if (user) {
        setAuthSet(true)
      }
    }
    if (auth.user) {
      setShowAcceptableUseAcknowledgement(true)
    }
  }, [
    activeNavigator,
    isAuthenticated,
    isLoading,
    signinRedirect,
    user,
    isMounted,
    username,
    auth.user,
    handleBreadcrumb,
  ])

  const handleLogout = useCallback(async () => {
    await auth.removeUser()
    await auth.revokeTokens()
    await auth.signoutRedirect()
  }, [auth])

  const handleAcceptableUseAcknowledgement = useCallback(
    async (agreementId: string, hasAccepted: boolean) => {
      if (agreementId) {
        await AcceptableUseAgreementControllerService.confirmAcceptableUseAgreement(
          agreementId,
          hasAccepted
        ).catch((error) => {
          showSnackError(error.message)
        })
      }
      // automatically log the user out of the application if the user does not accept the
      // acceptable use acknowledgement
      if (agreementId && !hasAccepted) {
        handleLogout()
      } else if (hasAccepted || !agreementId) {
        setShowAcceptableUseAcknowledgement(false)
      }
    },
    [handleLogout, showSnackError]
  )

  const getRouteComponent = useCallback(
    (key: string) => {
      const additionalBreadcrumb = getAdditionalBreadcrumbs(key)
      switch (key) {
        case ROUTE_KEYS.SIGN_IN:
          return <Route element={<SignIn auth={auth} />} path="sign-in" />
        case ROUTE_KEYS.DOMAIN_SELECTION:
          return (
            <Route
              element={<DomainSelection onBreadcrumb={handleBreadcrumb} />}
              path="domain-selection"
            />
          )
        case ROUTE_KEYS.AGREEMENTS_CONFIDENTIAL:
          return (
            <Route
              element={
                <AgreementsConfidential breadcrumb={[...breadcrumb, ...additionalBreadcrumb]} />
              }
              path="admin/agreements/confidential"
            />
          )
        case ROUTE_KEYS.AGREEMENTS_ACCEPTABLE_USE:
          return (
            <Route
              element={
                <AgreementsAcceptableUse breadcrumb={[...breadcrumb, ...additionalBreadcrumb]} />
              }
              path="admin/agreements/acceptable-use"
            />
          )
        case ROUTE_KEYS.CLIENTS:
          return <Route element={<SearchClient onAddClient={undefined} />} path="/clients" />
        case ROUTE_KEYS.CLIENT_CREATE:
          return <Route element={<AddEditClient />} path="clients/create" />
        case ROUTE_KEYS.CLIENT_DASHBOARD:
          return (
            <Route element={<ClientDashboard />} path="clients/client-record/:clientId/dashboard" />
          )
        case ROUTE_KEYS.CLIENT_PROFILE:
          return <Route element={<ClientDetails />} path="clients/client-record/:clientId" />
        case ROUTE_KEYS.CLIENT_CHARTING:
          return <Route element={<Charting />} path="clients/client-record/:clientId/charting" />
        case ROUTE_KEYS.CLIENT_CHARTING_IN_PROGRESS:
          return <Route element={<Charting />} path="clients/client-record/:clientId/in-progress" />
        case ROUTE_KEYS.CLIENT_IMMUNIZATIONS:
          return (
            <Route
              element={<ClientImmunizations />}
              path="clients/client-record/:clientId/immunizations"
            />
          )
        case ROUTE_KEYS.CLIENT_ENCOUNTERS:
          return (
            <Route element={<Encounters />} path="clients/client-record/:clientId/encounters" />
          )
        case ROUTE_KEYS.CLIENT_RUNNING_NOTES:
          return (
            <Route
              element={<RunningNotes />}
              path="clients/client-record/:clientId/running-notes"
            />
          )
        case ROUTE_KEYS.CLIENT_HEALTH_CONCERNS:
          return (
            <Route
              element={<HealthConcerns />}
              path="clients/client-record/:clientId/health-concerns"
            />
          )
        case ROUTE_KEYS.CLIENT_TO_DO:
          return <Route element={<ToDo />} path="clients/client-record/:clientId/to-do" />
        case ROUTE_KEYS.ENCOUNTER_SERVICE_TEMPLATES:
          return (
            <Route
              element={
                <SearchEncounterServiceTemplates
                  breadcrumb={[...breadcrumb, ...additionalBreadcrumb]}
                />
              }
              path="encounter/service-templates"
            />
          )
        case ROUTE_KEYS.ENCOUNTER_SERVICE_TEMPLATE_CREATE:
          return (
            <Route
              element={
                <AddEditEncounterServiceTemplate
                  breadcrumb={[...breadcrumb, ...additionalBreadcrumb]}
                />
              }
              path="encounter/service-templates/create"
            />
          )
        case ROUTE_KEYS.ENCOUNTER_SERVICE_TEMPLATE_EDIT:
          return (
            <Route
              element={
                <AddEditEncounterServiceTemplate
                  breadcrumb={[...breadcrumb, ...additionalBreadcrumb]}
                />
              }
              path="encounter/service-templates/edit/:selectedTemplateId"
            />
          )
        case ROUTE_KEYS.ENCOUNTER_SERVICE_TEMPLATE_GROUPS:
          return (
            <Route
              element={<EncounterServiceTemplateGroups />}
              path="encounter/service-template-groups"
            />
          )
        case ROUTE_KEYS.ALERTS:
          return <Route element={<Alerts />} path="admin/alerts" />
        case ROUTE_KEYS.TEAMS:
          return <Route element={<Teams />} path="admin/teams" />
        case ROUTE_KEYS.GOVERNANCE_AGENCIES:
          return (
            <Route
              element={
                <GovernanceAgencyList breadcrumb={[...breadcrumb, ...additionalBreadcrumb]} />
              }
              path="admin/governance-agencies"
            />
          )
        case ROUTE_KEYS.GOVERNANCE_AGENCY_CREATE:
          return <Route element={<GovAgencyDetails />} path="admin/governance-agencies/create" />
        case ROUTE_KEYS.GOVERNANCE_AGENCY_EDIT:
          return (
            <Route element={<GovAgencyDetails />} path="admin/governance-agencies/edit/:agencyId" />
          )
        case ROUTE_KEYS.GOVERNANCE_AGENCY_DEPARTMENT_CREATE:
          return (
            <Route
              element={<GovAgencyDetails isDepartment />}
              path="admin/governance-agencies/:agencyId/department/create"
            />
          )
        case ROUTE_KEYS.GOVERNANCE_AGENCY_DEPARTMENT_EDIT:
          return (
            <Route
              element={<GovAgencyDetails isDepartment />}
              path="admin/governance-agencies/:agencyId/department/:departmentId"
            />
          )
        case ROUTE_KEYS.TYPES:
          return <Route element={<Types />} path="admin/program/types" />
        case ROUTE_KEYS.TYPE_GROUPS:
          return <Route element={<TypeGroups />} path="admin/program/type-groups" />
        case ROUTE_KEYS.PURPOSES:
          return <Route element={<Purposes />} path="admin/program/purposes" />
        case ROUTE_KEYS.PURPOSE_GROUPS:
          return <Route element={<PurposeGroups />} path="admin/program/purpose-groups" />
        case ROUTE_KEYS.PROGRAMS:
          return <Route element={<Programs />} path="admin/program/programs" />
        case ROUTE_KEYS.PROGRAM_GROUPS:
          return <Route element={<BusinessLines />} path="admin/program/business-lines" />
        case ROUTE_KEYS.CHARTING_SERVICES:
          return <Route element={<ChartingServices />} path="admin/program/charting-services" />
        case ROUTE_KEYS.CHARTING:
          return <Route element={<Charting />} path="charting" />
        case ROUTE_KEYS.FORMS:
          return <Route element={<Forms />} path="admin/forms" />
        case ROUTE_KEYS.FORMS_VIEWER:
          return <Route element={<FormsViewer />} path="admin/formsViewer" />
        case ROUTE_KEYS.USER_PREFERENCES:
          return <Route element={<UserPreferences auth={auth} />} path="user/preferences" />
        case ROUTE_KEYS.USER_PROVISIONING:
          return <Route element={<UserProvisioning />} path="admin/user-provisioning" />
        case ROUTE_KEYS.PROVIDER_STAFF_SEARCH:
          return <Route element={<SearchProviderStaff />} path="provider-staff-record/search" />
        case ROUTE_KEYS.GROUP_SEARCH:
          return <Route element={<SearchGroup />} path="/groups/search" />
        case ROUTE_KEYS.GROUP_DETAIL:
          return <Route element={<GroupRecordDetails />} path="groups/group-record/:groupId" />
        case ROUTE_KEYS.GROUP_CREATE:
          return <Route element={<AddEditGroup />} path="group/create" />
        case ROUTE_KEYS.GROUP_CHARTING:
          return <Route element={<Charting />} path="groups/group-record/:groupId/charting" />
        case ROUTE_KEYS.GROUP_CHARTING_IN_PROGRESS:
          return (
            <Route
              element={<Charting />}
              path="groups/group-record/:groupId/charting/in-progress"
            />
          )
        case ROUTE_KEYS.REPORTS:
          return <Route element={<BoldReportDesigner />} path="bold-reports" />
        case ROUTE_KEYS.REPORTS_VIEWER:
          return <Route element={<BoldReportViewer />} path="bold-reports-viewer" />
        case ROUTE_KEYS.PROVIDER_STAFF_PROFILE:
          return (
            <Route
              element={<ProviderStaffDetails />}
              path="provider-staff-record/:providerStaffId"
            />
          )
        case ROUTE_KEYS.PROVIDER_STAFF_CREATE:
          return <Route element={<AddEditProviderStaff />} path="provider-staff/create" />
        case ROUTE_KEYS.TEMPLATE_EDITOR:
          return <Route element={<TemplateEditor />} path="admin/template-editor" />
        case ROUTE_KEYS.TEMPLATE_MANAGEMENT:
          return <Route element={<TemplateManagement />} path="admin/template-management" />
        case ROUTE_KEYS.USER_DASHBOARD:
          return <Route element={<UserDashboard />} path="admin/dashboard" />
        case ROUTE_KEYS.USER_TO_DO:
          return <Route element={<UserToDo />} path="admin/to-do" />
        case ROUTE_KEYS.SILENT_LOGOUT:
          return <Route path="silent-logout" />
        case ROUTE_KEYS.DEFAULT:
          return <Route element={<UserDashboard />} path="" />
      }
    },
    [auth, breadcrumb, handleBreadcrumb]
  )

  if (auth.isLoading || !appInitialized || !authSet) return <Loader fullScreen />

  if (error) return <GenericError errorMessage={error.message} />

  return (
    <Suspense fallback={<Loader fullScreen />}>
      <QueryClientProvider client={queryClient}>
        <NotificationProvider user={auth?.user}>
          <ErrorHandlerProvider>
            <ProgressProvider>
              <BrowserRouter>
                <div className="router-app">
                  <div className="left-pane">
                    <Drawer />
                  </div>
                  <div className="right-pane">
                    <TopBanner />
                    <TopNavigation auth={auth} user={user} />
                    <Routes>
                      <Route element={<AppNavigation />} path="/">
                        {getRouteComponent(ROUTE_KEYS.SIGN_IN)}
                        {ability.can('read', 'CLIENT') && (
                          <>{getRouteComponent(ROUTE_KEYS.CLIENT_DASHBOARD)}</>
                        )}
                        {ability.can('create', 'CLIENT') && (
                          <>
                            {getRouteComponent(ROUTE_KEYS.CLIENTS)}
                            {getRouteComponent(ROUTE_KEYS.CLIENT_CREATE)}
                            {getRouteComponent(ROUTE_KEYS.CLIENT_RECORD)}
                            {getRouteComponent(ROUTE_KEYS.CLIENT_PROFILE)}
                            {getRouteComponent(ROUTE_KEYS.CLIENT_CHARTING)}
                            {getRouteComponent(ROUTE_KEYS.CLIENT_CHARTING_IN_PROGRESS)}
                            {getRouteComponent(ROUTE_KEYS.CLIENT_IMMUNIZATIONS)}
                            {getRouteComponent(ROUTE_KEYS.CLIENT_HEALTH_CONCERNS)}
                            {getRouteComponent(ROUTE_KEYS.CLIENT_TO_DO)}
                            {getRouteComponent(ROUTE_KEYS.CLIENT_RUNNING_NOTES)}
                          </>
                        )}
                        {ability.can('create', 'ENCOUNTER') && (
                          <>
                            {getRouteComponent(ROUTE_KEYS.CLIENT_ENCOUNTERS)}
                            {getRouteComponent(ROUTE_KEYS.ENCOUNTER_SERVICE_TEMPLATES)}
                            {getRouteComponent(ROUTE_KEYS.ENCOUNTER_SERVICE_TEMPLATE_CREATE)}
                            {getRouteComponent(ROUTE_KEYS.ENCOUNTER_SERVICE_TEMPLATE_EDIT)}
                            {getRouteComponent(ROUTE_KEYS.ENCOUNTER_SERVICE_TEMPLATE_GROUPS)}
                          </>
                        )}
                        {isAuthenticated === true && (
                          <>
                            {getRouteComponent(ROUTE_KEYS.PROVIDER_STAFF_SEARCH)}
                            {getRouteComponent(ROUTE_KEYS.PROVIDER_STAFF_PROFILE)}
                            {getRouteComponent(ROUTE_KEYS.PROVIDER_STAFF_CREATE)}
                            {getRouteComponent(ROUTE_KEYS.DOMAIN_SELECTION)}
                            {getRouteComponent(ROUTE_KEYS.AGREEMENTS_CONFIDENTIAL)}
                            {getRouteComponent(ROUTE_KEYS.AGREEMENTS_ACCEPTABLE_USE)}
                            {getRouteComponent(ROUTE_KEYS.ALERTS)}
                            {getRouteComponent(ROUTE_KEYS.GROUP_SEARCH)}
                            {getRouteComponent(ROUTE_KEYS.GROUP_DETAIL)}
                            {getRouteComponent(ROUTE_KEYS.GROUP_CREATE)}
                            {getRouteComponent(ROUTE_KEYS.TEAMS)}
                            {getRouteComponent(ROUTE_KEYS.GOVERNANCE_AGENCIES)}
                            {getRouteComponent(ROUTE_KEYS.GOVERNANCE_AGENCY_CREATE)}
                            {getRouteComponent(ROUTE_KEYS.GOVERNANCE_AGENCY_EDIT)}
                            {getRouteComponent(ROUTE_KEYS.GOVERNANCE_AGENCY_DEPARTMENT_CREATE)}
                            {getRouteComponent(ROUTE_KEYS.GOVERNANCE_AGENCY_DEPARTMENT_EDIT)}
                            {getRouteComponent(ROUTE_KEYS.TYPES)}
                            {getRouteComponent(ROUTE_KEYS.TYPE_GROUPS)}
                            {getRouteComponent(ROUTE_KEYS.PURPOSES)}
                            {getRouteComponent(ROUTE_KEYS.PURPOSE_GROUPS)}
                            {getRouteComponent(ROUTE_KEYS.PROGRAMS)}
                            {getRouteComponent(ROUTE_KEYS.PROGRAM_GROUPS)}
                            {getRouteComponent(ROUTE_KEYS.CHARTING_SERVICES)}
                            {getRouteComponent(ROUTE_KEYS.CHARTING)}
                            {getRouteComponent(ROUTE_KEYS.GROUP_CHARTING)}
                            {getRouteComponent(ROUTE_KEYS.GROUP_CHARTING_IN_PROGRESS)}
                            {getRouteComponent(ROUTE_KEYS.FORMS)}
                            {getRouteComponent(ROUTE_KEYS.REPORTS)}
                            {getRouteComponent(ROUTE_KEYS.REPORTS_VIEWER)}
                            {getRouteComponent(ROUTE_KEYS.USER_PREFERENCES)}
                            {getRouteComponent(ROUTE_KEYS.USER_PROVISIONING)}
                            {getRouteComponent(ROUTE_KEYS.TEMPLATE_EDITOR)}
                            {getRouteComponent(ROUTE_KEYS.TEMPLATE_MANAGEMENT)}
                            {getRouteComponent(ROUTE_KEYS.USER_DASHBOARD)}
                            {getRouteComponent(ROUTE_KEYS.USER_TO_DO)}
                          </>
                        )}
                        {getRouteComponent(ROUTE_KEYS.DEFAULT)}
                      </Route>
                    </Routes>
                    {showAcceptableUseAcknowledgemnt && (
                      <AcceptableUseAcknowledgement
                        handleAcceptableUseAcknowledgement={handleAcceptableUseAcknowledgement}
                      />
                    )}
                    {!showAcceptableUseAcknowledgemnt && <ConfidentialityModal />}
                  </div>
                </div>
                <MISSnackbar />
              </BrowserRouter>
              <IdleTimerContainer auth={auth} isAuthenticated={isAuthenticated} />
            </ProgressProvider>
          </ErrorHandlerProvider>
        </NotificationProvider>
      </QueryClientProvider>
    </Suspense>
  )
}

const RouterApp = ({ username }) => {
  const auth: AuthContextProps = useAuth()
  const isMounted = useIsMounted()

  const [currentAuth, setCurrentAuth] = useState<AuthContextProps | null>(null)
  const prevUserRef = useRef<User>()

  useEffect(() => {
    if (isMounted()) {
      if (prevUserRef.current === undefined && auth.user) {
        prevUserRef.current = auth.user
        setCurrentAuth(auth)
      }
      setApiToken(auth)
    }
  }, [auth, isMounted])

  useEffect(() => {
    if (auth.user) AuthService.setUser(auth.user)
  }, [auth.user, auth])

  if (!currentAuth && !auth) return <Loader fullScreen />

  return <SecuredApp auth={currentAuth || auth} username={username} />
}

export default RouterApp

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChartingEntryAuditDTO } from '../models/ChartingEntryAuditDTO'
import type { ChartingEntryDTO } from '../models/ChartingEntryDTO'
import type { Document } from '../models/Document'
import type { PageChartingEntryDTO } from '../models/PageChartingEntryDTO'
import type { UnPagedChartingEntryStateDTO } from '../models/UnPagedChartingEntryStateDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ChartingEntryControllerService {
  /**
   * @deprecated
   * @param clientId
   * @param chartingEntryId
   * @returns ChartingEntryDTO OK
   * @throws ApiError
   */
  public static getChartingEntry(
    clientId: string,
    chartingEntryId: string
  ): CancelablePromise<ChartingEntryDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-charting-service/v1/client/{clientId}/charting-entries/{chartingEntryId}',
      path: {
        clientId: clientId,
        chartingEntryId: chartingEntryId,
      },
    })
  }

  /**
   * @deprecated
   * @param clientId
   * @param chartingEntryId
   * @param requestBody
   * @returns ChartingEntryDTO OK
   * @throws ApiError
   */
  public static updateChartingEntry(
    clientId: string,
    chartingEntryId: string,
    requestBody: ChartingEntryDTO
  ): CancelablePromise<ChartingEntryDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-charting-service/v1/client/{clientId}/charting-entries/{chartingEntryId}',
      path: {
        clientId: clientId,
        chartingEntryId: chartingEntryId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @deprecated
   * @param clientId
   * @param chartingEntryId
   * @param templateRecordId
   * @param requestBody
   * @returns ChartingEntryDTO OK
   * @throws ApiError
   */
  public static updateChartingTemplate(
    clientId: string,
    chartingEntryId: string,
    templateRecordId: string,
    requestBody: Document
  ): CancelablePromise<ChartingEntryDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-charting-service/v1/client/{clientId}/charting-entries/{chartingEntryId}/templates/{templateRecordId}',
      path: {
        clientId: clientId,
        chartingEntryId: chartingEntryId,
        templateRecordId: templateRecordId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param chartingEntryId
   * @returns ChartingEntryDTO OK
   * @throws ApiError
   */
  public static getChartingEntry1(chartingEntryId: string): CancelablePromise<ChartingEntryDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-charting-service/v1/charting-entries/{chartingEntryId}',
      path: {
        chartingEntryId: chartingEntryId,
      },
    })
  }

  /**
   * @param chartingEntryId
   * @param requestBody
   * @returns ChartingEntryDTO OK
   * @throws ApiError
   */
  public static updateChartingEntry1(
    chartingEntryId: string,
    requestBody: ChartingEntryDTO
  ): CancelablePromise<ChartingEntryDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-charting-service/v1/charting-entries/{chartingEntryId}',
      path: {
        chartingEntryId: chartingEntryId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param chartingEntryId
   * @param templateRecordId
   * @param requestBody
   * @returns ChartingEntryDTO OK
   * @throws ApiError
   */
  public static updateChartingTemplate1(
    chartingEntryId: string,
    templateRecordId: string,
    requestBody: Document
  ): CancelablePromise<ChartingEntryDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-charting-service/v1/charting-entries/{chartingEntryId}/templates/{templateRecordId}',
      path: {
        chartingEntryId: chartingEntryId,
        templateRecordId: templateRecordId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @deprecated
   * @param clientId
   * @param status
   * @param templateNames
   * @param primaryProvider
   * @param durationFrom
   * @param durationTo
   * @param serviceDateTimeFrom
   * @param serviceDateTimeTo
   * @param searchText
   * @param page
   * @param size
   * @returns PageChartingEntryDTO OK
   * @throws ApiError
   */
  public static searchChartingEntries(
    clientId: string,
    status?: Array<string>,
    templateNames?: Array<string>,
    primaryProvider?: Array<string>,
    durationFrom?: number,
    durationTo?: number,
    serviceDateTimeFrom?: string,
    serviceDateTimeTo?: string,
    searchText?: string,
    page?: number,
    size: number = 20
  ): CancelablePromise<PageChartingEntryDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-charting-service/v1/client/{clientId}/charting-entries',
      path: {
        clientId: clientId,
      },
      query: {
        status: status,
        templateNames: templateNames,
        primaryProvider: primaryProvider,
        durationFrom: durationFrom,
        durationTo: durationTo,
        serviceDateTimeFrom: serviceDateTimeFrom,
        serviceDateTimeTo: serviceDateTimeTo,
        searchText: searchText,
        page: page,
        size: size,
      },
    })
  }

  /**
   * @deprecated
   * @param clientId
   * @param requestBody
   * @returns ChartingEntryDTO OK
   * @throws ApiError
   */
  public static createChartingEntry(
    clientId: string,
    requestBody: ChartingEntryDTO
  ): CancelablePromise<ChartingEntryDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-charting-service/v1/client/{clientId}/charting-entries',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param clientId
   * @param groupId
   * @param status
   * @param templateNames
   * @param primaryProvider
   * @param durationFrom
   * @param durationTo
   * @param serviceDateTimeFrom
   * @param serviceDateTimeTo
   * @param searchText
   * @param page
   * @param size
   * @returns PageChartingEntryDTO OK
   * @throws ApiError
   */
  public static searchChartingEntries1(
    clientId?: string,
    groupId?: string,
    status?: Array<string>,
    templateNames?: Array<string>,
    primaryProvider?: Array<string>,
    durationFrom?: number,
    durationTo?: number,
    serviceDateTimeFrom?: string,
    serviceDateTimeTo?: string,
    searchText?: string,
    page?: number,
    size: number = 20
  ): CancelablePromise<PageChartingEntryDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-charting-service/v1/charting-entries',
      query: {
        clientId: clientId,
        groupId: groupId,
        status: status,
        templateNames: templateNames,
        primaryProvider: primaryProvider,
        durationFrom: durationFrom,
        durationTo: durationTo,
        serviceDateTimeFrom: serviceDateTimeFrom,
        serviceDateTimeTo: serviceDateTimeTo,
        searchText: searchText,
        page: page,
        size: size,
      },
    })
  }

  /**
   * @param requestBody
   * @returns ChartingEntryDTO OK
   * @throws ApiError
   */
  public static createChartingEntry1(
    requestBody: ChartingEntryDTO
  ): CancelablePromise<ChartingEntryDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-charting-service/v1/charting-entries',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @deprecated
   * Get list of possible next states for charting entry
   * @param clientId
   * @param chartingEntryId
   * @returns UnPagedChartingEntryStateDTO OK
   * @throws ApiError
   */
  public static getNextStatesByChartingId(
    clientId: string,
    chartingEntryId: string
  ): CancelablePromise<UnPagedChartingEntryStateDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-charting-service/v1/client/{clientId}/charting-entries/{chartingEntryId}/next-states',
      path: {
        clientId: clientId,
        chartingEntryId: chartingEntryId,
      },
    })
  }

  /**
   * @deprecated
   * Get history of chartingEntry changes by chartingEnryId
   * @param clientId
   * @param chartingEntryId
   * @returns ChartingEntryAuditDTO OK
   * @throws ApiError
   */
  public static getChartingAuditHistories(
    clientId: string,
    chartingEntryId: string
  ): CancelablePromise<Array<ChartingEntryAuditDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-charting-service/v1/client/{clientId}/charting-entries/{chartingEntryId}/history',
      path: {
        clientId: clientId,
        chartingEntryId: chartingEntryId,
      },
    })
  }

  /**
   * @deprecated
   * Get charting audit by chartingId and version
   * @param clientId
   * @param chartingEntryId
   * @param version
   * @returns ChartingEntryAuditDTO OK
   * @throws ApiError
   */
  public static getChartingEntryAuditById(
    clientId: string,
    chartingEntryId: string,
    version: number
  ): CancelablePromise<ChartingEntryAuditDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-charting-service/v1/client/{clientId}/charting-entries/{chartingEntryId}/history/{version}',
      path: {
        clientId: clientId,
        chartingEntryId: chartingEntryId,
        version: version,
      },
    })
  }

  /**
   * @deprecated
   * @param clientId
   * @param templateId
   * @param filters
   * @param isGlobal
   * @param searchOperator
   * @returns any OK
   * @throws ApiError
   */
  public static getFilteredChartingEntries(
    clientId: string,
    templateId: string,
    filters?: Array<string>,
    isGlobal: boolean = false,
    searchOperator: string = 'AND'
  ): CancelablePromise<Array<any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-charting-service/v1/client/{clientId}/charting-entries/filter',
      path: {
        clientId: clientId,
      },
      query: {
        templateId: templateId,
        filters: filters,
        isGlobal: isGlobal,
        searchOperator: searchOperator,
      },
    })
  }

  /**
   * @deprecated
   * @param templateId
   * @param clientId
   * @param filters
   * @param isGlobal
   * @param searchOperator
   * @returns any OK
   * @throws ApiError
   */
  public static getFilteredChartingEntries1(
    templateId: string,
    clientId?: string,
    filters?: Array<string>,
    isGlobal: boolean = false,
    searchOperator: string = 'AND'
  ): CancelablePromise<Array<any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-charting-service/v1/client/charting-entries/filter',
      query: {
        templateId: templateId,
        clientId: clientId,
        filters: filters,
        isGlobal: isGlobal,
        searchOperator: searchOperator,
      },
    })
  }

  /**
   * Get list of possible next states for charting entry
   * @param chartingEntryId
   * @returns UnPagedChartingEntryStateDTO OK
   * @throws ApiError
   */
  public static getNextStatesByChartingId1(
    chartingEntryId: string
  ): CancelablePromise<UnPagedChartingEntryStateDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-charting-service/v1/charting-entries/{chartingEntryId}/next-states',
      path: {
        chartingEntryId: chartingEntryId,
      },
    })
  }

  /**
   * Get history of chartingEntry changes by chartingEnryId
   * @param chartingEntryId
   * @returns ChartingEntryAuditDTO OK
   * @throws ApiError
   */
  public static getChartingAuditHistories1(
    chartingEntryId: string
  ): CancelablePromise<Array<ChartingEntryAuditDTO>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-charting-service/v1/charting-entries/{chartingEntryId}/history',
      path: {
        chartingEntryId: chartingEntryId,
      },
    })
  }

  /**
   * Get charting audit by chartingId and version
   * @param chartingEntryId
   * @param version
   * @returns ChartingEntryAuditDTO OK
   * @throws ApiError
   */
  public static getChartingEntryAuditById1(
    chartingEntryId: string,
    version: number
  ): CancelablePromise<ChartingEntryAuditDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-charting-service/v1/charting-entries/{chartingEntryId}/history/{version}',
      path: {
        chartingEntryId: chartingEntryId,
        version: version,
      },
    })
  }

  /**
   * @param templateId
   * @param clientId
   * @param groupId
   * @param filters
   * @param isGlobal
   * @param searchOperator
   * @returns any OK
   * @throws ApiError
   */
  public static getFilteredEntries(
    templateId: string,
    clientId?: string,
    groupId?: string,
    filters?: Array<string>,
    isGlobal: boolean = false,
    searchOperator: string = 'AND'
  ): CancelablePromise<Array<any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-charting-service/v1/charting-entries/filter',
      query: {
        templateId: templateId,
        clientId: clientId,
        groupId: groupId,
        filters: filters,
        isGlobal: isGlobal,
        searchOperator: searchOperator,
      },
    })
  }
}

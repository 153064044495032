/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagePersonnelIdentifierDTO } from '../models/PagePersonnelIdentifierDTO'
import type { PersonnelIdentifierDTO } from '../models/PersonnelIdentifierDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PersonnelIdentifierControllerService {
  /**
   * @param personnelId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PagePersonnelIdentifierDTO Successfully retrieved the identifiers
   * @throws ApiError
   */
  public static getPersonnelIdentifiers(
    personnelId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PagePersonnelIdentifierDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/identifiers',
      path: {
        personnelId: personnelId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
      errors: {
        403: `User does not have permission to view the identifiers`,
      },
    })
  }

  /**
   * @param personnelId
   * @param requestBody
   * @returns PersonnelIdentifierDTO Successfully saved the identifiers
   * @throws ApiError
   */
  public static savePersonnelIdentifiers(
    personnelId: string,
    requestBody: Array<PersonnelIdentifierDTO>
  ): CancelablePromise<Array<PersonnelIdentifierDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/identifiers',
      path: {
        personnelId: personnelId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `User does not have permission to save the identifier`,
      },
    })
  }

  /**
   * @param personnelId
   * @param identifierId
   * @returns void
   * @throws ApiError
   */
  public static deletePersonnelIdentifier(
    personnelId: string,
    identifierId: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/identifiers/{identifierId}',
      path: {
        personnelId: personnelId,
        identifierId: identifierId,
      },
      errors: {
        403: `User does not have permission to delete the identifier`,
      },
    })
  }
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IdheServiceDTO } from '../models/IdheServiceDTO'
import type { PageIdheServiceDTO } from '../models/PageIdheServiceDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class IdheServiceControllerService {
  /**
   * @param id
   * @returns IdheServiceDTO Successfully retrieved the specified service
   * @throws ApiError
   */
  public static getServiceById(id: string): CancelablePromise<IdheServiceDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/services/{id}',
      path: {
        id: id,
      },
      errors: {
        403: `User does not have permission to view the service`,
        404: `Service not found`,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns IdheServiceDTO Successfully updated the specified service
   * @throws ApiError
   */
  public static updateService(
    id: string,
    requestBody: IdheServiceDTO
  ): CancelablePromise<IdheServiceDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/services/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input data`,
        403: `User does not have permission to update the service`,
        404: `Service not found`,
      },
    })
  }

  /**
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageIdheServiceDTO Successfully retrieved all services
   * @throws ApiError
   */
  public static getAllServices(
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageIdheServiceDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/services',
      query: {
        page: page,
        size: size,
        sort: sort,
      },
      errors: {
        403: `User does not have permission to get all services`,
      },
    })
  }

  /**
   * @param requestBody
   * @returns IdheServiceDTO Successfully created the specified service
   * @throws ApiError
   */
  public static createService(requestBody: IdheServiceDTO): CancelablePromise<IdheServiceDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/services',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid input data`,
        403: `User does not have permission to create the service`,
      },
    })
  }

  /**
   * @param activeOnly
   * @param name
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageIdheServiceDTO Successfully retrieved the specified service
   * @throws ApiError
   */
  public static searchServices(
    activeOnly: boolean = true,
    name?: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageIdheServiceDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/services/search',
      query: {
        activeOnly: activeOnly,
        name: name,
        page: page,
        size: size,
        sort: sort,
      },
      errors: {
        403: `User does not have permission to search services`,
      },
    })
  }
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagePersonnelLanguageDTO } from '../models/PagePersonnelLanguageDTO'
import type { PersonnelLanguageDTO } from '../models/PersonnelLanguageDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PersonnelLanguageControllerService {
  /**
   * @param personnelId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PagePersonnelLanguageDTO Successfully retrieved the languages
   * @throws ApiError
   */
  public static getPersonnelLanguages(
    personnelId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PagePersonnelLanguageDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/languages',
      path: {
        personnelId: personnelId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
      errors: {
        403: `User does not have permission to view the languages`,
      },
    })
  }

  /**
   * @param personnelId
   * @param requestBody
   * @returns PersonnelLanguageDTO Successfully saved the languages
   * @throws ApiError
   */
  public static savePersonnelLanguages(
    personnelId: string,
    requestBody: Array<PersonnelLanguageDTO>
  ): CancelablePromise<Array<PersonnelLanguageDTO>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/languages',
      path: {
        personnelId: personnelId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `User does not have permission to save the languages`,
      },
    })
  }

  /**
   * @param personnelId
   * @param languageId
   * @returns void
   * @throws ApiError
   */
  public static deletePersonnelLanguage(
    personnelId: string,
    languageId: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/languages/{languageId}',
      path: {
        personnelId: personnelId,
        languageId: languageId,
      },
      errors: {
        403: `User does not have permission to delete the language`,
      },
    })
  }
}

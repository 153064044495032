import { useCallback, useEffect, useState } from 'react'
import IdleTimer from 'react-idle-timer'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { AppSettingControllerService } from 'services/openapi'
import TimeoutModal from './TimeOutModal'

const IdleTimerContainer = (props: any) => {
  const { auth, isAuthenticated } = props
  const [showModal, setShowModal] = useState(false)
  const [idleTimer, setIdleTimer] = useState<any>(null)
  const [logoutTimer, setLogoutTimer] = useState<any>(null)
  const [logoutTimerDuration, setLogoutTimerDuration] = useState(540) // default timeout
  const [fetchedLogoutTimerDuration, setFetchedLogoutTimerDuration] = useState(false)
  const [logoutTimerModalDuration, setLogoutTimerModalDuration] = useState(30) //default timeout
  const [fetchedLogoutTimerModalDuration, setFetchedLogoutTimerModalDuration] = useState(false)

  // Fetch the duration of time the user can stay idle
  const { handleApiError } = useErrorHandler()

  // Fetch the duration of time the user can stay idle
  const getLogoutTimerDuration = useCallback(() => {
    if (isAuthenticated) {
      AppSettingControllerService.getAppSetting('logout_timer_duration')
        .then((result: any) => {
          if (result) {
            setLogoutTimerDuration(result.data)
          }
        })
        .catch((error) => {
          handleApiError(error)
        })
        .finally(() => {
          setFetchedLogoutTimerDuration(true)
        })
    }
  }, [isAuthenticated, handleApiError])

  // Fetch the duration the sign out modal should be visible to the user for
  const getLogoutTimeoutModalDuration = useCallback(() => {
    if (isAuthenticated) {
      AppSettingControllerService.getAppSetting('logout_modal_duration')
        .then((result: any) => {
          if (result) {
            setLogoutTimerModalDuration(parseInt(result.data))
          }
        })
        .catch((error) => {
          handleApiError(error)
        })
        .finally(() => {
          setFetchedLogoutTimerModalDuration(true)
        })
    }
  }, [isAuthenticated, handleApiError])

  useEffect(() => {
    if (isAuthenticated) {
      if (!fetchedLogoutTimerDuration) {
        getLogoutTimerDuration()
      }
      if (!fetchedLogoutTimerModalDuration) {
        getLogoutTimeoutModalDuration()
      }
    }
  }, [
    fetchedLogoutTimerDuration,
    fetchedLogoutTimerModalDuration,
    getLogoutTimeoutModalDuration,
    getLogoutTimerDuration,
    isAuthenticated,
  ])

  // Set the idle timeout duration
  const onIdle = () => {
    togglePopup()
    setLogoutTimer(setTimeout(() => handleLogout(), 1000 * logoutTimerModalDuration * 1))
  }

  // Show/hide the session idle modal
  const togglePopup = () => {
    setShowModal(!showModal)
  }

  // Reset the timer if the user responds to the idle session modal, otherwise sign the user out
  const handleStayLoggedIn = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer)
      setLogoutTimer(null)
    }
    idleTimer.reset()
    togglePopup()
  }

  // Sign the user out of the application.
  const handleLogout = async () => {
    if (isAuthenticated) {
      localStorage.setItem('logged_out', auth.user?.profile?.preferred_username || '')
      localStorage.removeItem('login_initiated')
      const tenantId = localStorage.getItem('tenantId')

      const idToken = auth.user?.id_token
      const rootUrl = auth.settings.authority
      const silentHref = `${rootUrl}/protocol/openid-connect/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(
        window.location.origin + `/${tenantId}/silent-logout`
      )}`
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      iframe.src = silentHref
      document.body.appendChild(iframe)

      try {
        await auth.removeUser()
        await auth.revokeTokens()
        localStorage.clear()

        window.location.href = `${window.location.origin}/${tenantId}/login`
      } catch (error) {
        console.error('Error during logout:', error)
      } finally {
        if (iframe) document.body.removeChild(iframe)
      }
    }
  }

  return (
    <div
      style={{
        bottom: 0,
        left: 0,
        minHeight: '100vh',
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: '-20',
      }}
    >
      {isAuthenticated && (
        <div>
          <IdleTimer
            element={document}
            onIdle={onIdle}
            ref={(ref: any) => {
              setIdleTimer(ref)
            }}
            stopOnIdle
            timeout={1000 * logoutTimerDuration * 1}
          />
          <TimeoutModal
            handleStayLoggedIn={handleStayLoggedIn}
            showModal={showModal}
            time={logoutTimerModalDuration}
          />
        </div>
      )}
    </div>
  )
}

export default IdleTimerContainer

import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import MISButton from 'common/components/MISButton'
import { ErrorType } from 'modules/shared/utils'
import { selectChartingCurrentTemplate } from 'store/selectors/charting'
import { selectDataDomains } from 'store/selectors/permission'
import TemplateEditorMetadata from './TemplateEditorMetadata'

type SaveTemplateDialogProps = {
  openDialog: boolean
  handleClose: () => void
  handleSaveClicked: () => void
}

const SaveTemplateDialog = ({
  handleClose,
  handleSaveClicked,
  openDialog,
}: SaveTemplateDialogProps) => {
  const currTemplate = useSelector(selectChartingCurrentTemplate)
  const dataDomains = useSelector(selectDataDomains)
  const [errors, setErrors] = useState<ErrorType[]>([])

  const onSaveClicked = useCallback(() => {
    const validate = () => {
      let isValid = true
      const errors: ErrorType[] = []
      if (!currTemplate?.name) {
        isValid = false
        errors.push({
          field: `templateName`,
          message: 'Name is required',
        })
      }
      if (!currTemplate?.tags) {
        isValid = false
        errors.push({
          field: `templateTags`,
          message: 'Tags are required',
        })
      }
      return { errors, isValid }
    }

    const { errors, isValid } = validate()
    setErrors(errors)
    if (isValid) handleSaveClicked()
  }, [currTemplate?.name, currTemplate?.tags, handleSaveClicked])
  return (
    <div>
      <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={openDialog}>
        <DialogTitle component="h1" sx={{ fontSize: '26px', marginTop: '10px' }}>
          Template Details
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            color: 'black',
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TemplateEditorMetadata
            currTemplate={currTemplate}
            dataDomains={dataDomains}
            errors={errors}
            fromPopup
          />
        </DialogContent>
        <DialogActions>
          <MISButton onClick={onSaveClicked}>Add</MISButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SaveTemplateDialog

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagePersonnelContactDTO } from '../models/PagePersonnelContactDTO'
import type { PersonnelContactDTO } from '../models/PersonnelContactDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PersonnelContactControllerService {
  /**
   * @param personnelId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PagePersonnelContactDTO Successfully retrieved the contacts
   * @throws ApiError
   */
  public static getPersonnelContacts(
    personnelId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PagePersonnelContactDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/contacts',
      path: {
        personnelId: personnelId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
      errors: {
        403: `User does not have permission to view the contacts`,
      },
    })
  }

  /**
   * @param personnelId
   * @param requestBody
   * @returns PersonnelContactDTO Successfully saved the contacts
   * @throws ApiError
   */
  public static savePersonnelContacts(
    personnelId: string,
    requestBody: Array<PersonnelContactDTO>
  ): CancelablePromise<Array<PersonnelContactDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/contacts',
      path: {
        personnelId: personnelId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `User does not have permission to save the contact`,
      },
    })
  }

  /**
   * @param personnelId
   * @param contactId
   * @returns void
   * @throws ApiError
   */
  public static deletePersonnelContact(
    personnelId: string,
    contactId: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/contacts/{contactId}',
      path: {
        personnelId: personnelId,
        contactId: contactId,
      },
      errors: {
        403: `User does not have permission to delete the contact`,
      },
    })
  }
}

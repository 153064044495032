import './GroupRecordDetails.scss'

import { lazy, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import MISTabbedContentContainer from 'common/components/contentpane/MISTabbedContentContainer'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import Loader from 'common/components/Loader'
import ClientRecordHeader from 'modules/client/ClientDetails/ClientRecordHeader'
import useGroups from 'modules/shared/hooks/useGroups'

const NameSection = lazy(() => import('./Names/NameSection'))

const GroupRecordDetails = () => {
  const { groupId } = useParams()
  const { t } = useTranslation('common')
  const { chartingGroupInContext } = useGroups(groupId)

  const tabConfig = useCallback(
    (groupId: string) => [
      {
        active: false,
        component: <NameSection />,
        dirty: false,
        disabled: false,
        id: 'navigation.groups-menu.names',
        label: t('navigation.groups-menu.names'),
        visibile: true,
      },
    ],
    [t]
  )

  return (
    <MISBaseContainer>
      <div className="group-record-details">
        {chartingGroupInContext?.id ? (
          <>
            <ClientRecordHeader />
            <MISTabbedContentContainer tabConfig={tabConfig(chartingGroupInContext.id)} />
          </>
        ) : (
          <Loader fullScreen />
        )}
      </div>
    </MISBaseContainer>
  )
}

export default GroupRecordDetails

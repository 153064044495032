/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagePersonnelCredentialDTO } from '../models/PagePersonnelCredentialDTO'
import type { PersonnelCredentialDTO } from '../models/PersonnelCredentialDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PersonnelCredentialControllerService {
  /**
   * Returns a paged list of Credentials for Personnel
   * List is returned as Paged collection
   * @param personnelId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PagePersonnelCredentialDTO Successfully returned the array of credentials
   * @throws ApiError
   */
  public static getPersonnelCredentials(
    personnelId: string,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PagePersonnelCredentialDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/credentials',
      path: {
        personnelId: personnelId,
      },
      query: {
        page: page,
        size: size,
        sort: sort,
      },
      errors: {
        403: `User does not have permission to view the credentials`,
      },
    })
  }

  /**
   * Updates the list of credentials for personnel
   * updates the list and then returns as UnPaged collection
   * @param personnelId
   * @param requestBody
   * @returns PersonnelCredentialDTO Successfully saved the credentials
   * @throws ApiError
   */
  public static savePersonnelCredential(
    personnelId: string,
    requestBody: Array<PersonnelCredentialDTO>
  ): CancelablePromise<Array<PersonnelCredentialDTO>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/credentials',
      path: {
        personnelId: personnelId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `User does not have permission to save the credential`,
      },
    })
  }

  /**
   * Delete credential by credentialId
   * Soft deletes the credential by setting deleted flag to true
   * @param personnelId
   * @param credentialId
   * @returns void
   * @throws ApiError
   */
  public static deletePersonnelCredential(
    personnelId: string,
    credentialId: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-administrative-info-service/v1/personnel/{personnelId}/credentials/{credentialId}',
      path: {
        personnelId: personnelId,
        credentialId: credentialId,
      },
      errors: {
        403: `User does not have permission to delete the credential`,
      },
    })
  }
}

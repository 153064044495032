/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CodedRef } from './CodedRef'
import type { GroupSearchResultDTO } from './GroupSearchResultDTO'

export type ClientSearchResultDTO = {
  id?: string
  type?: ClientSearchResultDTO.type
  birthdate?: string
  gender?: CodedRef
  pronouns?: CodedRef
  firstName?: string
  middleName?: string
  lastName?: string
  identifierType?: CodedRef
  identifierValue?: string
  groupSearchResultDTO?: GroupSearchResultDTO
  clientSearchResultDTO?: ClientSearchResultDTO
}

export namespace ClientSearchResultDTO {
  export enum type {
    GROUP = 'GROUP',
    CLIENT = 'CLIENT',
  }
}

import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '..'

export const selectChartingClientIdInContext = createSelector(
  [(state: RootState) => state.charting],
  (charting) => charting.clientIdInContext
)

export const selectChartingClients = createSelector(
  [(state: RootState) => state.charting],
  (charting) => charting.chartingClients
)
export const selectChartingGroupInContext = createSelector(
  [(state: RootState) => state.charting],
  (charting) => charting.chartingGroupInContext
)

export const selectChartingCurrentTemplate = createSelector(
  [(state: RootState) => state.charting],
  (charting) => charting.currentTemplate
)

export const selectChartingMyTemplates = createSelector(
  [(state: RootState) => state.charting],
  (charting) => charting.myTemplates
)

export const selectChartingPreviewState = createSelector(
  [(state: RootState) => state.charting],
  (charting) => charting.preview
)

export const selectChartingPublicTemplates = createSelector(
  [(state: RootState) => state.charting],
  (charting) => charting.publicTemplates
)

export const selectChartingValidationActive = createSelector(
  [(state: RootState) => state.charting],
  (charting) => charting.validationActive
)

export const selectChartingActiveHistoricTabs = createSelector(
  [(state: RootState) => state.charting],
  (charting) => charting.activeHistoricTabs
)

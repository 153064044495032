import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Divider, Grid } from '@mui/material'
import { useRecoilState } from 'recoil'
import ADD_CLIENT_IMG_SRC from 'assets/images/add-client.jpg'
import { Content } from 'common/components/contentpane/Content'
import MISImageContentContainer from 'common/components/contentpane/MISImageContentContainer'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { isDirtyState } from 'recoil/isDirty'
import { GroupControllerService, GroupDTO } from 'services/openapi'

const AddEditGroup = () => {
  const { t } = useTranslation('common')
  const { showSnackSuccess } = useSnack()
  const { buildError, getError, handleApiError, hasError, setErrorList } = useErrorHandler()
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const navigate = useNavigate()

  const [data, setData] = useState<GroupDTO>({
    description: '',
    name: '',
  })

  const handleChange = useCallback(
    (value: any, field: string) => {
      if (!isDirty) setIsDirty(true)
      let updatedData: GroupDTO
      switch (field) {
        case 'startDate': {
          updatedData = {
            ...data,
            effective: { ...data.effective, startDate: value },
          }
          break
        }
        case 'endDate': {
          updatedData = {
            ...data,
            effective: { ...data.effective, endDate: value },
          }
          break
        }
        default: {
          updatedData = {
            ...data,
            [field]: value,
          }
        }
      }
      setData(updatedData)
    },
    [data, setData, isDirty, setIsDirty]
  )

  const handleSaveGroup = () => {
    setIsDirty(false)
    const saveGroup = async () => {
      let response

      try {
        response = await GroupControllerService.createGroup(data)
        setData(response)
        showSnackSuccess(t('api.save-success'))
        navigate(`/groups/group-record/${response.id}`)
      } catch (error) {
        handleApiError(error)
      }
    }
    if (validate()) {
      saveGroup()
    }
  }

  const validate = () => {
    const errors: string[] = []
    if (!data.name) {
      errors.push(
        buildError('add-group.name', 'api.error.required-field', {
          fieldName: 'Name',
        })
      )
    }
    if (!data.effective?.startDate) {
      errors.push(
        buildError('add-group.start-date', 'api.error.required-field', {
          fieldName: 'Start Date',
        })
      )
    }

    setErrorList(errors)
    return errors.length === 0
  }

  const getGroupContent = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <MISTextField
              error={hasError('add-group.name')}
              helperText={getError('add-group.name')}
              id="name"
              label={t('group.add-edit.name')}
              onChange={(event) => handleChange(event.target.value, 'name')}
              required
              value={data.name}
            />
          </Grid>

          <Grid item xs={8}>
            <MISTextField
              id="description"
              label={t('group.add-edit.description')}
              onChange={(event) => handleChange(event.target.value, 'description')}
              value={data.description}
            />
          </Grid>

          <Grid item xs={2}>
            <MISDatePicker
              error={hasError('add-group.start-date')}
              helperText={getError('add-group.start-date')}
              id="start-date"
              label={t('group.add-edit.start-date')}
              onChange={(value) => handleChange(value, 'startDate')}
              readOnly={false}
              required
              value={data.effective?.startDate}
            />
          </Grid>
          <Grid item xs={2}>
            <MISDatePicker
              id="end-date"
              label={t('group.add-edit.end-date')}
              onChange={(value) => handleChange(value, 'endDate')}
              readOnly={false}
              value={data.effective?.endDate}
            />
          </Grid>
        </Grid>
        <Divider className="divider" />
        <Box className="actions" sx={{ textAlign: 'end' }}>
          <MISButton onClick={handleSaveGroup} sx={{ marginLeft: '1rem' }} variant="contained">
            {data.id ? t('group.add-edit.update-group-text') : t('group.add-edit.add-group-text')}
          </MISButton>
        </Box>
      </>
    )
  }

  return (
    <MISBaseContainer>
      <div className="add-edit-provider-staff">
        <MISImageContentContainer imgSrc={ADD_CLIENT_IMG_SRC}>
          <Content
            content={getGroupContent()}
            heading={
              data.id ? t('group.add-edit.update-group-text') : t('group.add-edit.add-group-text')
            }
            isCollapsible={false}
            isDivider
          />
        </MISImageContentContainer>
      </div>
    </MISBaseContainer>
  )
}

export default AddEditGroup

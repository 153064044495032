export const ROUTE_KEYS = {
  AGREEMENTS_ACCEPTABLE_USE: 'AGREEMENTS_ACCEPTABLE_USE',
  AGREEMENTS_CONFIDENTIAL: 'AGREEMENTS_CONFIDENTIAL',
  ALERTS: 'ALERTS',
  CHARTING: 'CHARTING',
  CHARTING_SERVICES: 'CHARTING_SERVICES',
  CLIENT_CHARTING: 'CLIENT_CHARTING',
  CLIENT_CHARTING_IN_PROGRESS: 'CLIENT_CHARTING_IN_PROGRESS',
  CLIENT_CREATE: 'CLIENTS_CREATE',
  CLIENT_DASHBOARD: 'CLIENTS_DASHBOARD',
  CLIENT_ENCOUNTERS: 'CLIENT_ENCOUNTERS',
  CLIENT_HEALTH_CONCERNS: 'CLIENT_HEALTH_CONCERNS',
  CLIENT_IMMUNIZATIONS: 'CLIENT_IMMUNIZATIONS',
  CLIENT_PROFILE: 'CLIENT_PROFILE',
  CLIENT_RECORD: 'CLIENT_RECORD',
  CLIENT_RUNNING_NOTES: 'CLIENT_RUNNING_NOTES',
  CLIENT_TO_DO: 'CLIENT_TO_DO',
  CLIENTS: 'CLIENTS',
  DEFAULT: 'DEFAULT',
  DOMAIN_SELECTION: 'DOMAIN_SELECTION',
  ENCOUNTER_SERVICE_TEMPLATE_CREATE: 'ENCOUNTER_SERVICE_TEMPLATE_CREATE',
  ENCOUNTER_SERVICE_TEMPLATE_EDIT: 'ENCOUNTER_SERVICE_TEMPLATE_EDIT',
  ENCOUNTER_SERVICE_TEMPLATE_GROUPS: 'ENCOUNTER_SERVICE_TEMPLATE_GROUPS',
  ENCOUNTER_SERVICE_TEMPLATES: 'ENCOUNTER_SERVICE_TEMPLATES',
  FORMS: 'FORMS',
  FORMS_VIEWER: 'FORMS_VIEWER',
  GOVERNANCE_AGENCIES: 'GOVERNANCE_AGENCIES',
  GOVERNANCE_AGENCY_CREATE: 'GOVERNANCE_AGENCY_CREATE',
  GOVERNANCE_AGENCY_DEPARTMENT_CREATE: 'GOVERNANCE_AGENCY_DEPARTMENT_CREATE',
  GOVERNANCE_AGENCY_DEPARTMENT_EDIT: 'GOVERNANCE_AGENCY_DEPARTMENT_EDIT',
  GOVERNANCE_AGENCY_EDIT: 'GOVERNANCE_AGENCY_EDIT',
  GROUP_CHARTING: 'GROUP_CHARTING',
  GROUP_CHARTING_IN_PROGRESS: 'GROUP_CHARTING_IN_PROGRESS',
  GROUP_CREATE: 'GROUP_CREATE',
  GROUP_DETAIL: 'GROUP_DETAIL',
  GROUP_SEARCH: 'GROUP_SEARCH',
  PROGRAM_GROUPS: 'PROGRAM_GROUPS',
  PROGRAMS: 'PROGRAMS',
  PROVIDER_STAFF_CREATE: 'PROVIDER_STAFF_CREATE',
  PROVIDER_STAFF_PROFILE: 'PROVIDER_STAFF_PROFILE',
  PROVIDER_STAFF_SEARCH: 'PROVIDER_STAFF_SEARCH',
  PURPOSE_GROUPS: 'PURPOSE_GROUPS',
  PURPOSES: 'PURPOSES',
  REPORTS: 'REPORTS',
  REPORTS_VIEWER: 'REPORTS_VIEWER',
  SIGN_IN: 'SIGN_IN',
  SILENT_LOGOUT: 'SILENT_LOGOUT',
  TEAMS: 'TEAMS',
  TEMPLATE_EDITOR: 'TEMPLATE_EDITOR',
  TEMPLATE_MANAGEMENT: 'TEMPLATE_MANAGEMENT',
  TYPE_GROUPS: 'TYPE_GROUPS',
  TYPES: 'TYPES',
  USER_DASHBOARD: 'USER_DASHBOARD',
  USER_PREFERENCES: 'USER_PREFERENCES',
  USER_PROVISIONING: 'USER_PROVISIONING',
  USER_TO_DO: 'USER_TO_DO',
}

import { TextField, TextFieldProps } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import global from 'common/styles/global.scss'
import misTheme from 'core/styles/theme'

const MISTextField = (props: TextFieldProps) => {
  return (
    <ThemeProvider theme={misTheme}>
      <TextField
        {...props}
        color={props.color || 'primary'}
        size={props.size || 'small'}
        sx={{
          '& .MuiInputLabel-root.Mui-disabled': {
            color: global.FIELD_DISABLED_COLOR,
          },
          '& .MuiOutlinedInput-input.Mui-disabled': {
            '-webkit-text-fill-color': global.FIELD_DISABLED_COLOR,
            color: global.FIELD_DISABLED_COLOR,
            cursor: 'not-allowed',
          },
          '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-readOnly .MuiOutlinedInput-notchedOutline':
            {
              border: `1px solid ${global.FIELD_BORDER_COLOR}`,
            },
          '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${global.FIELD_BORDER_COLOR_DISABLED}`,
          },
          '& .MuiOutlinedInput-root.Mui-readOnly': {
            cursor: 'pointer',
            pointerEvents: 'none',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${global.FIELD_BORDER_COLOR_FOCUSED}`,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${global.FIELD_BORDER_COLOR_HOVER}`,
          },
          borderRadius: global.FIELD_BORDER_RADIUS,
          boxSizing: 'border-box',
          color: global.FIELD_LABEL_COLOR,
          fontFamily: global.BASE_FONT_FAMILY,
          textAlign: 'left',
        }}
      />
    </ThemeProvider>
  )
}

export default MISTextField

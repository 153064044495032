import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import HistoryIcon from '@mui/icons-material/History'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from '@mui/material'
import { useRecoilValue } from 'recoil'
import MISCheckbox from 'common/components/form/MISCheckbox'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import GLOBAL from 'common/styles/global.scss'
import { isoDateToDisplayFormatWithTime } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import {
  displayChipsUtil,
  evaluateChipsUtil,
  evaluateLabelUtil,
  getFilteredListUtilWithoutMember,
} from 'modules/shared/StaffAssociation/StaffAssociationUtils'
import { terminologySelector } from 'recoil/terminology'
import {
  PersonnelControllerService,
  PersonnelDTO,
  PrivacyDirectiveControllerService,
  PrivacyDirectiveDTO,
  PrivacyDirectivePersonnelDTO,
  UserControllerService,
} from 'services/openapi'
import {
  MIS_DIRECTIVE_ACCESS_PERSONNEL_TYPES,
  MIS_DIRECTIVE_ACCESS_TYPES,
} from 'services/terminologyConstants'
import { setClientPrivacyDirectives } from 'store/reducers/client'
import { selectClientPrivacyDirectives } from 'store/selectors/client'
import CustomMultiValueAutocomplete, { MutliValueOptions } from './CustomMultiValueAutocomplete'
import PrivacyTimeline from './PrivacyTimeline'

const PrivacyDirectivesPopover = () => {
  const dispatch = useDispatch()
  const { handleApiError } = useErrorHandler()
  const { t } = useTranslation('common')
  const { clientId } = useParams()
  const accessTypeOptions = useRecoilValue(terminologySelector(MIS_DIRECTIVE_ACCESS_TYPES))
  const accessPersonnelTypeOptions = useRecoilValue(
    terminologySelector(MIS_DIRECTIVE_ACCESS_PERSONNEL_TYPES)
  )
  const clientPrivacyDirectives = useSelector(selectClientPrivacyDirectives)

  const hideTimerRef = useRef<NodeJS.Timeout | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [popoverState, setPopoverState] = useState<string>()
  const [providerOptions, setProviderOptions] = useState<PersonnelDTO[]>([])
  const [providerList, setProviderList] = useState<MutliValueOptions[]>([])
  const [isProviderDenied, setIsProviderDenied] = useState<boolean>(false)
  const [viewingHistory, setViewingHistory] = useState<boolean>(false)
  const [isRestricted, setIsRestricted] = useState<boolean>(false)
  const [reasons, setReasons] = useState<string>('')
  const [latestText, setLatestText] = useState<string>('')
  const [reasonsError, setReasonsError] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState<boolean>(false)

  const handleActualSelect = useCallback((providerList, denied) => {
    const newList = providerList
      .filter(Boolean)
      .map((provider: PersonnelDTO | MutliValueOptions) => {
        if ('provider' in provider) {
          return provider
        } else {
          return {
            label: evaluateLabelUtil(provider?.names),
            provider: provider,
          }
        }
      })
    if (newList.length > 0) setIsRestricted(true)
    setProviderList(newList)
    setIsProviderDenied(denied)
    setIsDirty(true)
  }, [])

  const handleSearchText = useCallback(async (searchText) => {
    if (searchText) {
      const response = await PersonnelControllerService.searchPersonnel(
        searchText,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        1000000
      )
      if (response.content) {
        setProviderOptions(response.content)
      }
    }
  }, [])

  const handleSearch = useCallback(
    (searchText) => {
      if (hideTimerRef.current !== null) {
        clearTimeout(hideTimerRef.current)
        hideTimerRef.current = null
      }
      hideTimerRef.current = setTimeout(() => {
        if (searchText.length >= 3) {
          handleSearchText(searchText)
        }
      }, 1500)
    },
    [handleSearchText]
  )

  const handleClick = useCallback(async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setIsDirty(false)
    setAnchorEl(null)
  }, [])

  const handleViewHistory = useCallback(() => {
    setViewingHistory(true)
  }, [])

  const handleSaveClicked = useCallback(() => {
    const saveDirective = async (id: string) => {
      if (reasons === '') {
        setReasonsError(true)
        return
      }

      const providers: PrivacyDirectivePersonnelDTO[] = providerList.map((provider) => {
        return {
          accessType: isProviderDenied
            ? accessPersonnelTypeOptions.find((accessType) => accessType.code === 'DENIED')
            : accessPersonnelTypeOptions.find((accessType) => accessType.code === 'ALLOWED'),
          personnelId: provider.provider.id,
        }
      })
      const currentDirective: PrivacyDirectiveDTO = {
        directive: isRestricted
          ? accessTypeOptions.find((accessType) => accessType.code === 'RESTRICTED')
          : accessTypeOptions.find((accessType) => accessType.code === 'UNRESTRICTED'),
        personnel: providers,
        reason: reasons,
      }
      const response = await PrivacyDirectiveControllerService.createDirective(id, currentDirective)
      if (response) {
        dispatch(setClientPrivacyDirectives(response))
        setIsDirty(false)
        setAnchorEl(null)
      }
    }

    if (clientId) {
      saveDirective(clientId)
    }
  }, [
    accessPersonnelTypeOptions,
    accessTypeOptions,
    clientId,
    dispatch,
    isProviderDenied,
    isRestricted,
    providerList,
    reasons,
  ])

  useEffect(() => {
    const getClientDirective = async () => {
      if (!clientPrivacyDirectives || clientPrivacyDirectives.directive?.code !== 'RESTRICTED') {
        setIsRestricted(false)
        setIsProviderDenied(false)
        setReasons('')
        setLatestText('')
        setProviderList([])
        return
      }
      const directive = clientPrivacyDirectives
      setIsRestricted(directive.directive?.code === 'RESTRICTED')
      setIsProviderDenied(directive.personnel?.[0]?.accessType?.code === 'DENIED')
      setReasons(directive.reason || '')
      if (directive.auditInfo?.lastUpdated?.user?.id) {
        const latestUser = await UserControllerService.getById(
          directive.auditInfo?.lastUpdated?.user.id
        )
        if (latestUser) {
          setLatestText(
            `${t('privacy-directives.most-recent-changes')}` +
              latestUser.firstName +
              ' ' +
              latestUser.lastName +
              ' ' +
              isoDateToDisplayFormatWithTime(directive.auditInfo?.lastUpdated?.date)
          )
        }
      }

      if (directive.personnel && directive.personnel?.length !== 0) {
        const providerOptions = await fetchProviderOptions(directive.personnel)
        setProviderList(providerOptions.filter((x) => x !== null) as MutliValueOptions[])
      } else {
        setProviderList([])
      }
    }

    if (anchorEl) {
      getClientDirective()
    }
  }, [anchorEl, clientPrivacyDirectives, handleApiError, t])

  async function fetchProviderOptions(personnel: PrivacyDirectivePersonnelDTO[]) {
    if (!personnel?.length) {
      return []
    }

    const providerPromises = personnel
      .map((p) => (p.personnelId ? PersonnelControllerService.getPersonnel(p.personnelId) : null))
      .filter(Boolean)

    const providerList = await Promise.all(providerPromises)
    return providerList.filter(Boolean).map((provider) => ({
      label: evaluateLabelUtil(provider?.names),
      provider: provider,
    }))
  }

  return (
    <>
      <IconButton
        aria-controls={anchorEl ? 'menu-button-icon' : undefined}
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-haspopup="true"
        aria-label="more"
        id="menu-button-icon"
        onClick={handleClick}
        sx={{ marginLeft: 1, padding: 0 }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        id="menu-button-popover"
        onClick={(event) => event.stopPropagation()}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <>
          <Box sx={{ display: popoverState ? 'none' : 'block' }}>
            <MenuItem
              divider
              key="manageDirectivesKey"
              onClick={() => setPopoverState('asd')}
              sx={{ height: 54, width: 300 }}
            >
              {t('privacy-directives.manage-file-access-restrictions')}
            </MenuItem>
          </Box>
          <Box
            sx={{
              display: popoverState ? 'block' : 'none',
              pb: GLOBAL.PADDING_MD,
              width: 700,
            }}
          >
            <Box sx={{ display: 'inline-flex' }}>
              <IconButton
                aria-label="back"
                id="back-button"
                onClick={() => {
                  if (viewingHistory) {
                    setViewingHistory(false)
                  } else {
                    setPopoverState(undefined)
                  }
                }}
                sx={{ width: 48 }}
              >
                <KeyboardBackspaceIcon />
              </IconButton>
              <Typography sx={{ lineHeight: 3 }}>
                {t('privacy-directives.manage-file-access-restrictions')}
              </Typography>
            </Box>
            <Divider sx={{ mt: GLOBAL.MARGIN_XXS }} />
            {!viewingHistory && (
              <Box sx={{ px: GLOBAL.PADDING_SM }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box>
                        <FormControlLabel
                          control={
                            <MISCheckbox
                              checked={isRestricted}
                              onChange={() => {
                                setIsRestricted(!isRestricted)
                                setIsDirty(true)
                              }}
                            />
                          }
                          label="Restrict File Access"
                        />
                      </Box>
                      <Box>
                        <MISButton
                          color="primary"
                          endIcon={<HistoryIcon />}
                          onClick={() => handleViewHistory()}
                          size="large"
                          variant="text"
                        >
                          {t('privacy-directives.view-history')}
                        </MISButton>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">{latestText}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <MISTextField
                      disabled={!isDirty}
                      error={reasonsError}
                      helperText={t('privacy-directives.reason-error')}
                      inputProps={{ maxLength: 255 }}
                      label={t('privacy-directives.reason-for-changes')}
                      maxRows={3}
                      minRows={3}
                      multiline
                      onChange={(event) => {
                        setReasons(event.target.value)
                        setReasonsError(false)
                      }}
                      required
                      value={reasons || ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomMultiValueAutocomplete
                      label={
                        <span>
                          {t('privacy-directives.provider-staff')}
                          <span style={{ fontWeight: 'bold' }}>denied</span>{' '}
                          {t('privacy-directives.by-client-text')}
                        </span>
                      }
                      onChange={(event: SyntheticEvent, newValue: string[]) => {
                        handleActualSelect(newValue, true)
                      }}
                      onInputChange={(newValue: string) => {
                        handleSearch(newValue)
                      }}
                      options={getFilteredListUtilWithoutMember(providerOptions)}
                      renderOption={(props, provider) => {
                        const label = evaluateLabelUtil(provider?.names)
                        const chips = evaluateChipsUtil(provider?.jobFunctions)
                        return (
                          <Stack
                            {...props}
                            direction="row"
                            key={label}
                            sx={{
                              '&.MuiAutocomplete-option': {
                                justifyContent: 'space-between',
                              },
                              cursor: 'pointer',
                              p: 1,
                            }}
                          >
                            <span>{label}</span>
                            <span>{displayChipsUtil(chips)}</span>
                          </Stack>
                        )
                      }}
                      value={isProviderDenied ? providerList : []}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomMultiValueAutocomplete
                      label={
                        <span>
                          {t('privacy-directives.provider-staff')}
                          <span style={{ fontWeight: 'bold' }}>authorized</span>{' '}
                          {t('privacy-directives.by-client-text')}
                        </span>
                      }
                      onChange={(event: SyntheticEvent, newValue: string[]) => {
                        handleActualSelect(newValue, false)
                      }}
                      onInputChange={(newValue: string) => {
                        handleSearch(newValue)
                      }}
                      options={getFilteredListUtilWithoutMember(providerOptions)}
                      renderOption={(props, provider) => {
                        const label = evaluateLabelUtil(provider?.names)
                        const chips = evaluateChipsUtil(provider?.jobFunctions)
                        return (
                          <Stack
                            {...props}
                            direction="row"
                            key={label}
                            sx={{
                              '&.MuiAutocomplete-option': {
                                justifyContent: 'space-between',
                              },
                              cursor: 'pointer',
                              p: 1,
                            }}
                          >
                            <span>{label}</span>
                            <span>{displayChipsUtil(chips)}</span>
                          </Stack>
                        )
                      }}
                      value={!isProviderDenied ? providerList : []}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box alignItems="center" display="flex" justifyContent="flex-end">
                      <Box mr={2}>
                        <MISButton color="secondary" onClick={() => setPopoverState(undefined)}>
                          {t('common.button.close')}
                        </MISButton>
                      </Box>
                      <Box>
                        <MISButton
                          color="primary"
                          disabled={!isDirty}
                          onClick={() => handleSaveClicked()}
                        >
                          {t('common.button.confirm')}
                        </MISButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            {viewingHistory && (
              <Box sx={{ px: GLOBAL.PADDING_SM }}>
                <PrivacyTimeline />
              </Box>
            )}
          </Box>
        </>
      </Popover>
    </>
  )
}

export default PrivacyDirectivesPopover

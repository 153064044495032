/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FormSchemaDTO } from '../models/FormSchemaDTO'
import type { PageFormSchemaLightDTO } from '../models/PageFormSchemaLightDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class FormSchemaControllerService {
  /**
   * @param requestBody
   * @returns FormSchemaDTO OK
   * @throws ApiError
   */
  public static putFormSchema(requestBody: FormSchemaDTO): CancelablePromise<FormSchemaDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-form-service/v1/form-schemas',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param formSchemaId
   * @param _with
   * @returns FormSchemaDTO OK
   * @throws ApiError
   */
  public static getFormSchema(
    formSchemaId: string,
    _with?: Array<string>
  ): CancelablePromise<FormSchemaDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-form-service/v1/form-schemas/{formSchemaId}',
      path: {
        formSchemaId: formSchemaId,
      },
      query: {
        with: _with,
      },
    })
  }

  /**
   * @param tagText
   * @returns string OK
   * @throws ApiError
   */
  public static getMatchingTags(tagText?: string): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-form-service/v1/form-schemas/tags',
      query: {
        tagText: tagText,
      },
    })
  }

  /**
   * Search for templates
   * Returns a list of templates matching the supplied filter critieria
   * @param searchText The partial match on name, description and tag fields
   * @param version Exact match on the version of the template
   * @param createdByUserOnly Templates created by logged in user
   * @param notCreatedByUser Templates not created by logged in user
   * @param onlyDraftVersion Only templates with draft state are returned
   * @param onlyLatestVersion Only templates with latest version are returned
   * @param status Match on the state of the template
   * @param designation Match on the designation of the template
   * @param searchOperator Two modes. 'OR' matches any of the filter parameters. 'AND' matches all of the filter parameters. If this parameter is not included the default is 'AND'.
   * @param includeGa Include GA templates
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageFormSchemaLightDTO OK
   * @throws ApiError
   */
  public static searchFormSchema(
    searchText: string,
    version?: string,
    createdByUserOnly: boolean = false,
    notCreatedByUser: boolean = false,
    onlyDraftVersion: boolean = false,
    onlyLatestVersion: boolean = false,
    status?: Array<string>,
    designation?: Array<string>,
    searchOperator?: string,
    includeGa: boolean = false,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageFormSchemaLightDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-form-service/v1/form-schemas/search',
      query: {
        searchText: searchText,
        version: version,
        createdByUserOnly: createdByUserOnly,
        notCreatedByUser: notCreatedByUser,
        onlyDraftVersion: onlyDraftVersion,
        onlyLatestVersion: onlyLatestVersion,
        status: status,
        designation: designation,
        searchOperator: searchOperator,
        includeGA: includeGa,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }
}

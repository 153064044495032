import './ClientRecordHeader.scss'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import { Avatar, Box, Chip, Grid, Link, Stack, Typography } from '@mui/material'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import changeClientContext from 'assets/images/changeClientContext.svg'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISButton from 'common/components/MISButton'
import { isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { MISNavigationState } from 'core/components/navigation/MISNavigationState'
import { getClientAgeString, getClientFullName } from 'modules/shared/clientUtils'
import {
  chartingAttendeesProgressState,
  chartingEditorProgressState,
  chartingFieldsProgressState,
  isDisplayInProgressState,
} from 'recoil/charting'
import { terminologySelector } from 'recoil/terminology'
import {
  ClientControllerService,
  ClientDTO,
  ClientIdentifier,
  ClientSearchResultDTO,
  CodedRef,
  GroupDTO,
  PrivacyDirectiveControllerService,
} from 'services/openapi'
import {
  MEMBER_IDENTIFIER_TYPE_VOCAB_NAME,
  MEMBER_STATUS_VOCAB_NAME,
  MIS_GENDER_VOCAB_NAME,
  MIS_MEMBER_PRONOUNS_VOCAB_NAME,
  VALUESET,
} from 'services/terminologyConstants'
import { setChartingClients } from 'store/reducers/charting'
import { setClientPrivacyDirectives } from 'store/reducers/client'
import { selectClientPrivacyDirectives } from 'store/selectors/client'
import PrivacyDirectivesPopover from './ClientPrivacyDirectives/PrivacyDirectivesPopover'

type SearchResultType = {
  id: string
  type: string
  displayName: string
}

type ClientRecordHeaderDetailsProps = {
  client?: ClientDTO
  allowChangeContext?: boolean
  group?: GroupDTO
  readOnly?: boolean
  withNavigation?: boolean
}

const ClientRecordHeaderDetails = ({
  allowChangeContext = false,
  client,
  group,
  readOnly,
  withNavigation,
}: ClientRecordHeaderDetailsProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()
  const clientPrivacyDirectives = useSelector(selectClientPrivacyDirectives)
  const clientStatusOptions = useRecoilValue(terminologySelector(MEMBER_STATUS_VOCAB_NAME))
  const genderOptions = useRecoilValue(terminologySelector(MIS_GENDER_VOCAB_NAME))
  const pronounsOptions = useRecoilValue(terminologySelector(MIS_MEMBER_PRONOUNS_VOCAB_NAME))
  const identifierTypeOptions = useRecoilValue(
    terminologySelector(MEMBER_IDENTIFIER_TYPE_VOCAB_NAME)
  )

  const [, setSearchText] = useState('')
  const [displayChangeContext, setDisplayChangeContext] = useState(false)
  const [searchResults, setSearchResults] = useState<SearchResultType[]>([])
  const [, setSelectedClientId] = useState<string>('')
  const [, setSelectedGroupId] = useState<string>('')
  const [seletedMenuItem, setSeletedMenuItem] = useRecoilState(MISNavigationState)
  const setIsDisplayInProgress = useSetRecoilState(isDisplayInProgressState)
  const setChartingEditorProgress = useSetRecoilState(chartingEditorProgressState)
  const setChartingAttendeesProgress = useSetRecoilState(chartingAttendeesProgressState)
  const setChartingFieldsProgress = useSetRecoilState(chartingFieldsProgressState)

  const getStatus = useCallback(
    (status: CodedRef) => {
      return clientStatusOptions.find((option) => status?.code === option.code)?.name
    },
    [clientStatusOptions]
  )

  const getIdentifierValue = useCallback(
    (identifierKey: string) => {
      const identifier =
        identifierTypeOptions?.find((option) => option.name === identifierKey) || null

      return (
        identifier &&
        client &&
        client.clientIdentifiers?.find(
          (obj: ClientIdentifier) =>
            obj.type?.code === identifier.code &&
            obj.type?.codeSystemOid === identifier.codeSystemOid
        )?.value
      )
    },
    [client, identifierTypeOptions]
  )

  useEffect(() => {
    const getClientPrivacyDirectives = async (clientId: string) => {
      try {
        const directives = await PrivacyDirectiveControllerService.getDirectives(
          clientId,
          true,
          undefined,
          undefined,
          ['auditInfo']
        )
        if (!directives?.content?.length) {
          dispatch(setClientPrivacyDirectives(undefined))
          return
        }
        const directive = directives.content[0]
        dispatch(setClientPrivacyDirectives(directive))
      } catch (error) {
        handleApiError(error)
      }
    }
    if (client && client.id && !readOnly) getClientPrivacyDirectives(client.id)
  }, [client, dispatch, handleApiError, readOnly])

  const getClientDetail = useCallback(
    (searchResult: ClientSearchResultDTO | undefined) => {
      if (searchResult) {
        const { firstName, lastName } = searchResult
        const preferredName = [firstName, lastName].filter(Boolean).join(' ').trim()

        const gender =
          searchResult.gender &&
          genderOptions?.find((option) => option.code === searchResult.gender?.code)?.name
        const birthDate = searchResult.birthdate
          ? isoDateToDisplayFormat(searchResult.birthdate)
          : ''
        const age = searchResult.birthdate ? getClientAgeString(searchResult.birthdate) : ''
        return `${preferredName}|${t('client-header.gender-label')}: ${gender}| ${t(
          'client-header.birthdate-label'
        )}:${birthDate}|${t('client-header.age-label')}: ${age} `
      }
    },
    [genderOptions, t]
  )

  const handleSearch = useCallback(
    async (searchText: string) => {
      if (searchText && searchText.length >= 3) {
        try {
          const response = await ClientControllerService.getHeaderSearchResult(searchText)
          const results = response.map((result) => {
            const searchResp = {
              ...JSON.parse(JSON.stringify(result)),
            }
            return {
              displayName: result.type === 'GROUP' ? searchResp.name : getClientDetail(searchResp),
              id: result.id || '',
              type: result.type || '',
            }
          })
          setSearchResults(results)
        } catch (err) {
          setSearchResults([])
        }
      } else {
        setSearchResults([])
      }
    },
    [getClientDetail]
  )

  const handleChangeContextClick = useCallback(() => {
    setDisplayChangeContext(true)
  }, [])

  const handleCancelClick = useCallback(() => {
    setDisplayChangeContext(false)
  }, [])

  const handleSearchInputChange = useCallback(
    (value: string) => {
      setSearchText(value)
      handleSearch(value)
    },
    [handleSearch]
  )

  const performGroupNavigation = useCallback(
    (groupId: string) => {
      switch (seletedMenuItem) {
        case 'navigation.left-nav-menu.charting.blank-canvas': {
          setSeletedMenuItem('navigation.left-nav-menu.group.charting.in-progress')
          setIsDisplayInProgress(true)
          navigate(`/groups/group-record/${groupId}/charting/in-progress`)
          break
        }
        case 'navigation.left-nav-menu.group.charting.in-progress': {
          setSeletedMenuItem('navigation.left-nav-menu.group.charting')
          setIsDisplayInProgress(false)
          navigate(`/groups/group-record/${groupId}/charting`)
          break
        }
        case 'navigation.left-nav-menu.group.charting': {
          setSeletedMenuItem('navigation.left-nav-menu.group.charting')
          setIsDisplayInProgress(false)
          navigate(`/groups/group-record/${groupId}/charting`)
          break
        }
        default: {
          setSeletedMenuItem('navigation.left-nav-menu.group.charting')
          setIsDisplayInProgress(false)
          navigate(`/groups/group-record/${groupId}/charting`)
        }
      }
    },
    [navigate, setIsDisplayInProgress, setSeletedMenuItem, seletedMenuItem]
  )

  const performClientNavigation = useCallback(
    (clientId: string) => {
      switch (seletedMenuItem) {
        case 'navigation.left-nav-menu.charting.blank-canvas': {
          setSeletedMenuItem('navigation.left-nav-menu.client.charting.in-progress')
          setIsDisplayInProgress(true)
          navigate(`/clients/client-record/${clientId}/in-progress`)
          break
        }
        case 'navigation.left-nav-menu.client.charting.in-progress': {
          setSeletedMenuItem('navigation.left-nav-menu.client.charting')
          setIsDisplayInProgress(false)
          navigate(`/clients/client-record/${clientId}/charting`)

          break
        }
        case 'navigation.left-nav-menu.client.charting': {
          setSeletedMenuItem('navigation.left-nav-menu.client.charting')
          setIsDisplayInProgress(false)
          navigate(`/clients/client-record/${clientId}/charting`)
          break
        }
        default: {
          setSeletedMenuItem('navigation.left-nav-menu.client.charting')
          setIsDisplayInProgress(false)
          navigate(`/clients/client-record/${clientId}/charting`)
        }
      }
    },
    [navigate, setIsDisplayInProgress, setSeletedMenuItem, seletedMenuItem]
  )

  const handleChangeContext = useCallback(
    (value: any) => {
      if (seletedMenuItem !== 'navigation.left-nav-menu.charting.blank-canvas') {
        setIsDisplayInProgress(false)
        setChartingEditorProgress(undefined)
        setChartingFieldsProgress(undefined)
      }
      setChartingAttendeesProgress(undefined)
      dispatch(setChartingClients(undefined))

      if (value.type === 'CLIENT') {
        performClientNavigation(value.id)
        setSelectedGroupId('')
      } else {
        performGroupNavigation(value.id)
        setSelectedClientId('')
      }
      setDisplayChangeContext(false)
    },
    [
      dispatch,
      performClientNavigation,
      performGroupNavigation,
      setChartingAttendeesProgress,
      setChartingEditorProgress,
      setChartingFieldsProgress,
      setIsDisplayInProgress,
      seletedMenuItem,
    ]
  )

  const getSearchContextField = useCallback(() => {
    return (
      (displayChangeContext ||
        seletedMenuItem === 'navigation.left-nav-menu.charting.blank-canvas') && (
        <Grid container spacing={2} sx={{ marginLeft: '10px', marginTop: 0 }}>
          <Grid item xs={10}>
            <MISAutocomplete
              label={t('client-header.search-field-label')}
              onChange={(value) => handleChangeContext(value)}
              onInputChange={handleSearchInputChange}
              options={searchResults.map((result) => {
                return { id: result.id, label: result.displayName, type: result.type }
              })}
            />
          </Grid>
          <Grid item xs={2}>
            {seletedMenuItem !== 'navigation.left-nav-menu.charting.blank-canvas' && (
              <MISButton
                color="secondary"
                onClick={handleCancelClick}
                sx={{ marginLeft: 1 }}
                variant="outlined"
              >
                {t('common.button.cancel')}
              </MISButton>
            )}
          </Grid>
        </Grid>
      )
    )
  }, [
    displayChangeContext,
    handleCancelClick,
    handleChangeContext,
    handleSearchInputChange,
    searchResults,
    seletedMenuItem,
    t,
  ])

  const getGroupContextHeader = useCallback(() => {
    return (
      !displayChangeContext &&
      group &&
      seletedMenuItem !== 'navigation.left-nav-menu.charting.blank-canvas' && (
        <Box className="group-header">
          <Box className="title-panel">
            <Typography className="title1">{group?.name}</Typography>
            {allowChangeContext && (
              <img
                alt="change context"
                className="change-icon"
                onClick={handleChangeContextClick}
                src={changeClientContext}
              />
            )}
          </Box>
          <Box className="details-panel">
            <Stack direction="row" spacing={1}>
              <Typography className="details">
                {group?.effective?.startDate
                  ? isoDateToDisplayFormat(group?.effective?.startDate)
                  : ''}
              </Typography>
              <Typography className="details">{t('group.header.end-date')}</Typography>
              <Typography className="details">
                {group?.effective?.endDate
                  ? isoDateToDisplayFormat(group?.effective?.endDate)
                  : '-'}
              </Typography>
              <Typography className="details" sx={{ color: '#eee' }}>
                |
              </Typography>
              <Typography className="details">{group?.description}</Typography>
            </Stack>
          </Box>
        </Box>
      )
    )
  }, [
    allowChangeContext,
    handleChangeContextClick,
    group,
    displayChangeContext,
    seletedMenuItem,
    t,
  ])

  const isDisplayClientHeader = useCallback(() => {
    return (
      !displayChangeContext &&
      !group &&
      seletedMenuItem !== 'navigation.left-nav-menu.charting.blank-canvas'
    )
  }, [group, displayChangeContext, seletedMenuItem])

  return (
    <Stack>
      {getSearchContextField()}
      {getGroupContextHeader()}

      {isDisplayClientHeader() && (
        <Box className="client-header">
          <Box className="panelLeft">
            <Avatar
              alt="Avatar Image"
              className="avatar"
              src="https://wallpapercave.com/dwp2x/wp9061276.jpg"
            >
              <PersonIcon className="icon" />
            </Avatar>
          </Box>
          <Box className="panelRight">
            <Stack>
              <Box style={{ alignItems: 'center', display: 'flex' }}>
                <Typography className="title">{getClientFullName(client)}</Typography>
                {client?.status && client.status?.code !== VALUESET.MEMBER_STATUS.ACTIVE.code && (
                  <Chip label={getStatus(client.status)} sx={{ mx: 0.25 }} />
                )}
                {allowChangeContext && (
                  <img
                    alt="change context"
                    className="change-icon"
                    onClick={handleChangeContextClick}
                    src={changeClientContext}
                  />
                )}
                {clientPrivacyDirectives &&
                  clientPrivacyDirectives?.directive?.code === 'RESTRICTED' && (
                    <Chip label="Restricted" sx={{ mx: 0.25, padding: 0 }} />
                  )}
                {!readOnly && <PrivacyDirectivesPopover />}
              </Box>
              <Box className="details">
                <Stack direction="row" spacing={1}>
                  <Typography className="details">
                    <span>{t('client-header.gender-label')}: </span>
                    {client?.gender &&
                      genderOptions?.find((option) => option.code === client.gender?.code)?.name}
                  </Typography>
                  <Typography className="details">
                    <span>{t('client-header.pronouns-label')}: </span>
                    {client?.pronouns &&
                      pronounsOptions?.find((option) => option.code === client.pronouns?.code)
                        ?.name}
                  </Typography>
                  <Typography className="details">
                    <span>{t('client-header.birthdate-label')}: </span>
                    {client?.birthdate ? isoDateToDisplayFormat(client.birthdate) : ''}
                  </Typography>
                  {!client?.deathDate && (
                    <Typography className="details">
                      <span>{t('client-header.age-label')}: </span>
                      {client?.birthdate ? getClientAgeString(client.birthdate) : ''}
                    </Typography>
                  )}
                  {client?.deathDate && (
                    <Typography className="details">
                      <span>{t('client-header.age-at-death-label')}: </span>
                      {client.birthdate
                        ? getClientAgeString(client.birthdate, client.deathDate)
                        : ''}
                    </Typography>
                  )}
                  <Typography className="details">
                    <span>{t('client-header.client-file-label')}: </span>
                    {withNavigation ? (
                      <Link
                        href={`/clients/client-record/${client?.id}`}
                        onClick={(event) => {
                          event.preventDefault()
                          navigate(`/clients/client-record/${client?.id}`)
                        }}
                        underline="hover"
                        variant="inherit"
                      >
                        {client?.fileNumber || ''}
                      </Link>
                    ) : (
                      client?.fileNumber || ''
                    )}
                  </Typography>
                  <Typography className="details">
                    <span>{t('client-header.status-card-label')}: </span>
                    {getIdentifierValue('Indian Registry Status Number')}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Box>
      )}
    </Stack>
  )
}

export default ClientRecordHeaderDetails

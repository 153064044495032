/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EntityAssociationCreationDTO } from '../models/EntityAssociationCreationDTO'
import type { PageProgramTerse } from '../models/PageProgramTerse'
import type { Program } from '../models/Program'
import type { ProgramGroupAssociation } from '../models/ProgramGroupAssociation'
import type { ProgramGroupAssociationTerse } from '../models/ProgramGroupAssociationTerse'
import type { ProgramTerse } from '../models/ProgramTerse'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ProgramControllerService {
  /**
   * @param id
   * @returns ProgramTerse OK
   * @throws ApiError
   */
  public static getProgramById(id: string): CancelablePromise<ProgramTerse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/programs/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns ProgramTerse OK
   * @throws ApiError
   */
  public static updateProgram(id: string, requestBody: Program): CancelablePromise<ProgramTerse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-administrative-info-service/v1/programs/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageProgramTerse OK
   * @throws ApiError
   */
  public static listPrograms(
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageProgramTerse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/programs',
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * @param requestBody
   * @returns ProgramTerse OK
   * @throws ApiError
   */
  public static createProgram(requestBody: Program): CancelablePromise<ProgramTerse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/programs',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @returns ProgramGroupAssociationTerse OK
   * @throws ApiError
   */
  public static getProgramGroupAssociationsForProgram(
    id: string
  ): CancelablePromise<Array<ProgramGroupAssociationTerse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/programs/{id}/groups',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns ProgramGroupAssociation OK
   * @throws ApiError
   */
  public static addProgramGroupToProgram(
    id: string,
    requestBody: EntityAssociationCreationDTO
  ): CancelablePromise<ProgramGroupAssociation> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-administrative-info-service/v1/programs/{id}/groups',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param activeOnly
   * @param effectiveAt
   * @param name
   * @param domain
   * @param typeId
   * @param purposeId
   * @returns ProgramTerse OK
   * @throws ApiError
   */
  public static searchPrograms(
    activeOnly: boolean = false,
    effectiveAt?: string,
    name?: string,
    domain?: string,
    typeId?: string,
    purposeId?: string
  ): CancelablePromise<Array<ProgramTerse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-administrative-info-service/v1/programs/search',
      query: {
        activeOnly: activeOnly,
        effectiveAt: effectiveAt,
        name: name,
        domain: domain,
        typeId: typeId,
        purposeId: purposeId,
      },
    })
  }
}

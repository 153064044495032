import { Dispatch, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { AddCircleOutline } from '@mui/icons-material'
import { Divider, Stack } from '@mui/material'
import { useRecoilState } from 'recoil'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import Loader from 'common/components/Loader'
import MISButton from 'common/components/MISButton'
import { MISNavigationState } from 'core/components/navigation/MISNavigationState'
import { FilterProps } from 'modules/shared/hooks/useRunningNotes'
import { isDisplayInProgressState } from 'recoil/charting'
import { ChartingEntryDTO, PersonnelDTO } from 'services/openapi'
import { setChartingClients } from 'store/reducers/charting'
import ChartingEntriesFilter from './ChartingEntriesFilter'
import ChartingEntriesPanel from './ChartingEntriesPanel'

type ChartingEntriesProps = {
  applyFilters: boolean
  filters: FilterProps[]
  chartingEntries: ChartingEntryDTO[]
  onSelect: (entry: ChartingEntryDTO, duplicate?: boolean) => void
  providers: PersonnelDTO[]
  setApplyFilters: Dispatch<SetStateAction<boolean>>
  setFilters: Dispatch<SetStateAction<FilterProps[]>>
}
const ChartingEntries = ({
  applyFilters,
  chartingEntries,
  filters,
  onSelect,
  providers,
  setApplyFilters,
  setFilters,
}: ChartingEntriesProps) => {
  const { t } = useTranslation('common')
  const { clientId } = useParams()
  const { groupId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [, setSelected] = useRecoilState(MISNavigationState)
  const [, setIsDisplayInProgress] = useRecoilState(isDisplayInProgressState)

  const handleSelectChart = useCallback(
    (chartingEntryDTO: ChartingEntryDTO) => {
      onSelect(chartingEntryDTO)
    },
    [onSelect]
  )

  const handleAdd = useCallback(() => {
    setIsDisplayInProgress(true)
    dispatch(setChartingClients(undefined))
    if (clientId) {
      setSelected('navigation.left-nav-menu.client.charting.in-progress')
      navigate(`/clients/client-record/${clientId}/in-progress`)
    } else if (groupId) {
      setSelected('navigation.left-nav-menu.group.charting.in-progress')
      navigate(`/groups/group-record/${groupId}/charting/in-progress`)
    }
  }, [clientId, dispatch, groupId, navigate, setIsDisplayInProgress, setSelected])

  return (
    <MISBaseContainer>
      {chartingEntries ? (
        <div>
          <Stack direction="row" sx={{ mb: 1, mt: 1 }}>
            <MISButton
              color="primary"
              onClick={handleAdd}
              size="large"
              startIcon={<AddCircleOutline />}
              sx={{ mb: 1, mt: 1 }}
              variant="text"
            >
              {t('charting.new-chart-entry')}
            </MISButton>
          </Stack>
          <Divider sx={{ mb: 0 }} />
          <ChartingEntriesFilter
            applyFilters={applyFilters}
            filters={filters}
            providers={providers}
            setApplyFilters={setApplyFilters}
            setFilters={setFilters}
          />
          <Divider sx={{ mb: 0 }} />
          <ChartingEntriesPanel
            charts={chartingEntries}
            onSelect={handleSelectChart}
            providers={providers}
          />
        </div>
      ) : (
        <Loader fullScreen />
      )}
    </MISBaseContainer>
  )
}

export default ChartingEntries

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import {
  checkPrivacyClientProfileState,
  recentClientsState,
  userIdState,
  userRecentClientsPreferenceIdState,
} from 'recoil/recentClients'
import {
  ClientControllerService,
  Preference,
  UserPreferenceControllerService,
} from 'services/openapi'
import { setActiveHistoricTabs, setChartingGroupInContext } from 'store/reducers/charting'
import { resetClient, setClientDetails } from 'store/reducers/client'
import { selectClientDetails } from 'store/selectors/client'

const useClientDetails = (clientId: string | undefined, forceUpate?: boolean) => {
  const dispatch = useDispatch()
  const clientDetails = useSelector(selectClientDetails)
  const { handleApiError } = useErrorHandler()
  const [recentClients, setRecentClients] = useRecoilState(recentClientsState)
  const [userRecentClientsPreferenceId, setUserRecentClientsPreferenceId] = useRecoilState(
    userRecentClientsPreferenceIdState
  )
  const setCheckPrivacyClientProfile = useSetRecoilState(checkPrivacyClientProfileState)
  const userId = useRecoilValue(userIdState)

  useEffect(() => {
    const getClientDetails = async (clientId: string) => {
      try {
        const resp = await ClientControllerService.getClient(clientId)
        dispatch(setClientDetails(resp))
        const currRecentClients = recentClients || []
        const index = currRecentClients.findIndex((client) => client.id === resp.id)
        let nextRecentClients = currRecentClients
        if (index === -1) nextRecentClients = [resp, ...currRecentClients.slice(0, 19)]
        else
          nextRecentClients = [
            currRecentClients[index],
            ...currRecentClients.slice(0, index),
            ...currRecentClients.slice(index + 1),
          ]
        const latestRecentClients = nextRecentClients.slice(0, 10)
        setRecentClients(latestRecentClients)
        if (userId) {
          const preference: Preference = {
            id: userRecentClientsPreferenceId,
            name: 'recent-clients',
            type: Preference.type.USER,
            value: latestRecentClients.map((client) => client.id).join('|'),
          }
          if (userRecentClientsPreferenceId) {
            await UserPreferenceControllerService.updateUserPreference(
              userId,
              userRecentClientsPreferenceId,
              preference
            )
          } else {
            const preferenceResp = await UserPreferenceControllerService.createUserPreference(
              userId,
              preference
            )
            setUserRecentClientsPreferenceId(preferenceResp.id)
          }
        }
      } catch (error) {
        if (error instanceof Error && error.message.includes('Forbidden')) {
          setCheckPrivacyClientProfile({ checkFlag: true, error: error })
        } else {
          handleApiError(error)
        }
      }
    }
    if (clientId && (clientId !== clientDetails?.id || forceUpate)) {
      dispatch(resetClient())
      dispatch(setChartingGroupInContext(undefined))
      dispatch(setActiveHistoricTabs([]))
      getClientDetails(clientId)
    }
  }, [
    clientDetails?.id,
    clientId,
    dispatch,
    forceUpate,
    handleApiError,
    recentClients,
    setCheckPrivacyClientProfile,
    setRecentClients,
    setUserRecentClientsPreferenceId,
    userId,
    userRecentClientsPreferenceId,
  ])

  return { clientDetails }
}

export default useClientDetails

import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Alert,
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import PropTypes from 'prop-types'
import { Content } from 'common/components/contentpane/Content'
import MISImageContentContainer from 'common/components/contentpane/MISImageContentContainer'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { EMPTY_FUNCTION } from 'modules/shared/constants'
import { GroupControllerService, GroupDTO, PageGroupDTO } from 'services/openapi'
import './SearchGroup.scss'

function EnhancedTableHead({ onRequestSort, order, orderBy }: any) {
  const { t } = useTranslation('common')

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        key: 'name',
        label: t('group.search.name'),
        sortable: true,
      },
      {
        id: 'description',
        key: 'description',
        label: t('group.search.description'),
      },
    ],
    [t]
  )

  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className="head-cell"
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              hideSortIcon={!headCell.sortable}
              onClick={headCell.sortable ? createSortHandler(headCell.id) : EMPTY_FUNCTION}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}
const DEFAULT_PAGE_SIZE = 5

const SearchGroup = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { handleApiError } = useErrorHandler()
  const [searchFilters, setSearchFilters] = useState({ name: '' })
  const [searchResults, setSearchResults] = useState<GroupDTO[]>([])
  const [displaySearchResults, setDisplaySearchResults] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage] = useState(5)
  const [totalElements, setTotalElements] = useState<number>(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')

  const handleChange = useCallback(
    (value: string, field: string) => {
      const updatedSearchFilters = {
        ...searchFilters,
        [field]: value,
      }
      setSearchFilters(updatedSearchFilters)
    },
    [searchFilters, setSearchFilters]
  )

  const validate = useCallback(() => {
    const filterKeys = Object.keys(searchFilters)
    let filterPresent = false

    filterKeys.forEach((key) => {
      if (filterPresent) return
      if (typeof searchFilters[key] === 'string' && searchFilters[key] !== '') {
        filterPresent = true
      } else {
        filterPresent = false
      }
    })
    setHasErrors(!filterPresent)
    return !!filterPresent
  }, [searchFilters])

  const handleSearch = useCallback(
    (page, order, orderBy) => {
      if (validate()) {
        const { name } = searchFilters

        GroupControllerService.searchGroup(name, undefined, page, DEFAULT_PAGE_SIZE, [
          `${orderBy},${order}`,
        ])
          .then((response: PageGroupDTO) => {
            setSearchResults(response?.content || [])
            setDisplaySearchResults(true)
            setTotalElements(response.totalElements || 0)
          })
          .catch((error) => {
            handleApiError(error)
          })
      }
    },
    [searchFilters, validate, handleApiError]
  )

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage)
      handleSearch(newPage, order, orderBy)
    },
    [handleSearch, order, orderBy]
  )

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
      handleSearch(page, isAsc ? 'desc' : 'asc', property)
    },
    [page, order, orderBy, handleSearch]
  )

  const emptyRows = useMemo(
    () => (page > 0 ? Math.max(0, rowsPerPage - (searchResults ? searchResults.length : 0)) : 0),
    [searchResults, page, rowsPerPage]
  )

  const getSearchResults = useCallback(
    (rows) => {
      return (
        <div className="search-results">
          <Table aria-label="simple table" className="table">
            <EnhancedTableHead onRequestSort={handleRequestSort} order={order} orderBy={orderBy} />
            {rows && rows.length <= 0 && (
              <TableBody>
                <TableRow className="row">
                  <TableCell className="cell" colSpan={3}>
                    <Alert severity="info">{t('group.search.search-no-results-title')}</Alert>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {rows && rows.length > 0 && (
              <TableBody>
                {searchResults.map((row: GroupDTO, index: number) => {
                  return (
                    <TableRow className="special-row" hover key={row.id}>
                      <TableCell component="th" scope="row">
                        <MISButton
                          onClick={() => navigate(`/groups/group-record/${row.id}`)}
                          sx={{ p: 0, textAlign: 'left' }}
                          variant="text"
                        >
                          {row.name}
                        </MISButton>
                      </TableCell>

                      <TableCell>{row.description}</TableCell>
                    </TableRow>
                  )
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={1} />
                  </TableRow>
                )}
              </TableBody>
            )}
            <TableFooter className="footer">
              <TableRow className="row">
                <TableCell className="cell" colSpan={4}>
                  <Box className="add-group">
                    <Box className="container">
                      <MISButton
                        className={
                          !displaySearchResults ? 'button primary disabled' : 'button primary'
                        }
                        disabled={!displaySearchResults}
                        onClick={() => navigate('/group/create')}
                      >
                        {t('group.add-edit.add-group-text')}
                      </MISButton>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow className="row">
                <TablePagination
                  className="pagination"
                  colSpan={10}
                  count={totalElements}
                  onPageChange={handleChangePage}
                  page={page}
                  rowsPerPage={5}
                  rowsPerPageOptions={[5]}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      )
    },
    [
      emptyRows,
      handleChangePage,
      handleRequestSort,
      order,
      orderBy,
      navigate,
      page,
      totalElements,
      searchResults,
      displaySearchResults,
      t,
    ]
  )

  const getSearchFilters = useCallback(() => {
    const { name } = searchFilters

    return (
      <form className="search-content" onSubmit={(event) => event.preventDefault()}>
        {hasErrors && (
          <Alert className="error" severity="error">
            {t('group.search.search-no-filters-applied')}
          </Alert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MISTextField
              id="name"
              label={t('group.search.name')}
              onChange={(event) => handleChange(event.target.value, 'name')}
              value={name}
            />
          </Grid>
        </Grid>
        <Divider />
        <Box className="actions">
          <MISButton
            onClick={() => handleSearch(0, order, orderBy)}
            sx={{ marginLeft: '1rem' }}
            type="submit"
          >
            {t('group.search.search-button')}
          </MISButton>
        </Box>
      </form>
    )
  }, [handleChange, handleSearch, hasErrors, searchFilters, order, orderBy, t])

  return (
    <MISBaseContainer>
      <div className="search-group">
        <MISImageContentContainer>
          <Content
            content={getSearchFilters()}
            heading={t('group.search.search-title')}
            isCollapsible={false}
            isDivider
          />
        </MISImageContentContainer>
        {displaySearchResults && (
          <MISImageContentContainer hideImage>
            <Content
              content={getSearchResults(searchResults)}
              heading={t('group.search.search-results-title')}
              isCollapsible={false}
              isDivider
            />
          </MISImageContentContainer>
        )}
      </div>
    </MISBaseContainer>
  )
}

export default SearchGroup
